<template>
  <div>
    <el-container>
      <el-header>个号</el-header>
      <el-main>
        <div>
          <el-form
            :inline="true"
            :model="actionDrawerData"
            ref="actionDrawerData"
          >
            <el-form-item prop="ids">
              <el-input
                v-model="actionDrawerData.ids"
                placeholder="账号id, 多个逗号分隔"
              ></el-input>
            </el-form-item>
            <el-form-item prop="account">
              <el-input
                v-model="actionDrawerData.account"
                placeholder="手机号/邮箱，可模糊"
              ></el-input>
            </el-form-item>
            <el-form-item prop="q">
              <el-input
                v-model="actionDrawerData.q"
                placeholder="姓名/备份名"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item>
              <el-select v-model="actionDrawerData.region" placeholder="注册类型">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item> -->
            <!-- <el-form-item prop="platform">
              <el-select v-model="actionDrawerData.platform" placeholder="所属平台">
                <el-option v-for="item in platforms" :key="item.value" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="country">
              <el-select
                v-model="actionDrawerData.country"
                placeholder="所属区域"
              >
                <el-option
                  v-for="item in countries"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="mobileID">
              <el-select
                v-model="actionDrawerData.mobileID"
                placeholder="关联手机终端"
              >
                <el-option
                  v-for="item in mobilesData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="pcID">
              <el-select
                v-model="actionDrawerData.pcID"
                placeholder="关联PC终端"
              >
                <el-option
                  v-for="item in pcsData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="status">
              <el-select v-model="actionDrawerData.status" placeholder="状态">
                <el-option
                  v-for="item in account_status"
                  :key="item.value"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="handleSearch"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button
                @click="handleReset('actionDrawerData')"
                icon="el-icon-refresh-right"
                >重置</el-button
              >
            </el-form-item>
            <span @click="advancedSearch"
              ><i class="el-icon-brush"></i
              ><el-button type="text">高级筛选</el-button></span
            >
          </el-form>
        </div>
        <!-- buttons -->
        <div class="mb-10">
          <el-button
            type="primary"
            @click="handleCreate"
            icon="el-icon-circle-plus"
            >创建</el-button
          >
          <el-button icon="el-icon-delete" @click="handleBatchDelete"
            >批量删除</el-button
          >
          <!-- <el-button type="primary"  @click="batchImport"  icon="el-icon-circle-plus">批量导入</el-button> -->
        </div>
        <!-- 表格 -->
        <div>
          <el-table
            :data="acountList"
            class="table-info"
            highlight-current-row
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="id" width="100" prop="id">
            </el-table-column>
            <el-table-column width="140" label="头像">
              <template slot-scope="scope">
                <img
                  class="profile"
                  :src="
                    scope.row.avatar
                      ? scope.row.avatar
                      : require('../../assets/images/profile.jpg')
                  "
                />
              </template>
            </el-table-column>
            <el-table-column prop="account" label="个号账号">
              <template slot-scope="scope">
                <p>
                  {{ scope.row.account != null ? scope.row.account : "null" }}
                </p>
              </template>
            </el-table-column>

            <el-table-column label="用户信息">
              <template slot-scope="scope">
                <p class="">
                  {{ scope.row.last_name }}{{ scope.row.first_name }} |
                  {{ scope.row.gender == 0 ? "女" : "男" }} |
                  {{ scope.row.birthday }}
                </p>
                <!-- <p class="gray-color-9">个号编码:{{scope.row.phone_platform_id}}</p> -->
              </template>
            </el-table-column>
            <el-table-column prop="tfa_secret" label="2fa密钥">
              <template slot-scope="scope">
                <a
                  class="a-link"
                  target="_blank"
                  :href="scope.row.tfa_code_url"
                  >{{ scope.row.tfa_secret }}</a
                >
              </template>
            </el-table-column>
            <el-table-column prop="backup_record" width="240" label="备份名">
              <template slot-scope="scope">
                <div
                  v-for="(item, index) in scope.row.bind_devices"
                  :key="index"
                >
                  <p>{{ item.backup_record }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="country" label="所属区域"> </el-table-column>
            <el-table-column prop="status_str" label="状态" width="120">
            </el-table-column>
            <el-table-column prop="update_time" sortable label="最后更新时间">
            </el-table-column>
            <el-table-column label="关联PC|关联手机">
              <template slot-scope="scope">
                <div
                  v-for="(item, index) in scope.row.bind_devices"
                  :key="index"
                >
                  <p>{{ item.pc_name }} | {{ item.mobile_name }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="ads power id">
              <template slot-scope="scope">
                <div
                  v-for="(item, index) in scope.row.bind_devices"
                  :key="index"
                >
                  <p>{{ item.adspower_id }}</p>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
            <el-table-column label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  :disabled="scope.row.status == -1 ? true : false"
                  @click="handleCreateLog(scope.row)"
                  >创建日志</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  :disabled="scope.row.status == -1 ? true : false"
                  @click="handleCheckLog(scope.row)"
                  >查看日志</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="text"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
                <!-- <el-button
                  size="mini"
                  type="text"
                  class="mx-clear"
                  @click="handleBindRecord(scope.$index, scope.row)">绑定终端记录</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="text-center m-20">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              layout="total, prev, pager, next,sizes,jumper"
              :total="totalDataNum"
            >
            </el-pagination>
          </div>
        </div>
      </el-main>
    </el-container>
    <!-- 创建/修改 动作 -->
    <item-action
      ref="itemAction"
      :mobilesData="mobilesData"
      :pcsData="pcsData"
    ></item-action>
    <!-- 高级筛选 -->
    <el-drawer
      size="600px"
      title="高级筛选"
      :visible.sync="openSearchDrawer"
      direction="rtl"
      class="advancedFilter"
    >
      <div class="px-20">
        <el-form
          label-position="top"
          :inline="true"
          :model="actionDrawerData"
          ref="actionDrawerData"
        >
          <el-form-item label="手机号/邮箱" prop="account">
            <el-input
              v-model="actionDrawerData.account"
              placeholder="请输入手机号/邮箱地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="个号编码">
            <el-input
              v-model="actionDrawerData.user_id"
              placeholder="请输入个号编码"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="注册类型" prop="platform">
              <el-select v-model="actionDrawerData.region" placeholder="请选择广告平台">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </el-form-item> -->
          <el-form-item label="关联手机终端" prop="mobileID">
            <el-select
              v-model="actionDrawerData.mobileID"
              placeholder="关联手机终端"
            >
              <el-option
                v-for="item in mobilesData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关联PC终端" prop="pcID">
            <el-select v-model="actionDrawerData.pcID" placeholder="关联PC终端">
              <el-option
                v-for="item in pcsData"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="个号状态" prop="status">
            <el-select
              v-model="actionDrawerData.status"
              placeholder="请选择个号状态"
            >
              <el-option
                v-for="item in account_status"
                :key="item.value"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属平台" prop="platform">
            <el-select
              v-model="actionDrawerData.platform"
              placeholder="请选择所属平台"
            >
              <el-option
                v-for="item in platforms"
                :key="item.value"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属地区" prop="country">
            <el-select
              v-model="actionDrawerData.country"
              placeholder="请选择所属地区"
            >
              <el-option
                v-for="item in countries"
                :key="item.value"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="创建人" prop="name">
            <el-input
              v-model="actionDrawerData.name"
              placeholder="请输入创建人姓名"
            ></el-input>
          </el-form-item>
          <div class="d-flex align-items-end">
            <el-form-item label="创建时间" prop="ctimeStart">
              <el-date-picker
                type="datetime"
                placeholder="开始时间"
                v-model="actionDrawerData.ctimeStart"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="" prop="ctimeEnd">
              <el-date-picker
                type="datetime"
                placeholder="结束时间"
                v-model="actionDrawerData.ctimeEnd"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </div>
          <div class="d-flex align-items-end">
            <el-form-item label="最后更新时间" prop="utimeStart">
              <el-date-picker
                type="datetime"
                placeholder="开始时间"
                v-model="actionDrawerData.utimeStart"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="utimeEnd">
              <el-date-picker
                type="datetime"
                placeholder="结束时间"
                v-model="actionDrawerData.utimeEnd"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button
              type=""
              @click="handleAdvanceReset('actionDrawerData')"
              icon="el-icon-refresh-right"
              >重置</el-button
            >
            <el-button
              type="primary"
              @click="handleAdvancedSearch"
              icon="el-icon-search"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 批量导入 -->
    <el-dialog
      title="创建页面"
      :visible.sync="focusImport"
      class="create-page-dialog"
      width="40%"
    >
      <div class="d-flex px-40">
        <label style="width: 80px"><b class="red-color">*</b>上传</label>
        <div>
          <div>
            <el-button type="primary"
              >上传excel<i class="el-icon-upload el-icon--right"></i
            ></el-button>
            <el-button type="primary"
              >下载模板<i class="el-icon-upload el-icon-download"></i
            ></el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 查看日志 -->
    <el-drawer
      size="500px"
      title="日志列表"
      :visible.sync="openLogsDrawer"
      direction="rtl"
      class="advancedFilter"
    >
      <div class="px-20">
        <div v-if="logs && logs.length">
          <div v-for="(item, index) in logs" :key="item.id" class="log-lists">
            <p>({{ index + 1 }}) IP: {{ item.ip }}</p>
            <p>创建时间：{{ item.create_time }}</p>
            <p>{{ item.log_content }}</p>
            <img :src="item.log_image" />
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 创建日志 -->
    <el-dialog title="创建日志" :visible.sync="createDialogVisible" width="200">
      <el-form label-position="left" :model="logModalData" ref="logModalData">
        <el-form-item label="备注" prop="accountStatus">
          <el-select v-model="logModalData.accountStatus" placeholder="请选择">
            <el-option
              v-for="(item, index) in logStates"
              :key="index"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传图片">
          <el-upload
            ref="upload"
            action
            name="upload"
            list-type="picture-card"
            :on-preview="handlePreLogview"
            :auto-upload="false"
            :file-list="logImgList"
            :on-change="handleUpload"
            multiple
            :on-remove="handleRemoveLogImg"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="createLogModal">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ItemAction from "@/components/personal-account/ItemAction.vue";
import * as API from "@/api/common";
import Cookies from "js-cookie";
export default {
  name: "personal-account",
  data() {
    return {
      activeName: "first",
      actionName: "",
      focusImport: false, //批量导入弹框
      //openBindRecordDrawer: false, //打开绑定记录的弹层
      openSearchDrawer: false, //打开高级搜索弹层
      currentPage: 1,
      perPageCount: 10,
      totalDataNum: 0,
      platforms: [], //  所属平台
      countries: [], // 所属区域
      account_status: [], //个号状态
      mobilesData: [], //关联手机终端
      pcsData: [], //关联pc终端
      openLogsDrawer: false, //打开日志弹层
      device_status: null,
      logStates: [],
      logImgList: [],
      createDialogVisible: false,
      createLogId: "",
      logModalData: {
        accountStatus: "",
      },
      acountList: [
        {
          id: "",
          account: "",
          avatar: "",
          first_name: "",
          last_name: "",
          gender: "",
          birthday: "",
          platform: "",
          country: "",
          status_str: "",
          create_time: "",
        },
      ],
      multipleSelection: [],
      logs: [],
      actionDrawerData: this.emptyData(),
    };
  },
  components: {
    ItemAction,
  },
  mounted() {
    this.getTableData();
    this.getConfigData();
    this.getMobilesData();
    this.getPCsData();
  },
  methods: {
    handleCreate() {
      this.$refs.itemAction.createActionDrawer();
    },
    handleEdit(row) {
      // console.log(index, row)
      this.$refs.itemAction.updateActionDrawer(row);
    },
    getTableData() {
      //var res = await this.$http.get(`gka/anjian/account/accounts?page=${this.currentPage}&size=${this.perPageCount}`);
      var param = Object.assign(this.actionDrawerData, {
        page: this.currentPage,
        size: this.perPageCount,
      });
      // var res = await this.$http.get(`gka/api/accounts?params=${param}`);
      API.handleGet(API.accouts.create, { params: param }).then((res) => {
        if (res.code != 0) return this.$message(res.message);
        this.acountList = res.data;
        this.totalDataNum = res.page.total;
      });
    },
    //创建账号
    async createTableData() {
      //var res = await this.$http.get(`gka/api/accounts`);
      API.handleGet(API.accouts.create).then((res) => {
        if (res.code != 0) return this.$message(res.message);
        this.acountList = res.data;
        this.totalDataNum = res.page.total;
      });
    },
    //删除订单
    async handleDelete(row) {
      this.$confirm("确认删除？")
        .then(async () => {
          // var res = await this.$http.delete(`gka/api/account/${row.id}`);
          API.handleDelete(`${API.accouts.delete}/${row.id}`).then((res) => {
            if (res.code != 0) return this.$message.error(res.message);
            this.$message.success("删除成功");
            this.getTableData();
          });
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    //批量删除订单
    async handleBatchDelete() {
      this.$confirm("确认删除？")
        .then(async () => {
          var ids = [];
          if (this.multipleSelection && this.multipleSelection.length) {
            this.multipleSelection.forEach((item) => {
              ids.push(item.id);
            });
          }
          if (ids.length > 0) {
            var params = JSON.stringify(ids);
            //console.log(params)
            //var res = await this.$http.delete(`gka/api/accounts?ids=${params}`);
            API.handleDelete(`${API.accouts.batchDelete}?ids=${params}`).then(
              (res) => {
                if (res.code != 0) return this.$message.error(res.message);
                this.$message.success("删除成功");
                this.getTableData();
              }
            );
          } else {
            this.$message.error("请选择删除的对象");
          }
        })
        .catch(() => {
          this.$message.info("已取消删除");
        });
    },
    //查询操作
    async handleSearch() {
      this.currentPage = 1;
      var param = Object.assign(this.actionDrawerData, {
        page: this.currentPage,
        size: this.perPageCount,
      });
      //var newParam = JSON.stringify(param);
      //console.log(param)
      //var res = await this.$http.get(`gka/api/accounts?params=${newParam}`);
      API.handleGet(API.accouts.create, { params: param }).then((res) => {
        if (res.code != 0) return this.$message(res.message);
        this.acountList = res.data;
        this.totalDataNum = res.page.total;
      });
    },
    //高级查询
    async handleAdvancedSearch() {
      this.currentPage = 1;
      var param = Object.assign(this.actionDrawerData, {
        page: this.currentPage,
        size: this.perPageCount,
      });
      //var newParam = JSON.stringify(param);
      //var res = await this.$http.get(`gka/api/accounts?params=${newParam}`);
      API.handleGet(API.accouts.create, { params: param }).then((res) => {
        if (res.code != 0) return this.$message("查询数据失败");
        this.acountList = res.data;
        this.totalDataNum = res.page.total;
        this.openSearchDrawer = false;
      });
    },

    //创建日志
    handleCreateLog(row) {
      this.createDialogVisible = true;
      this.createLogId = row.id;
      API.handleGet(API.accouts.status).then((res) => {
        if (res.code != 0) return this.$message("查询数据失败");
        this.logStates = JSON.parse(JSON.stringify(res.data));
      });
    },
    handleRemoveLogImg(file, fileList) {
      this.logImgList = fileList;
    },
    handlePreLogview(file) {
      console.log(file);
    },
    handleUpload(file, fileList) {
      const isJPG =
        file.raw.type == "image/jpeg" ||
        file.raw.type == "image/png" ||
        file.raw.type == "image/jpg" ||
        file.raw.type == "image/gif";
      if (!isJPG) {
        this.$message.error("只能上传图片");
        return false;
      }

      this.logImgList.push(file);
    },
    //创建日志确认
    createLogModal() {
      let fb = new FormData();
      this.logImgList.forEach((el) => {
        if (el.status == "ready") {
          fb.append("file", el.raw);
        }
      });
      fb.append("log_content", this.logModalData.accountStatus);
      fb.append("account_id", this.createLogId);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        methods: "post",
      };
      var that = this;
      let userToken = Cookies.get("userToken");
      if (userToken) userToken = JSON.parse(userToken);
      fb.append("_user", userToken._user);

      this.$http.post(API.accouts.log, fb, config).then((res) => {
        if (res.data.code != 0) return that.$message("提交失败");
        that.$message({ message: "创建成功", type: "success" });
      });
      this.createDialogVisible = false;
      this.logModalData.accountStatus="";
      this.logImgList=[];
    },

    //查看日志
    handleCheckLog(row) {
      this.openLogsDrawer = true;
      this.logs = [];
      API.handleGet(`${API.accouts.logs}?accountID=${row.id}`).then((res) => {
        if (res.code != 0) return this.$message(res.message);
        this.logs = res.data;
      });
    },

    //高级查询重置
    handleAdvanceReset(actionDrawerData) {
      //this.$refs[actionDrawerData].resetFields();
      this.actionDrawerData = this.emptyData();
    },
    //查询重置
    handleReset(actionDrawerData) {
      //this.$refs[actionDrawerData].resetFields();
      this.actionDrawerData = this.emptyData();
    },
    //获取配置内容
    async getConfigData() {
      //var res = await this.$http.get(`gka/api/configs`);
      // console.log(res.data);
      API.handleGet(API.configs.getConfig).then((res) => {
        if (res.code != 0) return this.$message("请求数据失败");
        var datas = JSON.parse(JSON.stringify(res.data));

        let { keys, values, entries } = Object;
        for (let [key, value] of entries(datas.countries)) {
          this.countries.push({ key: key, value: value });
        }
        for (let [key, value] of entries(datas.account_status)) {
          this.account_status.push({ key: key, value: value });
        }
        for (let [key, value] of entries(datas.platforms)) {
          this.platforms.push({ key: key, value: value });
        }
      });
    },

    //获取手机终端的内容
    async getMobilesData() {
      //var res  = await this.$http.get('/gka//api/mobiles?params={"all":1}');
      API.handleGet(API.configs.getMobile).then((res) => {
        if (res.code != 0) return this.$message("请求数据失败");
        this.mobilesData = JSON.parse(JSON.stringify(res.data));
      });

      //console.log(mobileData);
    },
    //获取pc终端的内容
    async getPCsData() {
      //var res  = await this.$http.get('/gka//api/pcList?params={"all":1}');
      API.handleGet(API.configs.getPclist).then((res) => {
        if (res.code != 0) return this.$message("请求数据失败");
        this.pcsData = JSON.parse(JSON.stringify(res.data));
      });
      //console.log(pcData);
    },

    //表格复选框
    handleSelectionChange(val) {
      this.multipleSelection = val;
      //console.log(val);
    },
    advancedSearch() {
      this.openSearchDrawer = true;
    },
    //排序
    formatterDate(row, column) {
      return row.create_time;
    },
    //批量导入
    batchImport() {
      this.focusImport = true;
    },
    //分页
    handleSizeChange(val) {
      this.perPageCount = val;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
      //console.log(`当前页: ${val}`);
    },
    emptyData() {
      return {
        ids: "",
        account: "",
        q: "",
        user_id: "",
        status: "",
        country: "",
        region: "",
        name: "",
        mobileID: "",
        pcID: "",
        ctimeStart: "",
        ctimeEnd: "",
        utimeStart: "",
        utimeEnd: "",
      };
    },
  },
};
</script>
<style>
.el-table__header {
  background: #f1f2f3;
}
</style>
<style lang="scss" scoped>
.el-header {
  background-color: rgb(249 249 249);
  line-height: 60px;
  text-align: left;
  border: 1px solid #eee;
}
.table-info {
  border: 1px solid #eee;
  p {
    margin: 0;
  }
}
.profile {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.advancedFilter {
  .el-form--inline .el-form-item {
    width: 46%;
    margin-right: 20px;
    .el-select,
    .el-date-editor.el-input {
      width: 100%;
    }
  }
}
.a-link {
  color: #409eff;
  text-decoration: none;
}
.log-lists {
  border-bottom: 1px solid #eee;
}
.log-lists img {
  margin-bottom: 5px;
  width: 80%;
}
</style>