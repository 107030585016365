<template>
  <div>
    <el-container>
      <el-header>账号分发</el-header>
      <el-main>
        <el-form label-width="160px" :model="ruleForm"  :rules="rules"  ref="ruleForm" >
          <el-form-item label="转移人">
            <el-button type="text"  disabled>{{distributor}}</el-button>
          </el-form-item>
          <el-form-item label="优化师" prop="optimizer_name">
            <el-input style="width:400px" v-model="ruleForm.optimizer_name" placeholder="请输入优化师"></el-input>
          </el-form-item>
           <el-form-item label="转移方式" prop="type">
              <el-select v-model="ruleForm.type" placeholder="请选择" style="width:400px">
              <el-option label="未选择" value="0"></el-option>
              <el-option label="分享" value="1"></el-option>
              <el-option label="导出" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="adspower id" prop="adspower_ids">
            <el-input type="textarea"
              v-model="ruleForm.adspower_ids" style="width:400px"
              placeholder="多个adspower id用,分隔"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitClk('ruleForm')">确定</el-button>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import * as API from "@/api/common";
import Cookies from "js-cookie";
export default {
  name: "mail-list",
  data() {
    return {
      ruleForm:{
        optimizer_name: "",
        adspower_ids: "",
        type:"",
      },
      distributor: "",
      rules: {
          optimizer_name: [
            { required: true, message: '请输入优化师', trigger: 'blur' },
          ],
          type: [
            { required: true, message: '请选择转移方式', trigger: 'blur' },
          ],
          adspower_ids: [
            { required: true, message: '请输入adspower id', trigger: 'blur' },
          ]
        }
    }
  },
  mounted(){
      let userToken = Cookies.get("userToken");
      if (userToken) userToken = JSON.parse(userToken);
      this.distributor= userToken._user;
  },
  methods: {
    submitClk(formName) {
      var that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.opeFun(formName);
        }else{
          console.log('error submit!!');
          return false;
        }
      })
      
    },
    opeFun(formName){
      var that = this;
      let param=that.ruleForm;
      API.handlePost(API.accountSend.create, param).then((res) => {
        if (res.code != 0) return that.$message(res.message);
        that.$confirm(`是否确定将这些账号转移给${that.distributor}优化师？`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
          //转移
          API.handlePost(API.accountSend.send, param).then((res) => {
            if (res.code != 0) return that.$message("提交失败");
            that.$message.success("转移成功");
            that.$refs[formName].resetFields();
          })
        }).catch(() => {
          that.$message({
            type: 'info',
            message: '已取消提交'
          });          
        });
      })
    }
  }
}
</script>
<style>
</style>
<style lang="scss" scoped>
.el-header {
  background-color: rgb(249 249 249);
  line-height: 60px;
  text-align: left;
  border: 1px solid #eee;
}
.table-info {
  border: 1px solid #eee;
}
</style>
