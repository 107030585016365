<template>
  <div id="app">
    <!-- <Home></Home> -->
    <!-- <personal-account /> -->
    <!-- <terminal />  -->
    <!-- <raise-account /> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import Home from '@/views/Home.vue'; // @ is an alias to /src
export default{
   components:{
    // Home,
  }
}
</script>