<template>
  <div>
    <el-container>
      <el-header>任务列表</el-header>
      <el-main>
        <div>
          <el-form :inline="true" :model="advancedSearchData" ref="advancedSearchData" class="bindRecord">
            <el-form-item prop="name">
              <el-input v-model="advancedSearchData.name" placeholder="任务名称，可模糊"></el-input>
            </el-form-item>
            <el-form-item prop="taskIDs">
              <el-input v-model="advancedSearchData.taskIDs" placeholder="任务ID"></el-input>
            </el-form-item>
            <el-form-item prop="accountIDs">
              <el-input v-model="advancedSearchData.accountIDs" placeholder="账号ID"></el-input>
            </el-form-item>
            <el-form-item prop="type">
              <el-select v-model="advancedSearchData.type" placeholder="任务类型">
                <el-option v-for="item in task_types" :key="item.key" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="mobileID">
              <el-select v-model="advancedSearchData.mobileID" placeholder="关联手机终端">
                <el-option v-for="item in mobilesData" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="pcID">
              <el-select v-model="advancedSearchData.pcID" placeholder="关联PC终端">
                <el-option v-for="item in pcsData" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="status">
              <el-select multiple v-model="advancedSearchData.status" collapse-tags placeholder="任务状态" style="width:230px">
                <el-option v-for="item in task_status" :key="item.key" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="isHandled">
              <el-select v-model="advancedSearchData.isHandled" placeholder="是否已人工处理">
                <el-option v-for="item in isHandledOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
             <el-form-item label="" prop="utimeStart">
                <el-date-picker type="datetime" placeholder="最后更新开始时间" v-model="advancedSearchData.utimeStart"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
              </el-form-item>
              <el-form-item prop="utimeEnd">
                <el-date-picker type="datetime" placeholder="最后更新结束时间" v-model="advancedSearchData.utimeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              </el-form-item>
            <!-- <el-date-picker
              v-model="advancedSearchData.update_time"
              type="datetimerange"
              range-separator="-"
              start-placeholder="更新开始日期"
              value-format="yyyy-MM-dd HH:mm:ss HH:mm:ss"
              end-placeholder="更新结束日期"
              :default-time="['00:00:00', '23:59:59']">
            </el-date-picker> -->
            <el-form-item class="pl-10">
              <el-button type="primary" @click="handleSearch" icon="el-icon-search">查询</el-button>
              <el-button @click="handleReset('advancedSearchData')"  icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <span @click="advancedSearch"><i class="el-icon-brush"></i><el-button type="text">高级筛选</el-button></span>
          </el-form>
        </div>
        <!-- buttons -->
        <div class="d-flex justify-content-between">
          
          <div class="mb-10">
            <el-button type="primary"  @click="handleCreate"  icon="el-icon-circle-plus">创建任务</el-button>
            <el-dropdown class="ml-10"  @command="handleBatchOperation">
              <el-button type="primary">
                批量操作<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-delete" command="a">批量删除</el-dropdown-item>
                <el-dropdown-item icon="el-icon-refresh-right" command="b">批量重置</el-dropdown-item>
                <el-dropdown-item icon="el-icon-refresh-right" command="c">批量换号重置</el-dropdown-item>
                <el-dropdown-item icon="el-icon-refresh-right" command="d">批量标记</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
            <div class="mb-10">
            <el-button  type="text" @click="getTableData"  icon="el-icon-refresh"></el-button>
            <!-- <el-button type="text"  @click="openActionDrawer"  icon="el-icon-circle-plus"></el-button> -->
          </div>
        </div>
        <!-- 表格 -->
        <div>
          <el-table
            :data="timingTaskData"
            class="table-info"
            highlight-current-row
            @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="任务ID"
              prop="id"
              width="80">
            </el-table-column>
            <el-table-column
              label="配置ID"
              width="80"
              prop="config_id">
            </el-table-column>
            <el-table-column
              label="账号ID"
              width="80"
              prop="account_id">
            </el-table-column>
            <el-table-column
              label="adspower序号"
              prop="account_bind"
              >
               <template slot-scope="scope">
                  <div v-if="scope.row.account_bind">
                   <span>{{scope.row.account_bind.adspower_id}}</span>
                  </div>
              </template>
            </el-table-column>
           
           <el-table-column
              prop="id"
              label="账号详情">
              <template slot-scope="scope">
                <el-popover  trigger="click" placement="top">
                  <!-- <div v-for="(item, index) in accoutInfo" :key="index"> -->
                        <p>姓名: {{accoutInfo?accoutInfo.last_name:''}}{{accoutInfo?accoutInfo.first_name:''}}</p>
                        <p>账号: {{accoutInfo?accoutInfo.account:''}}</p>
                        <p>电话: {{accoutInfo?accoutInfo.phone:''}}</p>
                        <p>国家: {{accoutInfo?accoutInfo.country:''}}</p>
                        <p>语言: {{accoutInfo?accoutInfo.language:''}}</p>
                        <p>tfa_secret: <a class="a-link"  target="_blank" :href="accoutInfo?accoutInfo.tfa_code_url:''">{{accoutInfo?accoutInfo.tfa_secret:''}}</a></p>
                        <p>生日: {{accoutInfo?accoutInfo.birthday:''}}</p>
                        <p>备注: {{accoutInfo?accoutInfo.remark:''}}</p>
                        <p>状态码: {{accoutInfo?accoutInfo.status:''}}</p>
                        <p>状态值: {{accoutInfo?accoutInfo.status_str:''}}</p>
                        <div v-for="(item, index) in accoutInfo?accoutInfo.bind_devices:''" :key="index">
                        <p>备份: {{item.backup_record}}</p>
                        <p>adspower_id: {{item.adspower_id}}</p>
                        <p>绑定手机: {{item.mobile_name}}</p>
                        <p>绑定PC: {{item.pc_name}}</p>
                        </div>
                    <el-button size="mini" slot="reference" @click="getAccountInfo(scope.row)">账号详情</el-button>
                  <!-- <div slot="reference" class="name-wrapper">
                    <el-tag @click="getAccountInfo(scope.row)" size="medium">账号详情</el-tag>
                  </div> -->
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              label="任务名称"
              prop="name"
              >
            </el-table-column>
            <el-table-column
              label="任务类型">
              <template slot-scope="scope">
                 <span class="taskType">{{scope.row.type_str}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="cron"
              label="周期表达式">
            </el-table-column> -->
            <el-table-column
              label="任务状态"
              prop="status_str"
              >
            </el-table-column>
            <el-table-column
              prop="task_data"
              label="任务参数">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top" v-if ='scope.row.task_data && scope.row.task_data.length'>
                  <div v-for="(item, index) in scope.row.task_data" :key="index">
                    <p>{{item.code}} : {{item.val}}</p>
                  </div>
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">参数</el-tag>
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              prop="create_time"
              sortable
              label="创建时间">
            </el-table-column>
            <el-table-column
              prop="update_time"
              sortable
              label="最后更新时间">
            </el-table-column>
            <el-table-column
              prop="is_handled"
              label="是否人工处理">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <!-- <el-button
                  size="mini"
                  type="text"
                  @click="handleEdit(scope.row)">编辑</el-button> -->
                <el-button
                  size="mini"
                  type="text"
                  :disabled="scope.row.status==-1?true:false"
                  @click="handleDelete(scope.row)">删除</el-button>
                  <el-button
                  size="mini"
                  type="text"
                  :disabled="scope.row.status==-1?true:false"
                  @click="handleCheckLog(scope.row)">查看日志</el-button>
                  <el-button
                  size="mini"
                  type="text"
                  @click="handleMark(scope.row)">标记处理</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="text-center m-20">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              layout="total, prev, pager, next,sizes,jumper"
              :total="totalDataNum">
            </el-pagination>
          </div>
        </div>  
      </el-main>
    </el-container>
    <!-- 创建 动作 -->
    <create-action ref="createAction" :task-types = "task_types" :languages ="languages" :countries="countries" :task_data_options="task_data_options"></create-action>
    <!-- 修改 动作 -->
    <!-- <update-action ref="updateAction"></update-action> -->
    <!-- 查看日志 -->
    <el-drawer
      size="500px"
      title="日志列表"
      :visible.sync="openLogsDrawer"
      direction="rtl"
      class="advancedFilter"
      >
      <div class="px-20">
        <div v-if=" logs &&logs.length">
          <div v-for="(item,index) in logs" :key="item.id" class="log-lists">
            <p>({{index+1}})   IP: {{item.ip}}</p>
            <p>创建时间：{{item.create_time}}</p>
            <p>{{item.log_content}}</p>
            <img :src="item.log_image">
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 绑定终端记录 -->
    
    <!-- 高级筛选 -->
    <el-drawer
      size="600px"
      title="高级筛选"
      :visible.sync="openSearchDrawer"
      :wrapperClosable="false"
      @close="handleAdvanceCancel('advancedSearchData')"
      direction="rtl"
      class="advancedFilter"
      >
      <div class="px-20">
        <el-form label-position="top" :inline="true" :model="advancedSearchData" ref="advancedSearchData" class="demo-form-inline">        
          <el-form-item label="任务名称" prop="name">
            <el-input v-model="advancedSearchData.name" placeholder="请输入任务名称"></el-input>
          </el-form-item>
          <el-form-item label="任务ID" prop="taskIDs">
            <el-input v-model="advancedSearchData.taskIDs" placeholder="请输入任务ID"></el-input>
          </el-form-item>
          <el-form-item label="账号ID" prop="accountIDs">
            <el-input v-model="advancedSearchData.accountIDs" placeholder="请输入账号ID"></el-input>
          </el-form-item>
          <el-form-item label="任务类型" prop="type">
            <el-select v-model="advancedSearchData.type" placeholder="请选择任务类型">
              <!-- <el-option label="--请选择任务类型--"></el-option> -->
              <el-option v-for="item in task_types" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关联手机终端" prop="mobileID">
              <el-select v-model="advancedSearchData.mobileID" placeholder="关联手机终端">
                <el-option v-for="item in mobilesData" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关联PC终端" prop="pcID">
              <el-select v-model="advancedSearchData.pcID" placeholder="关联PC终端">
                <el-option v-for="item in pcsData" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          <el-form-item label="任务状态" prop="status">
            <el-select v-model="advancedSearchData.status" multiple  collapse-tags placeholder="请选择状态">
              <!-- <el-option label="--请选择任务状态--"></el-option> -->
              <el-option v-for="item in task_status" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否去重" prop="isDuplicate">
            <el-select v-model="advancedSearchData.isDuplicate"  collapse-tags placeholder="请选择状态">
              <!-- <el-option label="--请选择任务状态--"></el-option> -->
              <el-option v-for="item in isDuplicate" :key="item.key" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <div class="d-flex align-items-end">
            <el-form-item label="创建时间" prop="ctimeStart">
              <el-date-picker type="datetime" placeholder="开始时间" v-model="advancedSearchData.ctimeStart"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item  label="" prop="ctimeEnd">
              <el-date-picker type="datetime" placeholder="结束时间" v-model="advancedSearchData.ctimeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
          <div class="d-flex align-items-end">
            <el-form-item label="最后更新时间" prop="utimeStart">
              <el-date-picker type="datetime" placeholder="开始时间" v-model="advancedSearchData.utimeStart"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
            </el-form-item>
            <el-form-item prop="utimeEnd">
              <el-date-picker type="datetime" placeholder="结束时间" v-model="advancedSearchData.utimeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
          <div class="d-flex align-items-end">
            <el-form-item label="计划执行时间" prop="atimeStart">
              <el-date-picker type="datetime" placeholder="开始时间" v-model="advancedSearchData.atimeStart"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item prop="atimeEnd">
              <el-date-picker type="datetime" placeholder="结束时间" v-model="advancedSearchData.atimeEnd" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
          <el-form-item>
            <el-button @click="handleAdvancedReset('advancedSearchData')" icon="el-icon-refresh-right">重置</el-button>
            <el-button type="primary" icon="el-icon-search" @click="handleAdvancedSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>

  </div>
</template>
<script>
//  import UpdateAction from '@/components/raise-account/UpdateAction'
 import CreateAction from '@/components/raise-account/CreateAction'
//  import configs from '@/assets/js/common.js'
import * as API from '@/api/common'
  export default {
    name: 'raise-account',
    data() {
      return {
        activeName: 'first',
        actionName:"",
        openBindRecordDrawer: false, //打开绑定记录的弹层
        openSearchDrawer: false, //打开高级搜索弹层
        openLogsDrawer: false, //打开日志弹层
        currentPage:1,
        perPageCount:10,
        totalDataNum:0,
        countries:[],
        task_types:[],
        task_status:[],
        languages:[],
        mobilesData:[],
        pcsData:[],
        task_data_options:[],
        isDuplicate:[{'key':1, 'value': "是"}, {'key':0, 'value': "否"}],
        timingTaskData:[
          {
            taskIDs:"",
            name:"",
            type:"",
            cron:"",
            status_str:"",
            create_time:"",
            update_time:"",
            status:"",
          }
        ], 
        isHandledOptions: [
          {value: 0, label: "否"},
          {value: 1, label: "是"}
        ],
        multipleSelection: [],
        orderIdLists:[],
        accoutInfo:{},
        // advancedSearchData: {
        //   name: '',
        //   type: '',
        //   status: '',
        //   mobileID:'',
        //   pcID:'',
        //   update_time:''
        // },
         advancedSearchData: this.emptyData(),
          logs:[]
      };
    },
    components:{
      CreateAction,
      // UpdateAction,
    },
    created(){
      this.getTableData();
      // configs.getConfigData();
      this.getConfigData();
      this.getMobilesData();
      this.getPCsData();
    },
    methods: {
      openUpdateDrawer(){
        this.$refs.updateAction.openActionDrawer();
      },
      handleCreate(){
        this.$refs.createAction.createActionDrawer();
      },
      handleEdit(row){
        // console.log(index, row)
        if(row.status_str=="待执行"){
          this.$refs.createAction.updateActionDrawer(row);
        }else{
          this.$message.warning("只有待执行的任务才能编辑");
        }  
      },
      async getTableData(){
        //  var res = await this.$http.get(`/gka/anjian/task/getTasks?platform=FACEBOOK&mobile=X2&status=1&type=1`);
        var param= Object.assign(this.advancedSearchData,{page:this.currentPage, size: this.perPageCount})
         //param = JSON.stringify(param);
         //var res = await this.$http.get(`gka/api/tasks?params=${param}`);
         API.handleGet(API.tasks.create, {'params':param}).then(res =>{
           if(res.code != 0) return this.$message(res.message);
            this.timingTaskData = res.data;
            this.totalDataNum  = res.page.total;  
         })  
      },
      
      //获取配置内容
      async getConfigData(){
         //var res = await this.$http.get(`gka/api/configs`);
          
          API.handleGet(API.configs.getConfig).then(res =>{
            //console.log(res.data);
            if(res.code != 0) return this.$message(res.message);
            var datas = JSON.parse(JSON.stringify(res.data));

            let {keys, values, entries} = Object;
            for(let [key, value] of entries(datas.countries)){
              this.countries.push({'key':key, 'value': value});
            }
            for(let [key, value] of entries(datas.languages)){
              this.languages.push({'key':key, 'value': value});
            }
            for(let [key, value] of entries(datas.task_types)){
              this.task_types.push({'key':key, 'value': value});
            }
            for(let [key, value] of entries(datas.task_status)){
              this.task_status.push({'key':key, 'value': value});
            }
            for(let [key, value] of entries(datas.task_data_options)){
              this.task_data_options.push({'key':key, 'value': value});
            }
          })         
      },

      //删除订单
      async handleDelete(row){
        this.$confirm('确认删除？')
          .then(async ()=>{
            //var res = await this.$http.delete(`gka/api/task/${row.id}`);
            API.handleDelete(`${API.tasks.delete}/${row.id}`).then(res =>{
              if(res.code != 0) return this.$message.error(res.message);
              this.$message.success("删除成功");
              this.getTableData();
            })
            
          })
          .catch(()=>{
            this.$message.info("已取消删除"); 
          })
        
      },
      //批量删除订单
      async handleBatchDelete(){
        this.$confirm('确认删除？')
          .then(async ()=>{
            var ids=[];
            if(this.multipleSelection && this.multipleSelection.length){
              this.multipleSelection.forEach(item =>{
                ids.push(item.id);
              })
            }
            if(ids.length>0){
              var params= JSON.stringify(ids);
              //var res = await this.$http.delete(`gka/api/tasks?ids=${params}`);
              API.handleDelete(`${API.tasks.batchDelete}?ids=${params}`).then(res =>{
                if(res.code != 0) return this.$message.error(res.message);
                this.$message.success("删除成功");
                this.getTableData();
              })
              
            }else{
              this.$message.error("请选择删除的对象");
            }
          })
          .catch(()=>{
            this.$message.info("已取消删除"); 
          })
      },
      handleBatchReset(){
       this.$confirm('确认重置？(批量重置只对类型是PC收藏且状态处于执行中或执行失败时有效)')
          .then(async ()=>{
            var ids=[];
            if(this.multipleSelection && this.multipleSelection.length){
              this.multipleSelection.forEach(item =>{
                ids.push(item.id);
              })
            }
            if(ids.length>0){
              var params= JSON.stringify(ids);
              //  console.log(params)
              API.handlePut(`${API.tasks.batchDelete}?ids=${params}&operation=1`).then(res =>{
                if(res.code != 0) return this.$message.error(res.message);
                this.$message.success("批量重置成功");
                this.getTableData();
              }) 
            }else{
              this.$message.error("请选择重置的对象");
            }
          })
          .catch(()=>{
            this.$message.info("已取消重置"); 
          })
      },

      handleBatchChangeReset(){
        this.$confirm('确认重置？(换号重置只对类型是PC收藏且状态是502的有效)')
          .then(async ()=>{
            var ids=[];
            if(this.multipleSelection && this.multipleSelection.length){
              this.multipleSelection.forEach(item =>{
                if(item.type == 10 && item.status == 502){
                  ids.push(item.id);
                }
              })
              if(ids.length>0){
                var params= JSON.stringify(ids);
                //console.log(params)
                API.handlePut(`${API.tasks.batchDelete}?ids=${params}&operation=2`).then(res =>{
                  if(res.code != 0) return this.$message.error(res.message);
                  this.$message.success("批量重置成功");
                  this.getTableData();
                }) 
              }else{
                this.$message.error("只对类型是PC收藏且状态是502时才可以执行重置操作");
              }
            }else{
              this.$message.error("请选择重置的对象");
            }
            
          })
          .catch(()=>{
            this.$message.info("已取消重置"); 
          })
      },

      //批量标记
      handleBatchHandle(){
        var ids=[];
        if(this.multipleSelection && this.multipleSelection.length){
          this.multipleSelection.forEach(item =>{
            ids.push(item.id);
          })
          if(ids.length>0){
            this.$prompt('添加标记处理日志', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(({ value }) => {
                var params= {"task_ids":ids, "log_content":value};
                //console.log(params)
                API.handlePost(`${API.tasks.handleMarks}`, params).then(res =>{
                  if(res.code != 0) return this.$message.error(res.message);
                  this.$message.success("批量标记成功");
                  this.getTableData();
                }) 
              })
              .catch(()=>{
                this.$message.info("已取消标记"); 
              })

            
             
          }else{
          this.$message.error("请选择标记的对象");
        }
        }else{
          this.$message.error("请选择标记的对象");
        }


        
      },

      handleBatchOperation(command){
        switch (command){
          case "a": 
            this.handleBatchDelete();
            break;
          case "b": 
            this.handleBatchReset();
            break;
          case "c": 
            this.handleBatchChangeReset();
            break;
          case "d": 
            this.handleBatchHandle();
            break;  
        }
      },

      //查看日志
      handleCheckLog(row){
         this.openLogsDrawer = true;
         this.logs = [];
         API.handleGet(`${API.tasks.delete}/${row.id}`).then(res =>{
          if(res.code != 0) return this.$message(res.message);
          this.logs = res.data.task_log;
        }) 
      },

      //查询操作
      async handleSearch(){
        this.currentPage=1;
        var param = Object.assign(this.advancedSearchData, {page:this.currentPage, size: this.perPageCount});
        //var newParam = JSON.stringify(param);
        //console.log(param)
        //var res = await this.$http.get(`gka/api/tasks?params=${newParam}`);
        API.handleGet(API.tasks.create, {'params':param}).then(res =>{
          if(res.code != 0) return this.$message(res.message);
          this.timingTaskData = res.data;
          this.totalDataNum  = res.page.total;
        }) 
      },
      //高级查询
      async handleAdvancedSearch(){
        this.currentPage=1;
        var param = Object.assign(this.advancedSearchData, {page:this.currentPage, size: this.perPageCount});
        //var newParam = JSON.stringify(param);
        //var res = await this.$http.get(`gka/api/tasks?params=${newParam}`);
        API.handleGet(API.tasks.create,  {'params':param}).then(res =>{
          if(res.code != 0) return this.$message(res.message);
          this.timingTaskData = res.data;
          this.totalDataNum  = res.page.total;
          this.openSearchDrawer = false;
        })
      },
      //高级查询重置
      handleAdvancedReset(advancedSearchData){
        //this.$refs[advancedSearchData].resetFields();
         this.advancedSearchData=this.emptyData();
      },
      //高级查询取消
      handleAdvanceCancel(advancedSearchData){
        this.openSearchDrawer= false;
      },
      
      //查询重置
      handleReset(advancedSearchData){
       this.advancedSearchData=this.emptyData();
      },
       //获取手机终端的内容
      async getMobilesData(){
          API.handleGet(API.configs.getMobile).then(res =>{
            if(res.code != 0) return this.$message("请求数据失败");
            this.mobilesData = JSON.parse(JSON.stringify(res.data))
          })
      },
      //获取pc终端的内容
      async getPCsData(){
          API.handleGet(API.configs.getPclist).then(res =>{
            if(res.code != 0) return this.$message("请求数据失败");
            this.pcsData = JSON.parse(JSON.stringify(res.data))
          })
      },

      handleMark(row){
        this.$prompt('添加标记处理日志', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          var param = {"task_id":row.id, "log_content":value}
          //console.log(param);
          API.handlePost(`${API.tasks.handleMark}`, param).then(res =>{
          if(res.code != 0) return this.$message.error(res.message);
          this.$message.success("标记成功");
          this.getTableData();
          })
        }).catch(() => {
            this.$message.info("已取消标记");    
        });
      },

      //table表格复选框选择
       handleSelectionChange(val) {
         //console.log(val)
        this.multipleSelection = val;
      },
      bindRecordSearch(){
        console.log("查询")
      },
      advancedSearch(){
        // Object.assign(this.advancedSearchData, this.advancedSearchData);
        this.openSearchDrawer= true;
      },
      //绑定终端记录
      handleBindRecord(index, row){
        this.openBindRecordDrawer= true;
      },
      //排序
      formatterDate(row, column){
        return row.create_time
      },

      getAccountInfo(row){
        this.accoutInfo = {};
        API.handleGet(`${API.accouts.delete}/${row.account_id}`).then(res =>{
          if(res.code != 0) return this.$message.error(res.message);
          this.accoutInfo= res.data
        })
      },

       //分页
      handleSizeChange(val) {
        this.perPageCount = val;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getTableData();
        //console.log(`当前页: ${val}`);
      },
      emptyData(){
        return {
            taskIDs:"",
            name:"",
            accountIDs:"",
            type:"",
            status:[],
            ctimeStart:"",
            ctimeEnd:"",
            utimeStart:"",
            utimeEnd:"",
            atimeStart:"",
            atimeEnd:"",
            mobileID:"",
            pcID:"",
            isDuplicate:0
          }
      },
    }
  };
</script>
<style>
    .el-table__header{
     background: #f1f2f3;
    }
</style>
<style lang="scss" scoped>
  .el-header{
    background-color: rgb(249 249 249);
    line-height: 60px;
    text-align: left;
    border: 1px solid #eee;
  }
  .table-info{
    border:1px solid #eee;
    p{
      margin: 0;
    }
  }
.bindRecord .el-input{width: 170px;}
.bindRecord table p{margin: 0;}
.bindRecord .el-table{border:1px solid #eee}
.advancedFilter{
  .el-form--inline .el-form-item{
    width: 46%;
    margin-right: 20px;
    .el-select, .el-date-editor.el-input{width: 100%;}
  }
}
.el-form .el-select, .el-form .el-date-editor{width: 192px;}
.taskType{
  display: inline-block;
  padding: 3px 10px;
  border-radius: 3px;
  background: #f0f0f0;
}
.log-lists{border-bottom:1px solid #eee;}
.log-lists img{
  margin-bottom: 5px;
  width: 80%;
}
</style>