<template>
    <div class="action-con">
        <el-drawer
            title="关联个号"
            size="1000px"
            :visible.sync="openRelateDrawer"
            direction="rtl"
            class="relative-drawer"
            :before-close="handleClose">
            <div class="px-20">
              <el-row :gutter="20">
              <el-col :span="14">
                <section class="divide pr-20" style="height: calc(100vh - 200px) ">
                  <el-form :model="actionDrawerData">
                    <el-row :gutter="40" class="d-flex">
                      <el-col :span="18">
                        <div class="d-flex justify-content-between">
                          <el-form-item prop="region" class="mr-10">
                            <el-select v-model="actionDrawerData.region" placeholder="个号区域">
                              <el-option label="区域一" value="shanghai"></el-option>
                              <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item prop="region" class="mr-10">
                            <el-select v-model="actionDrawerData.region" placeholder="个号状态">
                              <el-option label="区域一" value="shanghai"></el-option>
                              <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                          </el-form-item>
                          <el-form-item prop="region">
                            <el-select v-model="actionDrawerData.region" placeholder="个号关联情况">
                              <el-option label="区域一" value="shanghai"></el-option>
                              <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                        <div class="d-flex justify-content-between">
                          <el-form-item class="mr-10">
                            <el-input v-model="actionDrawerData.firstname" placeholder="个号ID"></el-input>
                          </el-form-item>
                          <el-form-item class="mr-10">
                            <el-input v-model="actionDrawerData.firstname" placeholder="任务数开始值"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-input v-model="actionDrawerData.firstname" placeholder="任务数结束值"></el-input>
                          </el-form-item>
                        </div>
                        <div class="d-flex justify-content-between">
                          <el-form-item class="pr-10">
                            <el-input v-model="actionDrawerData.firstname" placeholder="手机终端编码"></el-input>
                          </el-form-item>
                          <el-form-item>
                            <el-input v-model="actionDrawerData.firstname" placeholder="PC终端编码"></el-input>
                          </el-form-item>
                        </div>
                      </el-col>
                      <el-col :span="6" class="d-flex flex-column justify-content-around">
                        <el-button type="default">重置</el-button>
                        <el-button class="mx-clear" type="primary" @click="cancelForm">查询</el-button>
                      </el-col>
                    </el-row>
                  </el-form>
                  <!-- 选择复选框 -->
                  <div>
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" class="mt-20 mb-10 pl-10">全选</el-checkbox>
                    <!-- <div style="margin: 15px 0;"></div> -->
                    <!-- <el-checkbox-group v-model="personalId" @change="handleCheckedIdChange">
                      <div>
                        <el-checkbox v-for="(personal, index) in personalIdAll" :label="personal.con+''" :key="index">{{personal}}</el-checkbox>
                      </div>
                    </el-checkbox-group> -->
                    <div v-for="(personal, index) in personalIdAll" :key="index" class="d-flex justify-content-between p-10 border mb-10 align-items-center">
                      <el-checkbox v-model="personal.index" style="width:30px" @change="handleCheckedIdChange"></el-checkbox>
                      <span class="mr-auto">{{personal.id}}</span>
                      <span>个号：{{personal.con}} <b class="blue-color"><i class="el-icon-close font-weight-bold hide" /></b></span>
                    </div>
                  </div>
                </section>
              </el-col>
              <el-col :span="10">
                <div class="d-flex justify-content-between mb-10">
                  <span class="gray-color-9 font-14">共<b class="font-16">2</b>条</span>
                  <span class="blue-color pointer font-14">清空</span>
                </div>
                <div class="relatelist">
                  <div class="d-flex justify-content-between p-10 border mb-10 align-items-center">
                    <span>15339903993</span>
                    <span>个号：2990 <b class="blue-color"><i class="el-icon-close font-weight-bold hide" /></b></span>
                  </div>
                  <div class="d-flex justify-content-between p-10  border mb-10 align-items-center">
                    <span>15339903993</span>
                    <span>个号：2990 <b class="blue-color"><i class="el-icon-close font-weight-bold hide" /></b></span>
                  </div>
                </div>
              </el-col>
            </el-row>
            <div class="text-center">
              <el-button @click="cancelForm">取 消</el-button>
              <el-button type="primary" @click="$refs.drawer.closeDrawer()">确定</el-button>
            </div>
            </div>
            
        </el-drawer>
      </div>
</template>
<script>
  export default {
    name: 'relate-account',
    props:{
    },
    data() {
        return {
          openRelateDrawer:false,
          isIndeterminate: true, //用于checkbox全选
          personalId:[],
          personalIdAll:[{con:15339903993, id:1302, index:0},{con:15339903993, id:1302, index:1},{con:15339903993, id:1302, index:2}],
          checkAll:false,
           actionDrawerData: {
            firstname: '',
            lastname: '',
            sex:'',
            birthday:'',
            phonenumber:'',
            email:'',
            platform:'',
            imageUrl:'',
            password:'',
            verifycode:'',
            verifycode1L:'',
            registe:'',
            region: '',
            taskParameter:[{name:"参数一",value: 'first'}],
          }
        }
      },
      mounted(){
        // this.actionDrawer = this.openDrawer;
      },
      watch:{
      
      },
      methods: {
       openActionDrawer(){
          this.openRelateDrawer = true;
        },
       handleClose() {
          this.$confirm('确认关闭？')
            .then(
              this.openDrawer = false
            )
            .catch();
        },
        cancelForm() {
          this.openRelateDrawer = false;
        },
        handleCheckAllChange(val) {
          this.personalId = val ? this.personalIdAll : [];
          this.isIndeterminate = false;
        },
        handleCheckedIdChange(value) {
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.personalIdAll.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.personalIdAll.length;
        }
      }
  }
</script>
<style lang="scss">
  
</style>
