<template>
  <div>
    <el-container>
      <el-header>line顾客好友</el-header>
      <el-main>
        <div>
          <el-form :inline="true" :model="searchData" ref="searchData" class="search-area">
            <el-form-item prop="ids">
              <el-input v-model="searchData.id" placeholder="id" class="short-width"></el-input>
            </el-form-item>
            <el-form-item prop="account">
              <el-input v-model="searchData.account_id" placeholder="账号id"  class="short-width"></el-input>
            </el-form-item>
            <el-form-item prop="user_name">
              <el-input v-model="searchData.user_name" placeholder="平台昵称/备注名" class="short-width"></el-input>
            </el-form-item>
            <el-form-item prop="offical_account">
              <el-input v-model="searchData.offical_account" placeholder="公众号" class="short-width"></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.type" placeholder="类型" class="short-width">
                <el-option key="1" label="公众号好友" value="1"></el-option>
                <el-option key="2" label="顾客好友" value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.is_sms_null" placeholder="是否有手机号" class="short-width">
                <el-option key="1" label="是" value="1"></el-option>
                <el-option key="2" label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.is_email_null" placeholder="是否有邮箱" class="short-width">
                <el-option key="1" label="是" value="1"></el-option>
                <el-option key="2" label="否" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="utimeStart">
              <el-date-picker type="datetime" placeholder="创建开始时间" v-model="searchData.ctimeStart"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
            </el-form-item>
            <el-form-item prop="utimeEnd">
              <el-date-picker type="datetime" placeholder="创建结束时间" v-model="searchData.ctimeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item label="" prop="utimeStart">
              <el-date-picker type="datetime" placeholder="最后更新开始时间" v-model="searchData.utimeStart"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
            </el-form-item>
            <el-form-item prop="utimeEnd">
              <el-date-picker type="datetime" placeholder="最后更新结束时间" v-model="searchData.utimeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" icon="el-icon-search">查询</el-button>
              <el-button @click="handleReset('searchData')"  icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
          </el-form>
          <div class="mb-10">
            <el-button type="primary"  @click="handleCreate"  icon="el-icon-circle-plus">创建</el-button>
            <el-button icon="el-icon-delete" @click="handleBatchDelete">批量删除</el-button>
            <el-button icon="el-icon-chat-line-square" @click="handleSendEmail">发送邮件</el-button>
            <el-button icon="el-icon-message" @click="handleSendMessage">发送短信</el-button>
          </div> 
          <el-table
            :data="customersList"
            class="table-info"
             @selection-change="handleSelectionChange"
            highlight-current-row
            >
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="id"
              width="80"
              prop="id">
            </el-table-column>
             <el-table-column
              label="平台账号"
              prop="pf_account">
            </el-table-column>
            <el-table-column
              label="头像"
              width="50px"
              prop="avatar">
              <template slot-scope="scope">
                <img :src="scope.row.avatar" style="width:50px;" />
              </template>
            </el-table-column>
              <el-table-column
              label="平台昵称"
              prop="pf_nickname">
            </el-table-column>
             <el-table-column
              label="备注名"
              prop="remark_name">
            </el-table-column>
            <el-table-column
              label="加好友时间"
              prop="add_time">
            </el-table-column>
            
            <el-table-column
              label="目前标签"
              prop="current_label_code_str">
              <!-- <template slot-scope="scope">
                 {{scope.row.is_active == 1? "活跃":"不活跃"}}
              </template> -->
            </el-table-column>
            <el-table-column
              prop="init_label_code_str"
              label="最初标签">
            </el-table-column>
            <el-table-column
              label="区号"
              prop="phone_country_code">
            </el-table-column>
            <el-table-column
              prop="phone"
              label="手机号">
            </el-table-column>
            <el-table-column
              prop="email"
              label="邮箱">
            </el-table-column>
            <el-table-column
              prop="gender_str"
              label="性别">
              <template slot-scope="scope">
                {{scope.row.gender == 0?"女":"男"}}
              </template>
            </el-table-column>
            <el-table-column
              prop="age"
              label="年龄">
            </el-table-column>
            <el-table-column
              prop="profession"
              label="职业">
            </el-table-column>
            <el-table-column
              prop="official_accounts"
              label="联系">
              <template slot-scope="scope">
                <el-popover trigger="hover" placement="top" v-if ='scope.row.official_accounts && scope.row.official_accounts.length'>
                  <div v-for="(item, index) in scope.row.official_accounts" :key="index">
                   <el-link type="primary"  :href="item.link">{{item.account_name}}</el-link>
                  </div>
                  <div slot="reference" class="name-wrapper">
                    <el-link type="primary" :underline="false" size="medium">line chat</el-link>
                    <!-- <el-tag >去聊天</el-tag> -->
                  </div>
                </el-popover>
              </template>
            </el-table-column>
            <el-table-column
              prop="is_visit"
              label="是否回访">
              <template slot-scope="scope">
                <span :class="scope.row.is_visit?'green-color':'gray-color-9'">{{scope.row.is_visit == 0?"否":"是"}}</span>
              </template>
            </el-table-column>
              <el-table-column
              prop="update_time"
              width="150px"
              label="更新时间"
              >
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="handleEdit(scope.row)">编辑</el-button>
                <el-button
                  size="mini"
                  type="text"
                  :disabled="scope.row.status==-1?true:false"
                  @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="text-center m-20">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              layout="total, prev, pager, next,sizes,jumper"
              :total="totalDataNum">
            </el-pagination>
          </div>
        </div>
        </el-main>
    </el-container>
    <!-- 创建 动作 -->
    <item-action ref="itemAction"></item-action>
    <!-- 发送邮件弹层 -->
    <el-dialog title="发送邮件" :visible.sync="dialogEmailVisible">
      <div class="mb-20">
        <div>发送对象：<span class="peopleNum black-color">{{emailLists.length}}</span> 人  &nbsp;&nbsp;&nbsp;&nbsp; 
          <el-popover
            placement="bottom"
            width="200"
            trigger="click">
            <ul class="ul-list">
              <li v-for="(item, index) in emailLists" :key="index">{{item}}<i class="el-icon-close" @click="deleteEmailNum(index)"></i></li>
            </ul>
            <el-button slot="reference" type="text">查看</el-button>
          </el-popover>
        </div>
        <div class="email-title" v-if="false">主题：<el-input v-model="emailTitle" placeholder=""></el-input></div>
      </div>
      <quill-editor ref="text" v-model="emailModelCon" class="myQuillEditor" :options="editorOption" />
      <div slot="footer" class="dialog-footer">
        <div class="inline-block float-left left-info">
        邮件模板：
        <el-select v-model="emailInfo" placeholder="邮件模板"  @change="getEmailModelsCon">
          <el-option
            v-for="item in emailModels"
            :key="item.id"
            :label="item.name"
            :value="[item.id, item.template]">
          </el-option>
        </el-select>
        </div>
        <el-button type="primary" @click="sendEmail" icon="el-icon-s-promotion">发送</el-button>
      </div>
    </el-dialog>

    <!-- 发送短信弹层 -->
    <el-dialog title="发送短信" :visible.sync="dialogMessageVisible">
      <div  class="mb-10">
        <div>发送对象：<span class="peopleNum black-color">{{phoneLists.length}}</span> 人  &nbsp;&nbsp;&nbsp;&nbsp; 
          <el-popover
            placement="bottom"
            width="200"
            trigger="click">
            <ul class="ul-list">
              <li v-for="(item, index) in phoneLists" :key="index">{{item}}<i class="el-icon-close" @click="deletePhoneNum(index)"></i></li>
            </ul>
            <el-button slot="reference" type="text">查看</el-button>
          </el-popover>
        </div>
      </div>
      <!-- <quill-editor ref="text" v-model="messageModelCon" class="myQuillEditor" :options="editorOption" /> -->
      <el-input
        type="textarea"
        :rows="8"
        placeholder="请输入内容"
        v-model="messageModelCon">
      </el-input>
      <div slot="footer" class="dialog-footer">
        <div class="inline-block float-left left-info">
          短信模板:
          <el-select v-model="messageInfo" placeholder="发件人" class="mr-20" @change="getModelsCon">
          <el-option
            v-for="item in messageModels"
            :key="item.id"
            :label="item.name"
            :value="[item.id, item.template]">
          </el-option>
        </el-select>
        </div>
        <el-button type="primary" @click="sendMessage" icon="el-icon-s-promotion">发送</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ItemAction from '@/components/line-customers/ItemAction.vue';
import * as API from '@/api/common';
import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
  export default {
    name: 'line-customers',
    data() {
      return {
        currentPage:1,
        perPageCount:10,
        totalDataNum:0,
        dialogEmailVisible:false, //邮件弹框
        emailTitle:"",
        editorOption: {} ,
        dialogMessageVisible:false, //短信弹框
        messageInfo:[],
        messageModels:[],
        messageModelId:"",
        messageModelCon:"",
        phoneLists:[],
        emailInfo:[],
        emailModels:[],
        emailModelId:"",
        emailModelCon:"",
        emailLists:[],
        customersList: [
          // {
          //   pf_account:'',
          //   pf_nickname: '',
          //   next_visit_time:'',
          //   add_time: '',
          //   current_label_code:'',
          //   init_label_code:'',
          //   phone_country_code:'',
          //   phone:'',
          //   gender:'',
          //   age:'',
          //   profession:'',
          // }
        ],
        multipleSelection: [],
        searchData: this.emptyData()
      };
    },
    components:{
        ItemAction,
        quillEditor
    },
    mounted() {
      this.getTableData();
    },
    methods: {
      getTableData(){
        let param = Object.assign(this.searchData,{page:this.currentPage, size: this.perPageCount});
         API.handleGet(API.lineCustomers.create, {'params':param}).then(res =>{
            if(res.code != 0) return this.$message(res.message);
            this.customersList = res.data;
            this.totalDataNum  = res.page.total;
        }) 
      },

      //查询操作
      async handleSearch(){
        this.currentPage=1;
        this.getTableData();
      },
      handleCreate(){
        this.$refs.itemAction.createActionDrawer();
      },
      handleEdit(row){
        this.$refs.itemAction.updateActionDrawer(row.id); 
      },
      handleDelete(row){
        this.deleteOpe([row.id]);
      },
      handleBatchDelete(){
        var ids=[];
        if(this.multipleSelection && this.multipleSelection.length){
          this.multipleSelection.forEach(item =>{
            ids.push(item.id);
          })
        }
        console.log(ids);
        if(ids.length>0){
          this.deleteOpe(ids);
        }
      },
      deleteOpe(idArr){
        console.log(idArr);
        this.$confirm('确认删除？')
          .then(async ()=>{
            //var res = await this.$http.delete(`gka/api/task/${row.id}`);
            API.handleDelete(`${API.lineCustomers.create}?ids=[${idArr}]`).then(res =>{
              if(res.code != 0) return this.$message.error(res.message);
              this.$message.success("删除成功");
              this.getTableData();
            })
          })
          .catch(()=>{
            this.$message.info("已取消删除"); 
          })
      },
      //打开发送邮件弹层
      handleSendEmail(){
        this.dialogEmailVisible = true;
        this.emailModelCon = '';
        this.emailInfo = [];
        API.handleGet(API.lineCustomers.email).then(res =>{
          if(res.code != 0) return this.$message.error(res.message);
          this.emailModels = res.message;
        })
      },
      getEmailModelsCon(val){
        console.log(val);
        this.emailModelCon = val[1];
        this.emailModelId = val[0]
      },
      sendEmail(){
        var formData = new FormData();
        formData.append('templateId',this.emailModelId);
        formData.append('emails',this.emailLists.join(","));
        formData.append('content',this.emailModelCon);
        if(this.emailLists.length<=0) return this.$message.error("没有发送对象");
        if(this.emailInfo.length<=0) return this.$message.error("请选择短信模板");
        let config = {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          methods: 'post'
        }
        var that = this;
        this.$http.post(API.lineCustomers.sendEmail,formData,config).then(res =>{
           //console.log(res);
            if(res.data.code != 0) return this.$message(res.message);
            this.$message.success(res.data.message.msg);
        }) 
        this.dialogEmailVisible = false;
      },
      //打开发送短信弹层
      handleSendMessage(){
        this.dialogMessageVisible = true;
        this.messageModelCon = '';
        this.messageInfo = [];
        API.handleGet(API.lineCustomers.message).then(res =>{
          if(res.code != 0) return this.$message.error(res.message);
          this.messageModels = res.message;
        })
      },
      getModelsCon(val){
        this.messageModelCon = val[1];
        this.messageModelId = val[0]
      },
      sendMessage(){
        var formData = new FormData();
        formData.append('templateId',this.messageModelId);
        formData.append('phones',this.phoneLists.join(","));
        formData.append('content',this.messageModelCon);
        if(this.phoneLists.length<=0) return this.$message.error("没有发送对象");
        if(this.messageInfo.length<=0) return this.$message.error("请选择短信模板");
        let config = {
          headers: {
              'Content-Type': 'multipart/form-data'
          },
          methods: 'post'
        }
        var that = this;
        this.$http.post(API.lineCustomers.sendMessage,formData,config).then(res =>{
           //console.log(res);
            if(res.data.code != 0) return this.$message(res.message);
           
            this.$message.success(res.data.message.msg);
        }) 
        this.dialogMessageVisible = false;
      },
      //table表格复选框选择
       handleSelectionChange(val) {
        this.phoneLists = [];
        this.emailLists = [];
        val.forEach(e =>{
          if(e.phone){
            this.phoneLists.push(e.phone)
          }
          if(e.email){
            this.emailLists.push(e.email);
          }
        })
        this.multipleSelection = val;
      },
      deletePhoneNum(index){
        this.phoneLists.splice(index,1)
      },
      deleteEmailNum(index){
        this.emailLists.splice(index,1);
      },
      //查询重置
      handleReset(){
        this.searchData = this.emptyData();
      },
      //分页
      handleSizeChange(val) {
        this.perPageCount = val;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getTableData();
      },
      emptyData(){
        return {
          id:'',
          account_id: '',
          user_name: '',
          offical_account: '',
          is_email_null: '',
          is_sms_null: '',
          type: '',
          ctimeStart:'',
          ctimeEnd: '',
          utimeStart: '',
          utimeEnd: '',
        }
      },
    }
  };
</script>
<style>
.search-area .el-input__inner{
  width: 180px;
  padding-right: 15px;
}
.short-width .el-input__inner{width: 170px;}
  .el-table__header{
    background: #f1f2f3;
  }
  .email-title .el-input__inner{
      border:none;
  }
  .ql-container,.ql-editor{
    max-height: 450px;
    min-height: 100px;
  }
</style>
<style lang="scss" scoped>
  .el-header{
    background-color: rgb(249 249 249);
    line-height: 60px;
    text-align: left;
    border: 1px solid #eee;
  }
  .table-info{
    border:1px solid #eee;
  }
  .peopleNum{font-size: 16px;}
  .ul-list{
    list-style-type: none;
    padding: 0;
    li{
      padding-bottom: 10px;
      i{
        float: right;
        line-height: 1.5;
        cursor: pointer;
        &:hover{
          color: #409EFF;
        }
      }
    }
  }
  .email-title{
    border-bottom: 1px solid #eee;
    .el-input{
      width: 80%;
    }
  }
  .left-info{
    font-size: 14px;
  }
</style>