import {GET, POST, PUT, DELETE} from '@/utils/request'

export var configs={
    'getConfig':`gka/api/configs`,
    'getMobile':'/gka//api/mobiles?params={"all":1}',
    'getPclist':'/gka//api/pcList?params={"all":1}'
}

export var accouts={
    'create':"gka/api/accounts",  // 账号列表get
    "delete":"gka/api/account", //删除订单 创建账号post  更新账号put 删除账号delete
    "batchDelete":"gka/api/accounts",  //批量删除
    "logs":"gka/api/account/logs",
    "status": "gka/api/account/status",
    "log": "gka/api/account/log"
}

export var devices={
    'createMobile':"gka/api/mobiles",  // 账号列表get
    "deleteMobile":"gka/api/mobile", //删除订单 创建账号post  更新账号put 删除账号delete
    "batchDeleteMobile":"gka/api/mobiles",  //批量删除
    'createPC':"gka/api/pcList",  // 账号列表get
    "deletePc":"gka/api/pc", //删除订单 创建账号post  更新账号put 删除账号delete
    "batchDeletePc":"gka/api/pcList"  //批量删除 
}
export var tasks ={
    'create':"gka/api/tasks",  // 账号列表get
    "delete":"gka/api/task", //删除订单 创建账号post  更新账号put 删除账号delete
    "batchDelete":"gka/api/tasks",  //批量删除
    "handleMark":"gka/api/task/handleLog", //标记处理
    "handleMarks":"gka/api/task/handleLogs" //标记批量处理
}

export var configration ={
    'create':"gka/api/taskconfigs",  // 账号列表get
    "delete":"gka/api/taskconfig", //删除订单 创建账号post  更新账号put 删除账号delete
    "batchDelete":"gka/api/taskconfigs"  //批量删除
}
export var line={
    'create':"gka/api/linevooms",  //统计列表get 删除
    'edit':"gka/api/linevoom",  //编辑
    'config':"gka/api/lineconfigs", //获取配置信息
    "voom":"gka/api/voom"
}
export var statistics={
    'create':"gka/api/pages"  //统计列表get
}
export var lineCustomers={
    'create':"gka/api/customers",  //统计列表get
    'edit':"gka/api/customer",  //编辑
    'config':"gka/api/lineconfigs", //获取配置信息
    "visitlogs":"gka/api/visit", // 访问记录查看更多
    "orderdata":"gka/api/order", //订单数据查看更多
    'message':"gka/api/message", //短信模板列表
    'sendMessage':"gka/api/sendMessage", //发送短信
    'email': "gka/api/email", //邮箱模板列表
    "sendEmail": "gka/api/sendEmail" // 发送邮箱
}
export var mailList={
    'list': "gka/api/mailList",
    'create': "gka/api/mail",
    'search': "gka/api/mails"
}
export var workbench={
    'create':"gka/api/page"  //统计列表get
}
export var accountSend={
    'create':"gka/api/distributeAccounts",  //
    "send":"gka/api/distributeAccountsCreate"
}

export function handleGet(url,data){
    return GET(url,data);
}

//accounts api
export function handlePut(url,data){
   return PUT(url,data)
}

export function handleDelete(url,data){
    return DELETE(url, data)
}

export function handlePost(url,data){
    return POST(url, data)
}
