<template>
  <div>
    <el-container>
      <!-- <el-header>终端</el-header> -->
      <el-main>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="pc端" name="first"> 
            <div>
            <el-form :inline="true" :model="PCAdvancedSearch" ref="PCAdvancedSearch">
              <el-form-item prop="name">
                <el-input v-model="PCAdvancedSearch.name" placeholder="终端名称，可模糊"></el-input>
              </el-form-item>
              <el-form-item prop="status">
                <el-select v-model="PCAdvancedSearch.status" placeholder="终端状态">
                    <el-option v-for="item in device_status" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handlePCSearch" icon="el-icon-search">查询</el-button>
                <el-button @click="handlePCReset('PCAdvancedSearch')"  icon="el-icon-refresh-right">重置</el-button>
              </el-form-item>
              <span @click="advancedSearch"><i class="el-icon-brush"></i><el-button type="text">高级筛选</el-button></span>
            </el-form>
            </div>
            <!-- buttons -->
            <div class="d-flex justify-content-between">
              <!-- <label>pc终端</label> -->
              <div class="mb-10">
                <el-button type="primary"  @click="openPCActionDrawer"  icon="el-icon-circle-plus">创建</el-button>
                <el-button icon="el-icon-delete" @click="handlePCBatchDelete">批量删除</el-button>
                <!-- <el-button type="primary"  @click="openPCActionDrawer"  icon="el-icon-circle-plus">批量导入</el-button> -->
              </div>
            </div>
            <!-- pc端表格 -->
            <div>
              <el-table
                :data="PCTableData"
                class="table-info"
                highlight-current-row
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  prop="id"
                  width="55">
                </el-table-column>
                <el-table-column
                  label="终端编码"
                  prop="name">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="PC终端名称">
                </el-table-column>
                <el-table-column
                  prop="status_str"
                  label="终端状态">
                </el-table-column>
                <!-- <el-table-column
                  prop="accounts_count"
                  label="终端状态">
                </el-table-column> -->
                <el-table-column
                  prop="create_time"
                  sortable
                  label="创建时间">
                </el-table-column>
                <el-table-column
                  prop="update_time"
                  sortable
                  label="最后更新时间">
                </el-table-column>
                <el-table-column
                  prop="accounts_count"
                  label="关联个号数">
                </el-table-column>
                <el-table-column
                  label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="text"
                      @click="handlePCEdit(scope.row)">编辑</el-button>
                    <el-button
                      size="mini"
                      type="text"
                      @click="handlePCDelete(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <!-- 分页 -->
            <div class="text-center m-20">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                layout="total, prev, pager, next,sizes,jumper"
                :total="totalDataNum">
              </el-pagination>
            </div>
            </div>
          </el-tab-pane>
          <!-- 手机端 -->
          <el-tab-pane label="手机端" name="second">
            <div>
              <el-form :inline="true" :model="mobileAdvancedSearch" ref="mobileAdvancedSearch">
                <el-form-item prop="name">
                  <el-input v-model="mobileAdvancedSearch.name" placeholder="终端名称，可模糊"></el-input>
                </el-form-item>
                <el-form-item prop="status">
                  <el-select v-model="mobileAdvancedSearch.status" placeholder="终端状态">
                    <el-option v-for="item in device_status" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="handleMobileSearch" icon="el-icon-search">查询</el-button>
                  <el-button @click="handleMobileReset('mobileAdvancedSearch')"  icon="el-icon-refresh-right">重置</el-button>
                </el-form-item>
                <span @click="mobileAdvancedSearchClk"><i class="el-icon-brush"></i><el-button type="text">高级筛选</el-button></span>
              </el-form>
            </div>
            <!-- buttons -->
            <div class="d-flex justify-content-between">
              <!-- <label>手机终端</label> -->
              <div class="mb-10">
                <el-button type="primary"  @click="handleMobileCreate"  icon="el-icon-circle-plus">创建</el-button>
                <el-button icon="el-icon-delete" @click="handleMobileBatchDelete">批量删除</el-button>
                <!-- <el-button type="primary"  @click="openMobileActionDrawer"  icon="el-icon-circle-plus">批量导入</el-button> -->
              </div>
            </div>
            <!-- 移动端 表格 -->
            <div>
              <el-table
                :data="mobileTableData"
                class="table-info"
                highlight-current-row
                @selection-change="handleSelectionChange">
                <el-table-column
                  type="selection"
                  prop="id"
                  width="55">
                </el-table-column>
                <el-table-column
                  label="设备id"
                  width="80"
                  prop="id">
                </el-table-column>
                <el-table-column
                  prop="name"
                  label="设备名称(编号)">
                </el-table-column>
                <el-table-column
                  prop="language"
                  label="设备语言">
                </el-table-column>
                <el-table-column
                  prop="phone_country"
                  label="设备接码所用地区">
                </el-table-column>
                <el-table-column
                  prop="country_search_text"
                  label="搜索地区关键词">
                  <!-- <template slot-scope="scope">
                    <p>{{scope.row.status = 0?"停用":"在用"}}</p>
                  </template> -->
                </el-table-column>
                <el-table-column             
                  label="代理类型">
                  <template slot-scope="scope">
                    {{scope.row.proxy_type? scope.row.proxy_type:'SOCKS5'}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="proxy_ip"
                  label="代理ip">
                </el-table-column>
                <el-table-column
                  prop="proxy_port"
                  label="代理端口">
                </el-table-column>
                <el-table-column
                  prop="proxy_location"
                  label="代理所属地区(区域)">
                </el-table-column>
                <el-table-column
                  prop="status_str"
                  label="状态名称">
                </el-table-column>
                <el-table-column
                  prop="accounts_count"
                  label="设备绑定账号数">
                </el-table-column>
                 <el-table-column
                  prop="create_time"
                  sortable
                  label="创建时间">
                </el-table-column>
                <el-table-column
                  prop="update_time"
                  sortable
                  label="最后更新时间">
                </el-table-column>
                <el-table-column
                  prop="is_task_acceptable"
                  sortable
                  label="是否可接收任务">
                  <template slot-scope="scope">
                    {{scope.row.is_task_acceptable? '是':'否'}}
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      type="text"
                      @click="handleMobileEdit(scope.row)">编辑</el-button>
                    <el-button
                      size="mini"
                      type="text"
                      @click="handleMobileDelete(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="text-center m-20">
              <el-pagination
                background
                @size-change="handleMobileSizeChange"
                @current-change="handleMobileCurrentChange"
                :current-page="mobileCurrentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                layout="total, prev, pager, next,sizes,jumper"
                :total="mobileTotalDataNum">
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      
        </el-main>
    </el-container>
    <!-- pc创建/修改 动作 -->
    <PC-item-action ref="pcItemAction"></PC-item-action>
    <!-- 手机端创建/修改 动作 -->
    <mobile-item-action ref="mobileItemAction"></mobile-item-action>
    <!-- 绑定终端记录 -->
    <!-- <el-drawer
      size="400"
      title="绑定终端记录"
      :visible.sync="openBindRecordDrawer"
      direction="rtl"
      class="bindRecord"
      >
      <div class="px-20">
        <el-form :inline="true" :model="bindRecord" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="bindRecord.name" placeholder="操作人"></el-input>
          </el-form-item>
          <el-form-item >
            <el-date-picker type="date" placeholder="绑定开始时间" v-model="bindRecord.starttime"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-date-picker type="date" placeholder="绑定结束时间" v-model="bindRecord.endtime"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="bindRecordSearch">查询</el-button>
          </el-form-item>
        </el-form>
       <el-table
            :data="bindRecordTable"
            stripe
            style="width: 100%">
            <el-table-column
              label="绑定时间"
              prop="time"
              width="150">
            </el-table-column>
            <el-table-column
              label="绑定终端"
              prop="terminal"
             >
              <template>
                  <p>PC终端：PC_FB0010#注册机编码：399093</p>
                  <p>PC终端：PC_FB0010#注册机编码：399093</p> 
              </template>
            </el-table-column>
            <el-table-column 
              label="操作人员"
              prop="name"
              width="120"
              > 
            </el-table-column>
        </el-table>
        </div>
    </el-drawer> -->
    <!-- pc高级筛选 -->
    <el-drawer
      size="600px"
      title="高级筛选"
      :visible.sync="openSearchDrawer"
      direction="rtl"
      class="advancedFilter"
      >
      <div class="px-20">
        <el-form label-position="top" :inline="true" :model="PCAdvancedSearch" ref="PCAdvancedSearch">
          <el-form-item label="pc设备名称" prop="name">
            <el-input v-model="PCAdvancedSearch.name" placeholder="请输入pc设备名称"></el-input>
          </el-form-item>
          <el-form-item label="终端状态" prop="status">
            <el-select v-model="PCAdvancedSearch.status" placeholder="终端状态">
              <el-option v-for="item in device_status" :key="item.value" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <div class="d-flex align-items-end">
            <el-form-item label="创建时间" prop="ctimeStart">
              <el-date-picker type="datetime" placeholder="开始时间" v-model="PCAdvancedSearch.ctimeStart" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item  label="" prop="ctimeEnd">
              <el-date-picker type="datetime" placeholder="结束时间" v-model="PCAdvancedSearch.ctimeEnd" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
          <div class="d-flex align-items-end">
            <el-form-item label="最后更新时间" prop="utimeStart">
              <el-date-picker type="datetime" placeholder="开始时间" v-model="PCAdvancedSearch.utimeStart" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item prop="utimeEnd">
              <el-date-picker type="datetime" placeholder="结束时间" v-model="PCAdvancedSearch.utimeEnd" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
          
          <el-form-item>
            <el-button @click="handlePCAdvancedReset('PCAdvancedSearch')"  icon="el-icon-refresh-right">重置</el-button>
            <el-button type="primary" icon="el-icon-search" @click="handlePCAdvancedSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <!-- 移动高级筛选 -->
    <el-drawer
      size="600px"
      title="高级筛选"
      :visible.sync="openMobileSearchDrawer"
      direction="rtl"
      class="advancedFilter"
      >
      <div class="px-20">
        <el-form label-position="top" :inline="true" :model="mobileAdvancedSearch" ref="mobileAdvancedSearch">
          <el-form-item label="手机设备名称" prop="name">
            <el-input v-model="mobileAdvancedSearch.name" placeholder="请输入手机设备名称"></el-input>
          </el-form-item>
          <el-form-item label="终端状态" prop="status">
            <el-select v-model="mobileAdvancedSearch.status" placeholder="终端状态">
              <el-option v-for="item in device_status" :key="item.value" :label="item.value" :value="item.key"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备所属地区(区域)" prop="country">
            <el-input v-model="mobileAdvancedSearch.country" placeholder="设备所属地区(区域)"></el-input>
          </el-form-item>
          <div class="d-flex align-items-end">
            <el-form-item label="创建时间" prop="ctimeStart">
              <el-date-picker type="datetime" placeholder="开始时间" v-model="mobileAdvancedSearch.ctimeStart"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item  label="" prop="ctimeEnd">
              <el-date-picker type="datetime" placeholder="结束时间" v-model="mobileAdvancedSearch.ctimeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
          <div class="d-flex align-items-end">
            <el-form-item label="最后更新时间" prop="utimeStart">
              <el-date-picker type="datetime" placeholder="开始时间" v-model="mobileAdvancedSearch.utimeStart"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item prop="utimeEnd">
              <el-date-picker type="datetime" placeholder="结束时间" v-model="mobileAdvancedSearch.utimeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </div>
          
          <el-form-item>
            <el-button @click="handleMobileAdvancedReset('mobileAdvancedSearch')"  icon="el-icon-refresh-right">重置</el-button>
            <el-button type="primary" icon="el-icon-search" @click="handleMobileAdvancedSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>
<script>
 import PCItemAction from '@/components/terminal/PCItemAction.vue'
 import MobileItemAction from '@/components/terminal/MobileItemAction.vue'
 import * as API from '@/api/common'
  export default {
    name: 'terminal',
    data() {
      return {
        activeName: 'first',
        actionName:"",
        openBindRecordDrawer: false, //打开绑定记录的弹层
        openSearchDrawer: false, //打开高级搜索弹层
        openMobileSearchDrawer:false,
        mobileCurrentPage:1,
        currentPage:1,
        perPageCount:10,
        totalDataNum:0,
        mobileTotalDataNum:0,
        device_status:[],
        languages:[],
        countries:[],
        proxy_locations:[],
        bindRecord:{
          name:"",
          starttime:"",
          endtime:""
        },
        // bindRecordTable:[
        //   {
        //     time:'2021/10/09 12:00',
        //     terminal:"PC终端：PC_FB0010#注册机编码：399093",
        //     name:"张三"
        //   },
        //    {
        //     time:'2021/10/09 13:00',
        //     terminal:"PC终端：PC_FB0010#注册机编码：399093",
        //     name:"张三"
        //   }
        // ],
         mobileTableData:[{
           id:"",
           name:"",
           language:"",
           phone_country:"",
           country_search_text:"",
           proxy_type:"",
           proxy_ip:"",
           proxy_port:"",
           proxy_location:"",
           status:"",
           status_str:"",
           accounts_count:"",
           create_time:"",
           update_time:""
         }],
         PCTableData:[{
           id:"",
           name:"",
           language:"",
           status:"",
           status_str:"",
           accounts_count:"",
           create_time:"",
           update_time:""
         }],
        // PCAdvancedSearch: {
        //   name: '',
        //   status: ''
        // },
        // mobileAdvancedSearch: {
        //   name: '',
        //   status: ''
        // },
        mobileAdvancedSearch:this.emptyMobileData(),
        PCAdvancedSearch:this.emptyPCData(),
        multipleSelection: [],
      };
    },
    components:{
      PCItemAction,
      MobileItemAction
    },
    created(){
      this.getMobileTableData();
      this.getPCTableData();
      this.getConfigData();
    },
    methods: {
      openPCActionDrawer(){
        this.$refs.pcItemAction.openActionDrawer();
      },
      handleMobileCreate(){
        this.$refs.mobileItemAction.openActionDrawer();
      },

      async getPCTableData(){
        //var param = JSON.stringify({page:this.currentPage, size: this.perPageCount});
        //var res = await this.$http.get(`gka/api/pcList?params=${param}`);
        var param = Object.assign(this.PCAdvancedSearch, {page:this.currentPage, size: this.perPageCount});
        API.handleGet(API.devices.createPC, {'params':param}).then(res =>{
          if(res.code != 0) return this.$message("请求数据失败");
          this.PCTableData = res.data;
          this.totalDataNum  = res.page.total;
        })
        
      },
      handlePCEdit(row){
        this.$refs.pcItemAction.updateActionDrawer(row);
      },
      handlePCDelete(row){
        this.$confirm('确认删除？')
          .then(async ()=>{
            //var res = await this.$http.delete(`gka/api/pc/${row.id}`)
            API.handleDelete(`${API.devices.deletePc}/${row.id}`).then(res =>{
              if(res.code != 0) return this.$message.error("请求失败");
              this.$message.success("删除成功");
              this.getPCTableData();
            })
          })
          .catch(function(){
            this.$message.info("已取消")
          });
      },
      handlePCBatchDelete(){
        this.$confirm('确认删除？')
          .then(async ()=>{
            var ids=[];
            if(this.multipleSelection && this.multipleSelection.length){
              this.multipleSelection.forEach(item =>{
                ids.push(item.id);
              })
            }
            if(ids.length>0){
              var params= JSON.stringify(ids);
              //var res = await this.$http.delete(`gka/api/pcList?ids=${params}`)`
              API.handleDelete(`${API.devices.batchDeletePc}?ids=${params}`).then(res =>{
                if(res.code != 0) return this.$message.error("请求失败");
                this.$message.success("删除成功");
                this.getPCTableData();
              })
              
            }else{
              this.$message.error("请选择删除的对象");
            }
          }) 
          .catch(function(){
            this.$message.info("已取消删除")
          }); 
      },
      //查询操作
      async handlePCSearch(){
        this.currentPage=1;
        var param = Object.assign(this.PCAdvancedSearch, {page:this.currentPage, size: this.perPageCount});
        API.handleGet(API.devices.createPC, {'params':param}).then(res =>{
          if(res.code != 0) return this.$message("查询数据失败");
          this.PCTableData = res.data;
          this.totalDataNum  = res.page.total;
        }) 
      },
      handlePCReset(PCTableData){
        this.PCAdvancedSearch = this.emptyPCData();
      },
      handlePCAdvancedReset (PCAdvancedSearch){
        this.PCAdvancedSearch = this.emptyPCData();
      },
      //高级查询
      async handlePCAdvancedSearch(){
        this.currentPage=1;
        var param = Object.assign(this.PCAdvancedSearch, {page:this.currentPage, size: this.perPageCount});
        API.handleGet(API.devices.createPC, {'params':param}).then(res =>{
          if(res.code != 0) return this.$message("查询数据失败");
          this.PCTableData = res.data;
          this.totalDataNum  = res.page.total;
          this.openSearchDrawer= false;
        })
        
      },

      //手机端操作
      async getMobileTableData(){
        //var param = JSON.stringify({page:this.mobileCurrentPage, size: this.perPageCount});
        //var res = await this.$http.get(`gka/api/mobiles?params=${param}`);
        var param = Object.assign(this.mobileAdvancedSearch, {page:this.mobileCurrentPage, size: this.perPageCount});
        API.handleGet(API.devices.createMobile, {'params':param}).then(res =>{
          if(res.code != 0) return this.$message("请求数据失败");
          this.mobileTableData = res.data;
          this.mobileTotalDataNum  = res.page.total;
        })
        
      },
      handleMobileEdit(row){
        this.$refs.mobileItemAction.updateActionDrawer(row);
      },
      handleMobileDelete(row){
        var that = this;
        that.$confirm('确认删除？')
          .then(async ()=>{
            //var res = await that.$http.delete(`gka/api/mobile/${row.id}`);
            API.handleDelete(`${API.devices.deleteMobile}/${row.id}`).then(res =>{
              if(res.code != 0) return that.$message.error("请求失败");
              that.$message.success("删除成功");
              that.getMobileTableData();
            })
            
          })
          .catch(() =>{
            that.$message.info("已取消删除");
          })
        
      },
      async handleMobileBatchDelete(){
        this.$confirm('确认删除？')
          .then(async ()=>{
            var ids=[];
            if(this.multipleSelection && this.multipleSelection.length){
              this.multipleSelection.forEach(item =>{
                ids.push(item.id);
              })
            }
            if(ids.length>0){
              var params= JSON.stringify(ids);
              //var res = await this.$http.delete(`gka/api/mobiles?ids=${params}`);
              API.handleDelete(`${API.devices.batchDeleteMobile}?ids=${params}`).then(res =>{
                if(res.code != 0) return this.$message.error("请求失败");
                this.$message.success("删除成功");
                this.getMobileTableData();
              })
              
            }else{
              this.$message.error("请选择删除的对象");
            }
          })
          .catch(()=>{
             this.$message.info("已取消删除"); 
          })
       
      },
      //查询操作
      async handleMobileSearch(){
        this.mobileCurrentPage=1;
        var param = Object.assign(this.mobileAdvancedSearch, {page:this.mobileCurrentPage, size: this.perPageCount});
        //var newParam = JSON.stringify(param);
        //console.log(param)
        //var res = await this.$http.get(`gka/api/mobiles?params=${newParam}`);
        API.handleGet(API.devices.createMobile, {'params':param}).then(res =>{
          if(res.code != 0) return this.$message("查询数据失败");
          this.mobileTableData = res.data;
          this.mobileTotalDataNum  = res.page.total;
        })
        
      },
      handleMobileReset(mobileTableData){
        //this.$refs[mobileTableData].resetFields();
        this.mobileAdvancedSearch = this.emptyMobileData();
      },

      handleMobileAdvancedReset(mobileTableData){
        this.mobileAdvancedSearch = this.emptyMobileData();
        //this.$refs[mobileTableData].resetFields();
      },

      //高级查询
      async handleMobileAdvancedSearch(){
        this.mobileCurrentPage=1;
        var param = Object.assign(this.mobileAdvancedSearch, {page:this.mobileCurrentPage, size: this.perPageCount});
        //var newParam = JSON.stringify(param);
        //var res = await this.$http.get(`gka/api/mobiles?params=${newParam}`);
        API.handleGet(API.devices.createMobile,  {'params':param}).then(res =>{
          if(res.code != 0) return this.$message("查询数据失败");
          this.mobileTableData = res.data;
          this.mobileTotalDataNum  = res.page.total;
          this.openMobileSearchDrawer = false;
        })
        
      },

      //获取配置内容
      async getConfigData(){
         //var res = await this.$http.get(`gka/api/configs`);
          // console.log(res.data);
        API.handleGet(API.configs.getConfig).then(res =>{
          //console.log(res)
          if(res.code != 0) return this.$message("请求数据失败");
          var datas = JSON.parse(JSON.stringify(res.data));

          let {keys, values, entries} = Object;
          for(let [key, value] of entries(datas.device_status)){
            this.device_status.push({'key':key, 'value': value});
          }
          for(let [key, value] of entries(datas.languages)){
            this.languages.push({'key':key, 'value': value});
          } 
          for(let [key, value] of entries(datas.countries)){
            this.countries.push({'key':key, 'value': value});
          } 
          for(let [key, value] of entries(datas.proxy_locations)){
            this.proxy_locations.push({'key':key, 'value': value});
          } 
        })
          
      },

      handleClick(tab, event) {
        //console.log(tab, event);
      },
       handleSelectionChange(val) {
        this.multipleSelection = [];
        this.multipleSelection = val;
      },
      bindRecordSearch(){
        console.log("查询")
      },
      advancedSearch(){
        this.openSearchDrawer= true;
      },
      mobileAdvancedSearchClk(){
        this.openMobileSearchDrawer= true;
      },
      //分页
      handleSizeChange(val) {
        this.perPageCount = val;
        this.getMobileTableData();
      },
      handleCurrentChange(val) {
        this.mobileCurrentPage = val;
        this.getMobileTableData();
      },
      handleMobileSizeChange(val) {
        this.perPageCount = val;
        this.getMobileTableData();
      },
      handleMobileCurrentChange(val) {
        this.mobileCurrentPage = val;
        this.getMobileTableData();
      },
      //绑定终端记录
      handleBindRecord(index, row){
        this.openBindRecordDrawer= true;
      },
      //排序
      formatterDate(row, column){
        return row.create_time
      },
      emptyPCData(){
        return {
          name:"",
          status:"",
          ctimeStart:"",
          ctimeEnd:"",
          utimeStart:"",
          utimeEnd:"",
        }
      },
      emptyMobileData(){
        return {
          name:"",
          status:"",
          country:"",
          ctimeStart:"",
          ctimeEnd:"",
          utimeStart:"",
          utimeEnd:"",
        }
      },
    }
  };
</script>
<style>
    .el-table__header{
     background: #f1f2f3;
    }
</style>
<style lang="scss" scoped>
  .el-header{
    background-color: rgb(249 249 249);
    line-height: 60px;
    text-align: left;
    border: 1px solid #eee;
  }
  .table-info{
    border:1px solid #eee;
    p{
      margin: 0;
    }
  }
.bindRecord .el-input, .bindRecord  .el-date-editor.el-input {width: 180px;}
.bindRecord table p{margin: 0;}
.bindRecord .el-table{border:1px solid #eee}
.advancedFilter{
  .el-form--inline .el-form-item{
    width: 46%;
    margin-right: 20px;
    .el-select, .el-date-editor.el-input{width: 100%;}
  }
}
</style>