<template>
  <div>
    <el-container>
      <el-header>主页状态</el-header>
      <el-main>
        <div>
          <el-form :inline="true" :model="searchData" ref="searchData">
            <el-form-item>
              <el-input v-model="searchData.page_id" placeholder="主页id"></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.status" placeholder="状态" class="short-width">
                <el-option key="1" label="正常" value="1"></el-option>
                <el-option key="2" label="异常" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-date-picker type="datetime" placeholder="最后维护开始时间" v-model="searchData.timeStart"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-date-picker type="datetime" placeholder="最后维护结束时间" v-model="searchData.timeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" icon="el-icon-search">查询</el-button>
              <el-button @click="handleReset('searchData')"  icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
           </el-form>
          <el-table
            :data="workbenchList"
            class="table-info"
            highlight-current-row
            >
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
             <el-table-column
              label="主页id"
              width="140"
              prop="page_id">
            </el-table-column>
              <el-table-column
              label="主页名称"
              prop="page_name">
            </el-table-column>
            <el-table-column
              width="80"
              label="评分"
              prop="score">
            </el-table-column>
            <el-table-column
              label="历史评分(时间-评分)"
              width="230"
              prop="last_scores">
              <template slot-scope="scope">
                <div v-if="scope.row.last_scores.length"> 
                  <div v-for="(item, index) in scope.row.last_scores" :key="index" v-show="index<2">
                    {{item}}
                  </div>
                  <div v-if="scope.row.last_scores.length>2">
                    <el-popover trigger="hover" placement="top" v-if='scope.row.last_scores && scope.row.last_scores.length'>
                    <div v-for="(item, index) in scope.row.last_scores" :key="index">
                      {{item}}
                    </div>
                    <div slot="reference" class="name-wrapper inline-block">
                      <el-link type="primary" size="medium">更多</el-link>
                    </div>
                  </el-popover>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="是否活跃"
              prop="is_active">
              <template slot-scope="scope">
                 {{scope.row.is_active == 1? "活跃":"不活跃"}}
              </template>
            </el-table-column>
            <el-table-column
              prop="time30"
              label="最近30天是否维护">
              <template slot-scope="scope">
                 {{scope.row.time30 == 1? "是":"否"}}
              </template>
            </el-table-column>
            <el-table-column
              label="最近7天是否维护"
              prop="time7">
              <template slot-scope="scope">
                 {{scope.row.time7 == 1? "是":"否"}}
              </template>
            </el-table-column>
            <el-table-column
              label="最近3天是否维护"
              prop="time3">
              <template slot-scope="scope">
                 {{scope.row.time3 == 1? "是":"否"}}
              </template>
            </el-table-column>
            <el-table-column
              prop="last_keep_time"
              label="最近维护时间">
            </el-table-column>
            <el-table-column
              prop="create_time"
              sortable
              width="160"
              label="创建时间">
            </el-table-column>
            <el-table-column
              prop="update_time"
              sortable
              width="160"
              label="更新时间">
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="text-center m-20">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              layout="total, prev, pager, next,sizes,jumper"
              :total="totalDataNum">
            </el-pagination>
          </div>
        </div>
        </el-main>
    </el-container>
  </div>
</template>
<script>
 import * as API from '@/api/common'
  export default {
    name: 'home-statistics',
    data() {
      return {
        currentPage:1,
        perPageCount:10,
        totalDataNum:0,
        workbenchList: [],
        searchData: this.emptyData()
      };
    },
    components:{
    },
    created() {
      this.getTableData();
    },
    methods: {
      getTableData(){
        let param = Object.assign(this.searchData,{page:this.currentPage, size: this.perPageCount});
         API.handleGet(API.workbench.create, {'params':param}).then(res =>{
            if(res.code != 0) return this.$message(res.message);
            this.workbenchList = res.data;
            this.totalDataNum  = res.page.total;
        }) 
      },
     
      //查询操作
      async handleSearch(){
        this.getTableData();
      },
      //查询重置
      handleReset(actionDrawerData){
        this.searchData = this.emptyData();
      },
      //分页
      handleSizeChange(val) {
        this.perPageCount = val;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getTableData();
      },
      emptyData(){
        return {
          page_id:'',
          status:'',
          timeEnd:'',
          timeStart:'',

        }
      },
    }
  };
</script>
<style>
    .el-table__header{
     background: #f1f2f3;
    }
</style>
<style lang="scss" scoped>
  .el-header{
    background-color: rgb(249 249 249);
    line-height: 60px;
    text-align: left;
    border: 1px solid #eee;
  }
  .table-info{
    border:1px solid #eee;
  }
</style>