<template>
    <div>
      <div class="action-con ">
        <el-drawer
            :title="drawerTitle"
            size="580px"
            :wrapperClosable="false"
            :visible.sync="openDrawer"
            @close="handleClose('actionDrawerData')"
            direction="rtl">
            <section class="px-20">
              <div class="d-flex justify-content-between px-20 font-15">
                <label class="font-weight-bold">PC终端</label>
                <span class="gray-color-9 font-14"><em class="red-color">*</em>必填项</span>
              </div>
               <el-form label-position="top" :model="actionDrawerData"  :rules="rules" ref="actionDrawerData" class="p-20">
                <el-form-item 
                  label="PC终端名称"
                  prop="name"
                  >
                  <el-input v-model="actionDrawerData.name" placeholder="请填写PC终端名称"></el-input>
                </el-form-item>
                <el-form-item v-if="!isCreate" prop="status" label="终端状态">
                  <el-select v-model="actionDrawerData.status" placeholder="终端状态"  class="width-100">
                      <el-option label="停用" value="0"></el-option>
                      <el-option label="在用" value="1"></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item 
                  label=" 针对市场"
                  :rules="[{required: true, message: '请选择市场', trigger: 'blur'}]">
                  <el-select v-model="actionDrawerData.region" placeholder="请选择市场" style="width: 100%;">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                  </el-select>
                </el-form-item>

                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item 
                      label=" PC端_系统"
                      :rules="[{required: true, message: '请填写PC系统版本', trigger: 'blur'}]"
                      >
                      <el-input v-model="actionDrawerData.name" placeholder="请填写PC系统版本"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item 
                      label=" PC端_机型"
                      :rules="[{required: true, message: '请填写PC端机型', trigger: 'blur'}]">
                      <el-input v-model="actionDrawerData.name" placeholder="请填写PC端机型"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <div class="d-flex justify-content-between my-20 font-15">
                  <label class="font-weight-bold">终端IP</label>
                  <span class="gray-color-9 font-14"><em class="red-color">*</em>必填项</span>
                </div>
                <el-form-item 
                  label="内网IP"
                  :rules="[{required: true, message: '请填写内网IP', trigger: 'blur'}]"
                  >
                  <el-input v-model="actionDrawerData.name" placeholder="请填写内网IP"></el-input>
                </el-form-item>
                <el-form-item 
                  label="外网IP"
                  :rules="[{required: true, message: '请填写外网IP', trigger: 'blur'}]"
                  >
                  <el-input v-model="actionDrawerData.name" placeholder="请填写外网IP"></el-input>
                </el-form-item>
                <div class="d-flex justify-content-between my-20 font-15">
                  <label class="font-weight-bold">关联个号<span class="gray-color-9 font-14 ">（非必关联）</span></label>
                  <span class="blue-color pointer" @click="openRelativeClk"><i class="el-icon-edit-outline"></i> 关联</span>
                </div>
                <div class="d-flex justify-content-between mb-10">
                  <span class="gray-color-9 font-14">共<b class="font-16">2</b>条</span>
                  <span class="blue-color pointer">清空</span>
                </div>
                <div class="border relateArea">
                  <div class="d-flex justify-content-between p-10">
                    <span>15339903993</span>
                    <span>个号：2990 <b class="blue-color"><i class="el-icon-close font-weight-bold hide" /></b></span>
                  </div>
                  <div class="d-flex justify-content-between p-10">
                    <span>15339903993</span>
                    <span>个号：2990 <b class="blue-color"><i class="el-icon-close font-weight-bold hide" /></b></span>
                  </div>
                </div> -->
                <div class="demo-drawer__footer">
                  <el-button @click="cancelForm">取 消</el-button>
                  <el-button type="primary" @click="submitButton('actionDrawerData')">确定</el-button>
                </div>
              </el-form>
            </section>
        </el-drawer>
      </div>
      <!-- 关联个号 -->
      <!-- <relate-account></relate-account> -->
      
    </div>
</template>
<script>
// import RelateAccount from './RelateAccount.vue';
import * as API from '@/api/common'
  export default {
    name: 'pc-item-action',
    components: { 
      // RelateAccount 
    },
    props:{
    },
    data() {
        return {
          actionDrawer: false, 
          openRelativeDrawer:false, //关联个号弹层
          drawerTitle:"",
          openDrawer: false,
          isCreate:false,
          createId:"",
          // loading: false,
          // isIndeterminate: true, //用于checkbox全选
          // checkAll:false,
          // personalId:[],
          // personalIdAll:[{con:15339903993, id:1302, index:0},{con:15339903993, id:1302, index:1},{con:15339903993, id:1302, index:2}],
          // options: [{
          //   value: '选项1',
          //   label: '黄金糕'
          // }],
          actionDrawerData: {
            name:'',
            status:""
          },
          rules: {
            name:[{required: true, message: '请填写设备名称', trigger: 'blur'}],
          }
        }
      },
      methods: {
        openActionDrawer(){
          this.drawerTitle="创建";
          this.openDrawer= true;
          this.isCreate = true;
        },
        updateActionDrawer(data){
          this.drawerTitle="编辑";
          this.openDrawer= true;
          console.log(data);
          this.$nextTick(function(){
            this.createId = data.id;
            this.actionDrawerData = {name:data.name, status:data.status+""};
            //.actionDrawerData = JSON.parse(JSON.stringify(data.name));
          })
        },

        submitButton(actionDrawerData){
          var that  = this;
          //创建操作
          if(this.isCreate){
            that.$refs[actionDrawerData].validate((valid) => {
              if (valid) {
                //that.$http.post(`gka/api/pc`,that.actionDrawerData).then(res =>{
                API.handlePost(API.devices.deletePc, that.actionDrawerData).then(res =>{
                  if(res.code != 0) return that.$message({message:res.message, type:'error'});
                  that.$message({message:"创建成功", type:'success'});
                  this.openDrawer= false;
                  //更新表格
                  that.$parent.getPCTableData();
                  this.$refs[actionDrawerData].resetFields()
                })
              }
            })
          }else{
            // var id = this.actionDrawerData.id;
            // delete this.actionDrawerData.id;
            // delete this.actionDrawerData.status_str;
            // delete this.actionDrawerData.accounts_count;
            //that.$http.put(`gka/api/pc/${this.createId}`,that.actionDrawerData).then(res =>{
            API.handlePut(`${API.devices.deletePc}/${this.createId}`, that.actionDrawerData).then(res =>{
              if(res.code != 0) return that.$message({message:res.message, type:'error'});
              that.$message({message:"更新成功", type:'success'});
               //更新表格
              that.$parent.getPCTableData();
              that.$refs[actionDrawerData].resetFields();
              this.openDrawer= false;
            })           
          }
        },

        openRelativeClk(){
          this.openRelativeDrawer= true;
        },
        handleClose(actionDrawerData){
          this.$refs[actionDrawerData].resetFields();
        },
        // handleClose() {
        //   this.$confirm('确认关闭？')
        //     .then(
        //       this.openDrawer = false
        //     )
        //     .catch();
        // },
        cancelForm() {
          this.openDrawer= false;
        },
        handleCheckAllChange(val) {
          this.personalId = val ? this.personalIdAll : [];
          this.isIndeterminate = false;
        },
        handleCheckedIdChange(value) {
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.personalIdAll.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.personalIdAll.length;
        }
      }
  }
</script>
<style lang="scss">
  .el-form--label-top .el-form-item__label{
    padding: 0 !important;
  }
  .el-form-item{
    text-align: left;
  }
  .relateArea{
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relatelist{
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relative-drawer{
    .el-form-item{
      margin-bottom: 10px; 
    }
  }
  .divide{
    border-right: 1px solid #eee;
    
  }
</style>
