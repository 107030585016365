<template>
    <div>
      <div class="action-con ">
        <el-drawer
            :title="drawerTitle"
            size="580px"
            :visible.sync="openDrawer"
            :wrapperClosable="false"
             @close="handleClose('actionDrawerData')"
            direction="rtl">
            <section class="px-20">
              <el-form label-position="top" :model="actionDrawerData" :rules="rules" ref="actionDrawerData">
                <el-form-item
                  label="内容"
                  prop="content"> 
                    <el-input v-model="actionDrawerData.content"  type="textarea" :autosize="{ minRows: 2, maxRows: 6}" placeholder="请填写内容"></el-input>
                </el-form-item>
                <el-form-item
                  label="标签"
                  prop="tags"> 
                    <el-input v-model="actionDrawerData.tags" placeholder="请填写标签"></el-input>
                </el-form-item>
                <el-form-item 
                    label="手机设备id"
                    prop="mobile_ids">
                    <el-select v-model="actionDrawerData.mobile_ids" placeholder="手机设备名id" multiple  style="width: 100%;"  @change="configTypeChange">
                      <el-option v-for="item in mobiles" :key="item.key" :label="item.value" :value="item.key"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                  label="请上传图片"
                >
                  <el-upload
                  ref="upload"
                  action
                  name="upload"
                  list-type="picture-card"
                  :on-preview="handlePreview"
                  :on-exceed ="handleExceed"
                  :auto-upload= false
                  :file-list="fileList"
                  :on-change= "handleUpload"
                  :limit="9"
                  multiple
                  :on-remove="handleRemove">
                  <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                </el-form-item>

                <div class="demo-drawer__footer">
                <el-button @click="cancelForm('actionDrawerData')">取 消</el-button>
                <el-button type="primary" @click="submitButton('actionDrawerData')" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
              </div>
              </el-form>
            </section>
        </el-drawer>
      </div>
    </div>
</template>
<script>
import { filterParams } from '@/assets/js/common.js'
import * as API from '@/api/common'
  export default {
    name: 'update-action',
    props:{
      mobiles:{type: Array}
    },
      
    data() {
        return {
          drawerTitle:"创建",
          actionDrawer: false, 
          openRelativeDrawer:false, //关联个号弹层
          openDrawer: false,
          loading: false,
          isCreate:false, //判断打开弹出是否是创建操作
          updateId:"",
          dialogVisible:false, //图片
          dialogImageUrl:"", //图片
          fileList:[], //图片
          rules:{
            content:[{required: true, message: '请填写内容', trigger: 'blur'}],
            tags:[{required: true, message: '请填写标签', trigger: 'blur'}],
          },
          actionDrawerData: {
           content:"",
           tags:"",
           mobile_ids:[],
          },
        }
      },
      components:{
      },
      
      mounted(){
        // this.actionDrawer = this.openDrawer;
      },
      watch:{
      
      },
      methods: {
        createActionDrawer(){
          this.drawerTitle="创建";
          this.openDrawer= true;
          this.isCreate = true;
          this.fileList = [];
          //this.task_data=[];
        },
        updateActionDrawer(data){
          this.drawerTitle="编辑";
          this.openDrawer= true;
          this.isCreate = false;
          this.updateId = data.id;
          this.fileList = [];
          this.$nextTick(function(){
            //console.log("子", data);
             //this.task_data=[];
            this.updateId = data.id;
            this.actionDrawerData = {
              content:data.content,
              tags: data.tags,
              mobile_ids: data.mobile_ids.split(","),
            },
            this.adjustPictures(data.images);
          })
        },
        openRelateClk(){
          this.$refs.relateAccount.openActionDrawer();
        },

      //提交信息
      submitButton(actionDrawerData){
        //创建操作
        if(this.isCreate){
          this.$refs[actionDrawerData].validate((valid) => {
            if (valid) {
              this.createFun();
            }
          })
        }else{  
          this.updateFun();  
        }
      },

      createFun(){
         let  fb = new FormData();
          this.fileList.forEach(el =>{
              fb.append("file", el.raw);
          })
          fb.append("content", this.actionDrawerData.content);
          fb.append("tags", this.actionDrawerData.tags);
          fb.append("mobile_ids", this.actionDrawerData.mobile_ids);
          let config = {
              headers: {
                  'Content-Type': 'multipart/form-data'
              },
              methods: 'post'
          }
          var that = this;
          this.$http.post(API.line.edit,fb,config).then(res=>{
              //console.log(res);
              if(res.data.code != 0) return that.$message.error(res.message);
              that.$message({message:"创建成功", type:'success'});
              that.fileList =[];
              that.openDrawer= false;
              //更新表格
              that.$parent.getTableData();
              //that.$refs[actionDrawerData].resetFields();
          })
      },
      updateFun(){
         let  fb = new FormData();
         var urlList=[];
          this.fileList.forEach(el =>{
            console.log(el);
            if(el.status =="ready"){
              fb.append("file", el.raw);
            }else{
              urlList.push (el.url);
            }
            //fb.append("file", el.raw);
          }) 
           fb.append("url", urlList);
          fb.append("voom_id", this.updateId);
          fb.append("content", this.actionDrawerData.content);
          fb.append("tags", this.actionDrawerData.tags);
          fb.append("mobile_ids", this.actionDrawerData.mobile_ids);
          let config = {
              headers: {
                  'Content-Type': 'multipart/form-data'
              },
              methods: 'put'
          }
          var that = this;
          this.$http.post(API.line.voom,fb,config).then(res=>{
              if(res.data.code != 0) return that.$message.error(res.message);
              that.$message({message:"更新成功", type:'success'});
              that.fileList =[];
              that.openDrawer= false;
              //更新表格
              that.$parent.getTableData();
              //that.$refs[actionDrawerData].resetFields();
          })
      },

      adjustPictures(file){
        let that = this;
        if(file){
          file.split(",").map(function(item){
            that.fileList.push({name:item,url:item});
          })
        }
      },
      handleRemove(file, fileList) {
          this.fileList.forEach((el, index) => {
              if(el.uid == file.uid){
                  this.fileList.splice(index,1);
                  return false;
              }
          });
      },
      handlePreview(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
      },
      handleExceed(file, fileList){
          if(file.length>9){
              this.$message.error("最多上传9张图片");
              return false;
          }
      },
      handleUpload(file,fileList){ 
          const isJPG = file.raw.type == 'image/jpeg' || file.raw.type == 'image/png' || file.raw.type == 'image/jpg'|| file.raw.type == 'image/gif';
          if(!isJPG){
              this.$message.error("只能上传图片");
              return false;
          }
          
          this.fileList.push(file);
          //console.log(this.fileList)
      },
      configTypeChange(item){
        this.ifPCLike = item =="11"?1:0;
      },

      handleClose(actionDrawerData) {
        this.$refs[actionDrawerData].resetFields();
      },
       cancelForm(actionDrawerData) {
        this.loading = false;
        this.openDrawer= false;
        this.$refs[actionDrawerData].resetFields();
      },
    },

  }
</script>
<style lang="scss">
  .el-form--label-top .el-form-item__label{
    padding: 0 !important;
  }
  .el-form-item{
    text-align: left;
  }
  .relateArea{
    min-height: 60px;
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        padding: 9px;
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relatelist{
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relative-drawer{
    .el-form-item{
      margin-bottom: 10px; 
    }
  }
  .divide{
    border-right: 1px solid #eee;
    
  }
</style>
