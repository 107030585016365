<template>
    <div>
        <el-container>
        <el-header>邮箱创建</el-header>
        <el-main>
          <el-form  :inline="true">
            <el-form-item>
              <el-input v-model="emailNumber" placeholder="邮箱"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" icon="el-icon-search">邮箱查询</el-button>
            </el-form-item>
          </el-form>
            <!-- 创建弹出层  用户自己输入邮箱账号 -->
            <!-- <div class="mb-10">
                <el-button type="primary"  @click="handleCreate"  icon="el-icon-circle-plus">创建</el-button>
                <span class="red-color">（创建失败，请稍后重试）</span>
            </div> -->
            <!--  随机创建邮箱账号 -->
            <div class="mb-10">
                <el-button type="primary"  @click="handleCreateRandom"  icon="el-icon-circle-plus">创建</el-button>
                <span class="red-color">（如提示登陆失效，请稍后重试）</span>
            </div>
            <el-table
            :data="mailList"
            class="table-info"
            highlight-current-row
            >
             <el-table-column
              label="id"
              prop="id">
            </el-table-column>
            <el-table-column
              prop="email"
              label="邮箱账号">
            </el-table-column>
            <el-table-column
              prop="password"
              label="密码">
            </el-table-column>
            <el-table-column
              prop="user_id"
              label="用户id">
            </el-table-column>
            <el-table-column
              prop="create_time"
              sortable
              label="创建时间">
            </el-table-column>
            <el-table-column
              label="操作">
              <template  slot-scope="scope">
                <el-link type="primary" href="https://mail.coivamall.com/" target="_blank">登录</el-link>
                <el-button class="ml-10" type="text" @click="extractBtn(scope.row)">提取fb邀请链接</el-button>
              </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
          <div class="text-center m-20">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              layout="total, prev, pager, next,sizes,jumper"
              :total="totalDataNum">
            </el-pagination>
          </div>
        </el-main>
        </el-container>
        <!-- 创建弹层 -->
        <el-dialog title="创建邮箱" :visible.sync="dialogCreate" width="30%">
            <div class="d-flex justify-content-start align-items-center">
                <span style="width:100px">邮箱账号：</span>
                <el-input v-model="createEmailName"></el-input>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelCreate">取 消</el-button>
                <el-button type="primary" @click="submitCreate">确 定</el-button>
            </div>
        </el-dialog>
        <!-- //fb邀请链接弹层 -->
        <el-dialog title="Fb邀请链接" :visible.sync="dialogFbLink">
          
          <el-row class="mb-20">
            <el-col :span="24"><div class="text-center">{{FbContent.email_content}}</div></el-col>
          </el-row>
          <div class="d-flex justify-content-center align-items-center mb-20 pb-10" v-show="FbContent.fb_link_href">
            <span>fb邀请链接:</span><span class="mx-20">{{FbContent.fb_link_href}}</span><el-button type="primary" size="mini" class="copyBtn" @click="copyLink">复制</el-button>
          </div>
          
        </el-dialog>
    </div>
</template>

<script>
import * as API from '@/api/common'
import Clipboard from 'clipboard'
export default{
    name:"mail-list",
    data(){
        return{
            mailList:[{
                id:'',
                email:'',
                password:'',
                user_id:'',
                create:''
            }],
            dialogCreate:false,
            dialogFbLink:false,
            createEmailName:"",
            currentPage:1,
            perPageCount:10,
            totalDataNum:0,
            emailNumber:"",
            FbContent:{'email_content':'', 'fb_link_href':''},
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        getData(){
            API.handleGet(`${API.mailList.list}?page=${this.currentPage}&size=${this.perPageCount}`).then(res =>{
            //console.log(res.data);
                if(res.code != 0) return this.$message(res.message);
                var datas = JSON.parse(JSON.stringify(res.data));
                this.mailList = datas;
                this.totalDataNum  = res.page.total;
            })
        },
        handleCreate(){
            this.dialogCreate = true;
            this.createEmailName = '';
        },
        cancelCreate(){
            this.dialogCreate = false;
            this.createEmailName = '';
        },
        handleCreateRandom(){
           API.handleGet(`${API.mailList.create}?type= 1`).then(res =>{
            //console.log(res.data);
                if(res.code != 0) return this.$message(res.message);
                this.$message(res.message);
                let htmlStr = `<div>邮箱：${res.data.email}</div><div>密码：${res.data.password}</div>`
                this.$alert(htmlStr, '创建账号成功', {
                  dangerouslyUseHTMLString: true,
                   center: true,
                   showConfirmButton: false,
                   type: "success" 
                }).catch((error)=>{console.log(error)});
                this.getData();
            })
        },
        submitCreate(){
            API.handleGet(`${API.mailList.create}?email= ${this.createEmailName}`).then(res =>{
            //console.log(res.data);
                if(res.code != 0) return this.$message(res.message);
                this.$message(res.message);
                this.getData();
                this.dialogCreate = false;
            })
        },
        handleSearch(){
          var param = Object.assign({'emailNumber':this.emailNumber}, {page:this.currentPage, size: this.perPageCount});
          API.handleGet(API.mailList.search, {'emailNumber':this.emailNumber}).then(res =>{
            if(res.code != 0) return this.$message("查询数据失败");
            this.mailList = res.data;
            this.totalDataNum  = res.page.total;
          })
        },
        extractBtn(row){
          API.handlePost(API.mailList.search, {'user_name':row.email.trim(), 'password': row.password.trim()}).then(res =>{
            if(res.code != 0) return this.$message("查询数据失败");
            if(Object.keys(res.data).length == 0){
              this.FbContent.email_content = "提取链接为空（没有收到fb邮件或者提取出现问题，请登陆邮箱查看）";
            }else{
              this.FbContent = res.data;
            }
            //this.FbContent = {'email_content': "啥时候的事", 'fb_link_href': "https：//wwwwwehowe"}
            this.dialogFbLink = true;
          })
        },
        copyLink(){
          let that = this;
          var clipboard = new Clipboard(".copyBtn",{
            text:function(){
              return that.FbContent.fb_link_href;
            }
          });
          clipboard.on('success',function(e){
            that.$message({message:"复制成功", type: 'success'});
            clipboard.destroy();
          });
          clipboard.on('error', function(e){
            that.$message.error("复制失败");
            clipboard.destroy();
          })
        },
         //分页
        handleSizeChange(val) {
            this.perPageCount = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
            //console.log(`当前页: ${val}`);
        },

    },
}
</script>
<style>

</style>
<style lang="scss" scoped>
.el-header{
    background-color: rgb(249 249 249);
    line-height: 60px;
    text-align: left;
    border: 1px solid #eee;
  }
  .table-info{
    border:1px solid #eee;
  }
</style>
