<template>
  <div>
    <el-container>
      <el-header>任务配置</el-header>
      <el-main>
        <div>
          <el-form :inline="true" :model="advancedSearchData" ref="advancedSearchData" class="demo-form-inline">
            <el-form-item prop="name">
              <el-input v-model="advancedSearchData.name" placeholder="任务配置名称，可模糊"></el-input>
            </el-form-item>
            <el-form-item prop="target">
              <el-input v-model="advancedSearchData.target" placeholder="预览链接"></el-input>
            </el-form-item>
            <el-form-item prop="pageName">
              <el-input v-model="advancedSearchData.pageName" placeholder="主页名称"></el-input>
            </el-form-item>
            <el-form-item prop="type">
              <el-select v-model="advancedSearchData.type" placeholder="任务配置类型">
                <el-option v-for="item in task_types" :key="item.key" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="status">
              <el-select v-model="advancedSearchData.status" placeholder="任务配置状态">
                <el-option v-for="item in task_config_status" :key="item.key" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" icon="el-icon-search">查询</el-button>
              <el-button @click="handleReset('advancedSearchData')"  icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <span @click="advancedSearch"><i class="el-icon-brush"></i><el-button type="text">高级筛选</el-button></span>
          </el-form>
        </div>
        <!-- buttons -->
        <div class="d-flex justify-content-between">
          
          <div class="mb-10">
            <el-button type="primary"  @click="handleCreate"  icon="el-icon-circle-plus">创建任务配置</el-button>
            <el-button icon="el-icon-delete" @click="handleBatchDelete">批量删除</el-button>
          </div>
            <div class="mb-10">
            <el-button  type="text" @click="getTableData"  icon="el-icon-refresh"></el-button>
          </div>
        </div>
        <!-- 表格 -->
        <div>
          <el-table
            :data="timingTaskData"
            class="table-info"
            highlight-current-row
            @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="任务配置ID"
              prop="id">
            </el-table-column>
            <el-table-column
              label="任务配置名称"
              prop="name"
              >
            </el-table-column>
            <el-table-column
              label="任务配置类型">
              <template slot-scope="scope">
                 <span class="taskType">{{scope.row.type_str}}</span>
                  <!-- <p  v-for="item in task_types" :key="item.key">
                    <span v-if="scope.row.type==item.key"  class="taskType">{{item.value}}</span>
                  </p> -->
              </template>
            </el-table-column>
            <el-table-column
              label="预览链接"
              prop="target"
              >
              <template slot-scope="scope">
                <a class="a-link"  target="_blank" :href="scope.row.target">{{scope.row.target}}</a>
              </template>
            </el-table-column>
            <el-table-column
              label="家族"
              prop="org_name"
              >
              </el-table-column>
            <el-table-column
              label="优化师"
              prop="optimizer_name"
              >
              </el-table-column>
            <el-table-column
              label="主页ID"
              prop="page_id"
              >
              <template slot-scope="scope">
                <a class="a-link"  target="_blank" :href="'https://www.facebook.com/'+scope.row.page_id">{{scope.row.page_id}}</a>
              </template>
            </el-table-column>
            <el-table-column
              label="商品ID"
              prop="sale_id"
              >
            </el-table-column>
            <el-table-column
              label="主页名称"
              prop="page_name"
              >
              <template slot-scope="scope">
                <a class="a-link"  target="_blank" :href="'https://www.facebook.com/'+scope.row.page_id">{{scope.row.page_name}}</a>
              </template>
            </el-table-column>
            <el-table-column
              label="历史销量"
              prop="history_sales"
              >
            </el-table-column>
            <el-table-column
              label="每日任务数"
              prop="account_num"
              >
              </el-table-column>
            <!-- <el-table-column
              prop="cron"
              label="周期表达式">
            </el-table-column> -->
            <el-table-column
              label="任务配置状态"
              prop="status_str"
              >
            </el-table-column>
            <el-table-column
              prop="create_time"
              sortable
              label="创建时间">
            </el-table-column>
            <el-table-column
              prop="update_time"
              sortable
              label="最后更新时间">
            </el-table-column>
            <el-table-column
              label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="handleEdit(scope.row)">编辑</el-button>
                <el-button
                  size="mini"
                  type="text"
                  :disabled="scope.row.status==-1?true:false"
                  @click="handleDelete(scope.row)">删除</el-button>
                  <!-- <el-button
                  size="mini"
                  type="text"
                  @click="openTask">开启</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="text-center m-20">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              layout="total, prev, pager, next,sizes,jumper"
              :total="totalDataNum">
            </el-pagination>
          </div>
        </div>  
      </el-main>
    </el-container>
    <!-- 创建 动作 -->
    <create-action ref="createAction" :task-types = "task_types" :languages ="languages" :countries="countries" :task_data_options="task_data_options" :task_config_status="task_config_status"></create-action>
    <!-- 高级筛选 -->
    <el-drawer
      size="600px"
      title="高级筛选"
      :visible.sync="openSearchDrawer"
      :wrapperClosable="false"
      @close="handleAdvanceCancel('advancedSearchData')"
      direction="rtl"
      class="advancedFilter"
      >
      <div class="px-20">
        <el-form label-position="top" :inline="true" :model="advancedSearchData" ref="advancedSearchData" class="demo-form-inline">
          <el-form-item label="任务配置名称" prop="name">
            <el-input v-model="advancedSearchData.name" placeholder="请输入任务配置名称"></el-input>
          </el-form-item>
          <el-form-item label="任务类型" prop="type">
              <el-select v-model="advancedSearchData.type" placeholder="请选择任务配置类型">
                <el-option v-for="item in task_types" :key="item.key" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="任务配置状态" prop="status">
              <el-select v-model="advancedSearchData.status" placeholder="请选择状态">
                <!-- <el-option label="--请选择任务状态--"></el-option> -->
                <el-option v-for="item in task_config_status" :key="item.key" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="地区" prop="account_county">
              <el-select v-model="advancedSearchData.account_county" placeholder="请选择地区">
                  <el-option v-for="item in countries" :key="item.key" :label="item.value" :value="item.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
              label="语言"
              prop="account_language">
                <el-select v-model="advancedSearchData.account_language" placeholder="请选择语言"  style="width: 100%;">
                  <el-option v-for="item in languages" :key="item.value" :label="item.value" :value="item.key"></el-option>
                </el-select>
              </el-form-item>
            <div class="d-flex align-items-end">
              <el-form-item label="创建时间" prop="ctimeStart">
                <el-date-picker type="date" placeholder="开始时间" v-model="advancedSearchData.ctimeStart" value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <el-form-item  label="" prop="ctimeEnd">
                <el-date-picker type="date" placeholder="结束时间" v-model="advancedSearchData.ctimeEnd" value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </div>
            <div class="d-flex align-items-end">
              <el-form-item label="最后更新时间" prop="utimeStart">
                <el-date-picker type="date" placeholder="开始时间" v-model="advancedSearchData.utimeStart" value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
              <el-form-item prop="utimeEnd">
                <el-date-picker type="date" placeholder="结束时间" v-model="advancedSearchData.utimeEnd" value-format="yyyy-MM-dd"></el-date-picker>
              </el-form-item>
            </div>
            
            <el-form-item>
              <el-button @click="handleAdvanceReset('advancedSearchData')"  icon="el-icon-refresh-right">重置</el-button>
              <el-button type="primary" icon="el-icon-search" @click="handleAdvancedSearch">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
    </el-drawer>
  </div>
</template>
<script>
import CreateAction from '@/components/configration/CreateAction'
import * as API from '@/api/common'
  export default {
    name: 'configration',
    data() {
      return {
        activeName: 'first',
        actionName:"",
        openBindRecordDrawer: false, //打开绑定记录的弹层
        openSearchDrawer: false, //打开高级搜索弹层
        currentPage:1,
        perPageCount:10,
        totalDataNum:0,
        countries:[],
        task_types:[],
        languages:[],
        task_config_status:[],
        task_data_options:[],
        timingTaskData:[
          {
            taskIDs:"",
            name:"",
            type:"",
            cron:"",
            status_str:"",
            create_time:"",
            update_time:"",
            status:""
          }
        ], 
        multipleSelection: [],
        orderIdLists:[],
        // advancedSearchData: {
        //   name: '',
        //   type: '',
        //   status: '',
        //   mobileID:'',
        //   pcID:''
        // },
        bindRecord:{
          name:"",
          starttime:"",
          endtime:""
        },
         advancedSearchData: this.emptyData(),
      };
    },
    components:{
      CreateAction,
      // UpdateAction,
    },
    created(){
      this.getTableData();
      // configs.getConfigData();
      this.getConfigData();
    },
    methods: {
      openUpdateDrawer(){
        this.$refs.updateAction.openActionDrawer();
      },
      handleCreate(){
        this.$refs.createAction.createActionDrawer();
      },
      handleEdit(row){
        this.$refs.createAction.updateActionDrawer(row); 
      },
      async getTableData(){
        var param= Object.assign(this.advancedSearchData,{page:this.currentPage, size: this.perPageCount})
         API.handleGet(API.configration.create, {'params':param}).then(res =>{
           if(res.code != 0) return this.$message(res.message);
            this.timingTaskData = res.data;
            this.totalDataNum  = res.page.total;  
         })  
      },
      
      //获取配置内容
      async getConfigData(){  
        API.handleGet(API.configs.getConfig).then(res =>{
          //console.log(res.data);
          if(res.code != 0) return this.$message(res.message);
          var datas = JSON.parse(JSON.stringify(res.data));

          let {keys, values, entries} = Object;
          for(let [key, value] of entries(datas.countries)){
            this.countries.push({'key':key, 'value': value});
          }
          for(let [key, value] of entries(datas.languages)){
            this.languages.push({'key':key, 'value': value});
          }
          for(let [key, value] of entries(datas.task_types)){
            this.task_types.push({'key':key, 'value': value});
          }
          for(let [key, value] of entries(datas.task_data_options)){
            this.task_data_options.push({'key':key, 'value': value});
          }
          for(let [key, value] of entries(datas.task_config_status)){
            this.task_config_status.push({'key':key, 'value': value});
          }
        })         
      },

      //删除订单
      async handleDelete(row){
        this.$confirm('确认删除？')
          .then(async ()=>{
            //var res = await this.$http.delete(`gka/api/task/${row.id}`);
            API.handleDelete(`${API.configration.delete}/${row.id}`).then(res =>{
              if(res.code != 0) return this.$message.error(res.message);
              this.$message.success("删除成功");
              this.getTableData();
            })
            
          })
          .catch(()=>{
            this.$message.info("已取消删除"); 
          })
        
      },
      //批量删除订单
      async handleBatchDelete(){
        this.$confirm('确认删除？')
          .then(async ()=>{
            var ids=[];
            if(this.multipleSelection && this.multipleSelection.length){
              this.multipleSelection.forEach(item =>{
                ids.push(item.id);
              })
            }
            if(ids.length>0){
              var params= JSON.stringify(ids);
              //console.log(params)
              //var res = await this.$http.delete(`gka/api/tasks?ids=${params}`);
              API.handleDelete(`${API.configration.batchDelete}?ids=${params}`).then(res =>{
                if(res.code != 0) return this.$message.error(res.message);
                this.$message.success("删除成功");
                this.getTableData();
              })
              
            }else{
              this.$message.error("请选择删除的对象");
            }
          })
          .catch(()=>{
            this.$message.info("已取消删除"); 
          })
      },
      //查询操作
      async handleSearch(){
        this.currentPage=1;
        var param = Object.assign(this.advancedSearchData, {page:this.currentPage, size: this.perPageCount});
        //var newParam = JSON.stringify(param);
        //console.log(param)
        //var res = await this.$http.get(`gka/api/tasks?params=${newParam}`);
        API.handleGet(API.configration.create, {'params':param}).then(res =>{
          if(res.code != 0) return this.$message(res.message);
          this.timingTaskData = res.data;
          this.totalDataNum  = res.page.total;
        }) 
      },
      //高级查询
      async handleAdvancedSearch(){
        this.currentPage=1;
        this.advancedSearchData.ctimeStart = this.dateAddTime(this.advancedSearchData.ctimeStart,0);
        this.advancedSearchData.ctimeEnd = this.dateAddTime(this.advancedSearchData.ctimeEnd,1);
        this.advancedSearchData.utimeStart = this.dateAddTime(this.advancedSearchData.utimeStart,0)
        this.advancedSearchData.utimeEnd = this.dateAddTime(this.advancedSearchData.utimeEnd,1)
        var param = Object.assign(this.advancedSearchData, {page:this.currentPage, size: this.perPageCount});
        //var newParam = JSON.stringify(param);
        //var res = await this.$http.get(`gka/api/tasks?params=${newParam}`);
        API.handleGet(API.configration.create,  {'params':param}).then(res =>{
          if(res.code != 0) return this.$message(res.message);
          this.timingTaskData = res.data;
          this.totalDataNum  = res.page.total;
          this.openSearchDrawer = false;
        })
      },
      //高级查询取消
      handleAdvanceCancel(advancedSearchData){
        // this.$refs[advancedSearchData].resetFields();
        this.openSearchDrawer= false;
      },
      //高级查询重置
      handleAdvanceReset(advancedSearchData){
        //this.$refs[advancedSearchData].resetFields();
        this.advancedSearchData = this.emptyData();
      },
      //查询重置
      handleReset(advancedSearchData){
        //this.$refs[advancedSearchData].resetFields();
        this.advancedSearchData = this.emptyData();
      },

      sendMessage(){
        console.log("sendMessage");
      },
      cancelSendMessage(){
        this.orderIdLists = [];
        this.openMessageDrawer = false;
      },

      //table表格复选框选择
       handleSelectionChange(val) {
         //console.log(val)
        this.multipleSelection = val;
      },
      bindRecordSearch(){
        console.log("查询")
      },
      advancedSearch(){
        this.openSearchDrawer= true;
      },

      //给日期添加上时分秒
      dateAddTime(a,index){
        if(index == 0 && a && a.indexOf("00:00:00")==-1){
          a =a+' 00:00:00';
        }
        if(index == 1 && a && a.indexOf("23:59:59")==-1){
          a =a+' 23:59:59';
        }
        return a;
      },
       //分页
      handleSizeChange(val) {
        this.perPageCount = val;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getTableData();
        //console.log(`当前页: ${val}`);
      },
      emptyData(){
        return {
            name:"",
            type:"",
            status:"",
            account_county:"",
            account_language:"",
            pageName:"",
            ctimeStart:"",
            ctimeEnd:"",
            utimeStart:"",
            utimeEnd:""
          }
      },
    }
  };
</script>
<style>
    .el-table__header{
     background: #f1f2f3;
    }
</style>
<style lang="scss" scoped>
  .el-header{
    background-color: rgb(249 249 249);
    line-height: 60px;
    text-align: left;
    border: 1px solid #eee;
  }
  .table-info{
    border:1px solid #eee;
    p{
      margin: 0;
    }
  }
.a-link{color: #409eff;text-decoration: none;}
.bindRecord .el-input, .bindRecord  .el-date-editor.el-input {width: 180px;}
.bindRecord table p{margin: 0;}
.bindRecord .el-table{border:1px solid #eee}
.advancedFilter{
  .el-form--inline .el-form-item{
    width: 46%;
    margin-right: 20px;
    .el-select, .el-date-editor.el-input{width: 100%;}
  }
}
.taskType{
  display: inline-block;
  padding: 3px 10px;
  border-radius: 3px;
  background: #f0f0f0;
}
</style>