import axios from 'axios';
import Vue from 'vue'
import { Message } from 'element-ui';
const LOGIN_URL = "http://gsso.giikin.com/admin/login/index.html?_system=18";
// const baseUrl = process.env.VUE_APP_API_BASE_URL;
const baseUrl = 'https://gassapi.giikin.cn/';
import Cookies from "js-cookie";

const _ticker = Cookies.get('_ticker')
let userToken = Cookies.get("userToken");
if (userToken) userToken = JSON.parse(userToken);
const service = axios.create({
    baseURL: baseUrl, //
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 0,
    headers: {
        "content-type": "application/json"
    }
});

// 请求拦截器
service.interceptors.request.use(config => {
    if (!userToken) {
        const _token = getQuery("_token");
        const _user = getQuery("_user");

        if (_token) {
            Cookies.set("userToken", JSON.stringify({ _token, _user }), { expires: 1 });
        } else {
            const _url = window.location;
            const href = LOGIN_URL + "&_url=" + _url;
            window.location.href = href;
        }
        userToken = { _token, _user };
    }

    if (!config.params) {
        config.params = {};
    }

    config.params = Object.assign({}, config.params, {
        _token: userToken._token, _user: userToken._user, _ticker: _ticker
    });

    // Vue.prototype.$showLoading();
    return config;
},
    error => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
);
// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    const res = response.data;

    if (res.code == 401) {
        // TODO
        Cookies.remove("userToken");
        Cookies.remove("_ticker");
        const _url = window.location;
        const href = LOGIN_URL + "&_url=" + _url;

        const _ticker = getQuery("_ticker", res.location);
        Cookies.set('_ticker', _ticker)

        window.location.href = href;
        // console.log(res);

        // console.log(window.location);
        return

    } else if (res.code != 0 && res.code != 401) {
        Vue.prototype.$hideLoading();
        Message({
            message: res.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(res)
    }

    return response;
}, function (err) {
    Vue.prototype.$hideLoading();
    if (err.toString().includes("401") && !err.message.includes("timeout")) {

        Cookies.remove("userToken");
        const _url = window.location;
        const href = LOGIN_URL + "&_url=" + encodeURIComponent(_url);
        window.location.href = href;
    } else {
        // 对响应错误做点什么
        Message({
            message: err.message.includes("timeout") ? "请求超时" : err.message,
            type: 'error',
            duration: 5 * 1000
        })
    }
});

function getQuery(key, href) {
    let search = href ? href.split("?")[1] : window.location.href.substring(window.location.href.indexOf("?")).substr(1);
    if (!search) return null;
    let list = search.split('&');
    let obj = {};
    list.forEach(v => {
        let query = v.split('=');
        obj[query[0]] = query[1];
    })
    if (!key) return obj;
    return obj[key] || null;
}

export const GET = (url, params, config, loading) => {
    if (!loading) {
        Vue.prototype.$showLoading();
    }
    return service({
        url,
        method: 'GET',
        params,
        ...config
    }
    ).then(res => {
        Vue.prototype.$hideLoading();
        return res?.data;
    });
}
export const POST = (url, data, config, loading) => {
    if (!loading) {
        Vue.prototype.$showLoading();
    }
    return service({
        url,
        method: 'POST',
        data: JSON.stringify(data),
        ...config
    }).then(res => {
        Vue.prototype.$hideLoading();
        return res?.data;
    });
}
export const Import = (url, data, config, loading) => {
    if (!loading) {
        Vue.prototype.$showLoading();
    }

    return service({
        url,
        method: 'POST',
        data: data,
        ...config
    }).then(res => {
        Vue.prototype.$hideLoading();
        return res?.data;
    });
}
export const PUT = (url, data, config, loading) => {
    if (!loading) {
        Vue.prototype.$showLoading();
    }
    return service({
        url,
        method: 'PUT',
        data: JSON.stringify(data),
        ...config
    }).then(res => {
        Vue.prototype.$hideLoading();
        return res?.data;
    });
}
export const DELETE = (url, data, config, loading) => {
    if (!loading) {
        Vue.prototype.$showLoading();
    }
    return service({
        url,
        method: 'DELETE',
        data: JSON.stringify(data),
        ...config
    }).then(res => {
        Vue.prototype.$hideLoading();
        return res?.data;
    });
}