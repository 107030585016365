<template>
    <div>
      <div class="action-con ">
        <el-drawer
            :title="drawerTitle"
            size="580px"
            :wrapperClosable="false"
            @close="handleClose('actionDrawerData')"
            :visible.sync="openDrawer"
            direction="rtl">
            <section class="px-20">
              <div class="d-flex justify-content-between px-20 font-15">
                <label class="font-weight-bold">手机终端</label>
                <span class="gray-color-9 font-14"><em class="red-color">*</em>必填项</span>
              </div>
               <el-form label-position="top" :model="actionDrawerData" :rules="rules" ref="actionDrawerData" class="p-20">
                <el-form-item 
                  label=" 设备名称"
                  prop="name"
                  >
                  <el-input v-model="actionDrawerData.name" placeholder="设备名称"></el-input>
                </el-form-item>
                <el-form-item 
                  label=" 设备语言"
                  prop="language">
                  <el-select v-model="actionDrawerData.language" placeholder="请选择设备语言" style="width: 100%;">
                    <el-option v-for="item in languages" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item 
                  prop="country_search_text"
                  label=" 设备fb搜索地区关键词">
                  <el-input v-model="actionDrawerData.country_search_text" placeholder="设备fb搜索地区关键词"></el-input>
                </el-form-item>
                <el-form-item 
                  prop="phone_country"
                  label=" 设备接码所用地区">
                  <el-select v-model="actionDrawerData.phone_country" placeholder="设备接码所用地区" style="width: 100%;">
                    <el-option v-for="item in  countries" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item 
                  prop="proxy_type"
                  label="代理类型">
                  <el-select v-model="actionDrawerData.proxy_type" placeholder="代理类型" style="width: 100%;">
                    <el-option v-for="item in  proxy_types" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item 
                  prop="proxy_ip"
                  label="代理ip">
                  <el-input v-model="actionDrawerData.proxy_ip" placeholder="请输入代理ip"></el-input>
                </el-form-item>
                <el-form-item 
                  prop="proxy_port"
                  label="代理端口">
                  <el-input v-model="actionDrawerData.proxy_port" placeholder="代理端口"></el-input>
                </el-form-item>
                <el-form-item 
                  prop="proxy_location"
                  label="代理所属地区（区域）">
                  <el-select v-model="actionDrawerData.proxy_location" placeholder="代理所属地区（区域）" style="width: 100%;">
                    <el-option v-for="item in  proxy_locations" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item 
                  prop="status"
                  label="状态名称">
                  <el-select v-model="actionDrawerData.status" placeholder="状态名称" style="width: 100%;">
                    <el-option v-for="item in device_status" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item 
                  prop="is_task_acceptable"
                  label="是否可接收任务">
                  <el-select v-model="actionDrawerData.is_task_acceptable" placeholder="是否可接收任务" style="width: 100%;">
                    <el-option v-for="item in ifAccepteTask" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                </el-form-item>
                <!-- <div class="d-flex justify-content-between my-20 font-15">
                  <label class="font-weight-bold">归属PC终端 </label>
                  <span class="gray-color-9 font-14"><em class="red-color">*</em>必填项</span>
                </div> -->
                <!-- <div class="d-flex justify-content-between my-20 font-15">
                  <label class="font-weight-bold">关联个号<span class="gray-color-9 font-14 ">（非必关联）</span></label>
                  <span class="blue-color pointer" @click="openRelativeClk"><i class="el-icon-edit-outline"></i> 关联</span>
                </div>
                <div class="d-flex justify-content-between mb-10">
                  <span class="gray-color-9 font-14">共<b class="font-16">2</b>条</span>
                  <span class="blue-color pointer">清空</span>
                </div>
                <div class="border relateArea">
                  <div class="d-flex justify-content-between p-10">
                    <span>15339903993</span>
                    <span>个号：2990 <b class="blue-color"><i class="el-icon-close font-weight-bold hide" /></b></span>
                  </div>
                  <div class="d-flex justify-content-between p-10">
                    <span>15339903993</span>
                    <span>个号：2990 <b class="blue-color"><i class="el-icon-close font-weight-bold hide" /></b></span>
                  </div>
                </div> -->
                <div class="demo-drawer__footer">
                  <el-button @click="cancelForm('actionDrawerData')">取 消</el-button>
                  <el-button type="primary" @click="submitButton('actionDrawerData')" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
                </div>
              </el-form>
            </section>
        </el-drawer>
      </div>
      <!-- 关联个号 -->
      <!-- <relate-account></relate-account> -->
    </div>
</template>
<script>
// import RelateAccount from './RelateAccount.vue';
import * as API from '@/api/common'
  export default {
  components: { 
    // RelateAccount 
    },
    name: 'mobile-item-action',
    props:{
    },
    data() {
        return {
          actionDrawer: false, 
          //openRelativeDrawer:false, //关联个号弹层
          openDrawer: false,
          isCreate:false,  //判断打开弹出是否是创建操作
          loading: false,
          //isIndeterminate: true, //用于checkbox全选
          drawerTitle:"",
          device_status:[],
          languages:[],
          countries:[],
          proxy_locations:[],
          proxy_types:[],
          ifAccepteTask:[{'key':0, 'value': '否'},{'key':1, 'value': '是'}],
          // checkAll:false,
          // personalId:[],
          // personalIdAll:[{con:15339903993, id:1302, index:0},{con:15339903993, id:1302, index:1},{con:15339903993, id:1302, index:2}],
          actionDrawerData: {
            name: '',
            language: '',
            country_search_text:'',
            phone_country:'',
            proxy_ip:'',
            proxy_type:'',
            proxy_port:'',
            proxy_location:'',
            status:'',
            is_task_acceptable:'',
          },
          createId:"",
          rules: {
            name:[{required: true, message: '请填写设备名称', trigger: 'blur'}],
          }
        }
      },
      created(){
        this.getConfigData();
      },
      mounted(){
        // this.actionDrawer = this.openDrawer;
      },
      watch:{
      
      },
      methods: {
        openActionDrawer(){
          this.drawerTitle="创建";
          this.openDrawer= true;
          this.isCreate = true;
        },
        updateActionDrawer(data){
          this.drawerTitle="编辑";
          this.openDrawer= true;
          this.$nextTick(function(){
            this.createId = data.id;
            this.actionDrawerData = {name:data.name, language: data.language, country_search_text: data.country_search_text, phone_country: data.phone_country, 
            proxy_ip: data.proxy_ip, proxy_type: data.proxy_type, proxy_port:data.proxy_port, proxy_location:data.proxy_location, status: data.status+'',is_task_acceptable:data.is_task_acceptable }
          })
        },
        // openRelativeClk(){
        //   this.openRelativeDrawer= true;
        // },
        submitButton(actionDrawerData){
          var that  = this;
          //创建操作
          if(this.isCreate){
            that.$refs[actionDrawerData].validate((valid) => {
              if (valid) {
                //that.$http.post(`gka/api/mobile`,that.actionDrawerData).then(res =>{
                API.handlePost(API.devices.deleteMobile, that.actionDrawerData).then(res =>{
                  if(res.code != 0) return that.$message({message:res.message, type:'error'});
                  that.$message({message:"创建成功", type:'success'});
                  this.openDrawer= false;
                  //更新表格
                  that.$parent.getMobileTableData();
                  that.$refs[actionDrawerData].resetFields();
                })
              }
            })
          }else{
            var id = this.actionDrawerData.id;
            delete this.actionDrawerData.status_str;
            delete this.actionDrawerData.accounts_count;
            //that.$http.put(`gka/api/mobile/${this.createId}`,that.actionDrawerData).then(res =>{
            API.handlePut(`${API.devices.deleteMobile}/${this.createId}`, that.actionDrawerData).then(res =>{
              if(res.code != 0) return that.$message({message:"更新失败", type:'error'});
              that.$message({message:"更新成功", type:'success'});
               //更新表格
              that.$parent.getMobileTableData();
              that.$refs[actionDrawerData].resetFields();
              this.openDrawer= false;
            })
          }
        },
        //获取配置内容
      async getConfigData(){
         //var res = await this.$http.get(`gka/api/configs`);
          // console.log(res.data);
        API.handleGet(API.configs.getConfig).then(res =>{
          //console.log(res)
          if(res.code != 0) return this.$message("请求数据失败");
          var datas = JSON.parse(JSON.stringify(res.data));

          let {keys, values, entries} = Object;
          for(let [key, value] of entries(datas.device_status)){
            this.device_status.push({'key':key, 'value': value});
          }
          for(let [key, value] of entries(datas.languages)){
            this.languages.push({'key':key, 'value': value});
          } 
          for(let [key, value] of entries(datas.countries)){
            this.countries.push({'key':key, 'value': value});
          } 
          for(let [key, value] of entries(datas.proxy_locations)){
            this.proxy_locations.push({'key':key, 'value': value});
          } 
          for(let [key, value] of entries(datas.proxy_types)){
            this.proxy_types.push({'key':key, 'value': value});
          } 
        })
          
      },
        cancelForm(actionDrawerData) {
          this.loading = false;
          this.openDrawer= false;
          this.$refs[actionDrawerData].resetFields();
        },
        // handleCheckAllChange(val) {
        //   this.personalId = val ? this.personalIdAll : [];
        //   this.isIndeterminate = false;
        // },
        // handleCheckedIdChange(value) {
        //   let checkedCount = value.length;
        //   this.checkAll = checkedCount === this.personalIdAll.length;
        //   this.isIndeterminate = checkedCount > 0 && checkedCount < this.personalIdAll.length;
        // },
        handleClose(actionDrawerData){
        this.$refs[actionDrawerData].resetFields();
      },
      }
  }
</script>
<style lang="scss">
  .el-form--label-top .el-form-item__label{
    padding: 0 !important;
  }
  .el-form-item{
    text-align: left;
  }
  .relateArea{
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relatelist{
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relative-drawer{
    .el-form-item{
      margin-bottom: 10px; 
    }
  }
  .divide{
    border-right: 1px solid #eee;
    
  }
</style>
