<template>
    <div>
      <div class="action-con ">
        <el-drawer
            :title="drawerTitle"
            size="580px"
            :visible.sync="openDrawer"
            :wrapperClosable="false"
             @close="handleClose('actionDrawerData')"
            direction="rtl">
            <section class="px-20">
              <div class="d-flex justify-content-between px-20 font-15">
                <label class="font-weight-bold">任务配置</label>
                <span class="gray-color-9 font-14"><em class="red-color">*</em>必填项</span>
              </div>
               <el-form label-position="top" :model="actionDrawerData" :rules="rules" ref="actionDrawerData" class="p-20">
                 <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="任务名称"
                    prop="name"> 
                      <el-input v-model="actionDrawerData.name" placeholder="请填写任务名称"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item 
                      label=" 任务类型"
                      prop="type">
                      <el-select v-model="actionDrawerData.type" placeholder="请选择任务类型" @change="changeTaskType" style="width: 100%;">
                        <el-option v-for="item in taskTypes" :key="item.key" :label="item.value" :value="item.key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item 
                  label=" 任务周期"
                  >
                  <el-input v-model="actionDrawerData.cron" placeholder="请填写任务周期表达式，如：0 0 12 * * ?"></el-input>
                </el-form-item>
                <!-- 目标账号 -->
                <div class="my-20 font-15">
                  <label class="font-weight-bold">目标账号<span class="gray-color-9 font-14 ">（注：开启任务时，必须添加）</span></label>
                </div> 
                <!-- <div class="d-flex justify-content-between my-20 font-15">
                  <label class="font-weight-bold">目标账号<span class="gray-color-9 font-14 font-weight-normal">（注：开启任务时，必须添加）</span></label>
                  <span class="blue-color pointer" @click="openRelateClk"><i class="el-icon-edit-outline"></i> 关联</span>
                </div> -->
                <div class="mb-20" :class="isCreate?'': 'hide'">
                  <el-select v-model="accountSearch.country" placeholder="请选择地区" size="mini" style="width:130px" class="mr-10">
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="item in countries" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                  <el-select v-model="accountSearch.language" placeholder="请选择语言" size="mini" style="width:130px"  class="mr-10">
                    <el-option label="请选择" value=""></el-option>
                    <el-option v-for="item in languages" :key="item.value" :label="item.value" :value="item.key"></el-option>
                  </el-select>
                  <el-input v-model="accountSearch.number" placeholder="请填写数量" size="mini" style="width:130px"  class="mr-10"></el-input>
                  <el-button type="primary" size="mini" @click="handleAccountsSearch">搜索</el-button>
                </div>
                
                <div class="d-flex justify-content-between mb-10"  v-if="accountLists.length" >
                  <span class="gray-color-9 font-14">共<b class="font-16 gray-color-3 font-weight-normal">{{accountLists.length}}</b>条</span>
                  <span class="blue-color pointer font-14" @click="clearAccountList">清空</span>
                </div>
                <div class="border relateArea">
                  <div v-for="item in accountLists" :key="item.id" class="d-flex justify-content-between align-items-baseline p-10">
                    <span style="width:25%;">{{item.id}}</span>
                    <span style="width:25%;">{{item.last_name}}{{item.first_name}}</span>
                    <span style="width:25%;">{{item.country}}</span>
                    <!-- <span></span> -->
                    <span class="d-flex justify-content-between" style="width:25%;"><em class="font-normal">{{item.language}} </em><b class="blue-color" @click="deleteAccount(item.id)"><i class="el-icon-close font-weight-bold hide" /></b></span>
                  </div>
                  <!-- <div class="d-flex justify-content-between p-10">
                    <span>15339903993</span>
                    <span>个号：2990 <b class="blue-color"><i class="el-icon-close font-weight-bold hide" /></b></span>
                  </div> -->
                </div>
               
                <!-- 任务参数 -->
                <div class="d-flex justify-content-between my-20 font-15">
                  <label class="font-weight-bold">任务参数<span class="gray-color-9 font-14 font-weight-normal">（非必填）</span></label>
                   <span class="blue-color pointer" @click="addKeysClk"><i class="el-icon-edit-outline"></i> 添加</span>
                </div>
                <el-form-item>
                  <template>
                     <el-form-item
                          v-for="(task, index) in task_data"
                            :key="index"
                          >
                          <el-row :gutter="20">
                            <el-col :span="10" class="mb-10">
                              <el-select v-model="task.code" placeholder="请选择参数名">
                              <el-option v-for="item in task_data_options" :key="item.value" :label="item.value" :value="item.key"></el-option>
                            </el-select>
                            </el-col>
                            <el-col :span="12" class="mb-10">
                              <el-input v-model="task.val" placeholder="请填写参数值"></el-input>
                            </el-col>
                            <el-col :span="2">
                            <i class="el-icon-delete font-16 blue-color" @click="removeKeysClk(task)"></i>
                            </el-col>
                          </el-row>
                            
                          </el-form-item>
                  </template>
                </el-form-item>

                <div class="demo-drawer__footer">
                  <el-button @click="cancelForm('actionDrawerData')">取 消</el-button>
                  <el-button type="primary" @click="submitButton('actionDrawerData')" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
                </div>
              </el-form>
            </section>
        </el-drawer>
      </div>
      <!-- 关联个号 -->
      <relate-account ref="relateAccount"></relate-account>
    </div>
</template>
<script>
import RelateAccount from '../terminal/RelateAccount.vue'
import { filterParams } from '@/assets/js/common.js'
import * as API from '@/api/common'
  export default {
    name: 'create-action',
    props:{
      taskTypes:{type:Array},
      languages:{type:Array},
      countries:{type: Array},
      task_data_options:{type: Array}
    },
      
    data() {
        return {
          drawerTitle:"创建",
          actionDrawer: false, 
          openRelativeDrawer:false, //关联个号弹层
          openDrawer: false,
          loading: false,
          isCreate:false, //判断打开弹出是否是创建操作
          updateId:"",
          taskTypeValue:-2,
          rules:{
            name:[{required: true, message: '请填写任务名称', trigger: 'blur'}],
            type:[{required: true, message: '请选择任务类型', trigger: 'blur'}]
          },
          actionDrawerData: {
           name:"",
           type:"",
           cron:"",
          },
          task_data:[{'code':"", "val":""}],
          accountSearch:{
            country:"",
            language:"",
            number:""
          },
          accountLists:[],
        }
      },
      components:{
        RelateAccount
      },
      
      mounted(){
        // this.actionDrawer = this.openDrawer;
      },
      watch:{
      
      },
      methods: {
        createActionDrawer(){
          this.drawerTitle="创建";
          this.openDrawer= true;
          this.isCreate = true;
          this.task_data=[{'code':"", "val":""}];
        },
        updateActionDrawer(data){
          this.drawerTitle="编辑";
          this.openDrawer= true;
          this.isCreate = false;
          this.updateId = data.id;
          this.$nextTick(function(){
            //console.log("data", data);
            this.updateId = data.id;
            this.taskTypeValue = data.type;
            this.actionDrawerData = {name:data.name,type: data.type+"",cron: data.cron, account_id: data.account_id};
            this.task_data = data.task_data;
            this.accountLists.push(data.account);
          })
        },
        openRelateClk(){
          this.$refs.relateAccount.openActionDrawer();
        },

      //提交信息
      submitButton(actionDrawerData){
        var that  = this;
        //创建操作
        if(this.isCreate){
          that.$refs[actionDrawerData].validate((valid) => {
            if (valid) {
              var param = {};
              var a = filterParams(this.actionDrawerData);
              Object.assign(param, a );
              //判断账号参数
              if(this.accountLists.length){
                var account_id=[];
                 this.accountLists.filter(item=>{
                   account_id.push(item.id);
                 })
                  Object.assign(param, {"account_id": account_id});
              }else{
                this.$message.error("需选择关联账号");
                return ;
              }
              //判断任务参数、
              if(this.task_data[0].code !='' || this.task_data[0].val != ''){
                Object.assign(param,{"task_data":this.task_data});
              }
              Object.assign(param,this.accountSearch);
              //that.$http.post(`gka/api/task`, param).then(res=>{
              API.handlePost(API.tasks.delete, param).then(res =>{
              if(res.code != 0) return that.$message.error(res.message);
              that.$message({message:"创建成功", type:'success'});
              this.openDrawer= false;
              //更新表格
              that.$parent.getTableData();
              this.$refs[actionDrawerData].resetFields();
              this.accountLists=[];
              // this.task_data=[{'code':"", "val":""}];
              });
            }
          })
        }else{    
          var param = {};
          var a = filterParams(this.actionDrawerData);
          Object.assign(param, a );
          //判断账号参数
          if(this.accountLists.length==1){
            var account_id='';
              // this.accountLists.filter(item=>{
              //   account_id;
              // })
              Object.assign(param, {"account_id": this.accountLists[0].id});
          }else if(this.accountLists.length>1){
            this.$message.error("编辑时只能关联一个账号");
            return ;
          }else{
            this.$message.error("需选择关联账号");
            return ;
          }
          //判断任务参数、
          if(this.task_data.length){
            if(this.task_data[0].code !='' || this.task_data[0].val != ''){
              Object.assign(param,{"task_data":this.task_data});
            }
          }
          
          //that.$http.put(`gka/api/task/${that.updateId}`,param).then(res =>{
          API.handlePut(`${API.tasks.delete}/${that.updateId}`,param).then(res =>{
            if(res.code != 0) return that.$message({message:res.message, type:'error'});
            that.$message({message:"更新成功", type:'success'});
             that.openDrawer= false;
            //更新表格
            that.$parent.getTableData();
            this.$refs[actionDrawerData].resetFields();
          })        
        }
      },

      clearAccountList(){
        this.accountLists = [];
      },

      cancelForm(actionDrawerData) {
        this.loading = false;
        this.$refs[actionDrawerData].resetFields();
        this.openDrawer= false;
        this.accountLists = [];
      },

        //添加任务参数
        addKeysClk(){
          console.log("test", this.task_data)
          this.task_data.push({
            code: '',
            val: ''
          });
        },
        // 删除任务参数
        removeKeysClk(item){
          var index = this.task_data.indexOf(item)
          if (index !== -1) {
            this.task_data.splice(index, 1)
          }
        },
        //处理账号搜索
        async handleAccountsSearch(){
          var param = {};
          var a = filterParams(this.actionDrawerData);
          Object.assign(param,a, {'task_data':this.task_data});

          var b =filterParams(this.accountSearch);
          Object.assign(param, b, {"task_create":"1"});
          //param = JSON.stringify(param);
          //var res = await this.$http.get(`/gka/api/tasks?params=${param}`)
          API.handleGet(API.tasks.create, {'params':param}).then(res =>{
            if(res.code != 0) return this.$message({message:res.message, type:'error'});
            this.accountLists = res.data;
          })
        },
        //删除账户列表
        deleteAccount(id){
          var i = this.accountLists.findIndex((val)=>{
            return val.id == id;
          })
          this.accountLists.splice(i,1);
        },

        //任务类型切换
        changeTaskType(val){
          if(this.taskTypeValue !== val){
            this.accountLists = [];
            this.taskTypeValue = val;
          }
        },

        handleClose(actionDrawerData) {
          this.$refs[actionDrawerData].resetFields();
          this.accountLists = [];
        },
      }
  }
</script>
<style lang="scss">
  .el-form--label-top .el-form-item__label{
    padding: 0 !important;
  }
  .el-form-item{
    text-align: left;
  }
  .relateArea{
    min-height: 60px;
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        padding: 9px;
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relatelist{
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relative-drawer{
    .el-form-item{
      margin-bottom: 10px; 
    }
  }
  .divide{
    border-right: 1px solid #eee;
    
  }
</style>
