<template>
    <div class="nav d-flex align-items-center justify-content-center">
      <router-link to="/accounts">账号页面</router-link>
      <router-link to="/devices">设备页面</router-link>
      <router-link to="/tasks">任务页面</router-link>  
      <router-link to="/configration">配置页面</router-link>  
      <router-link to="/line-voom">LINE VOOM素材</router-link>  
      <router-link to="/workbench">运营工作台</router-link>  
      <router-link to="/line-customers">LINE顾客好友</router-link>  
      <router-link to="/home-statistics">主页状态</router-link>  
      <router-link to="/mail-list">邮箱创建</router-link>  
      <router-link to="/account-send">账号分发</router-link>  
    </div>
</template>
<script>
export default{
    name:"nav-page",
    data(){
        return {}
    }
}
</script>

<style scoped>
  .nav{
    height: 200px;
  }
  .nav a{
      display: inline-block;
      margin:40px;
      font-size: 20px;
      text-decoration: none;
      color: #409EFF;
    }
  .nav a:hover{
    color: #2879cd;
    font-weight: bold;
  }
</style>