import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import '@/utils/loading.js'
// import './plugin/element.js'
import './assets/css/global.css'
// import configs from './assets/js/common.js'
import axios from 'axios'

Vue.use(ElementUI);

axios.defaults.baseURL='https://gassapi.giikin.cn/'
Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.prototype.$ELEMENT = { size: 'medium' };

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
