import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import PersonalAccount from '@/components/personal-account/PersonalAccount.vue'; // @ is an alias to /src
import Terminal from '@/components/terminal/Terminal.vue'
import RaiseAccount from '@/components/raise-account/RaiseAccount.vue';
import Configration from '@/components/configration/Configration.vue';
import NavPage from '@/views/NavPage.vue'
import LineVoom from '@/components/line-voom/LineVoom.vue'
import Statistics from '@/components/home-statistics/HomeStatistics.vue'
import LineCustomers from '@/components/line-customers/LineCustomers.vue'
import Workbench from '@/components/workbench/Workbench.vue'
import MailList from '@/components/mail-list/MailList.vue'
import AccountSend from '@/components/account-send/AccountSend.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'nav-page',
    component: NavPage
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: PersonalAccount
  },
  {
    path: '/devices',
    name: 'devices',
    component: Terminal
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: RaiseAccount
  },
  {
    path: '/configration',
    name: 'configration',
    component: Configration
  },
  {
    path: '/line-voom',
    name: 'line-voom',
    component: LineVoom
  },
  {
    path: '/home-statistics',
    name: 'home-statistics',
    component: Statistics,
    meta:{auth:true} 
  },
  {
    path: '/line-customers',
    name: 'line-customers',
    component: LineCustomers
  },
  {
    path: '/workbench',
    name: 'workbench',
    component: Workbench,
  },
  {
    path: '/mail-list',
    name: 'mail-list',
    component: MailList
  },
  {
    path: '/account-send',
    name: 'account-send',
    component: AccountSend
  }
  
]

const router = new VueRouter({
  routes
})

export default router
