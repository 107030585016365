<template>
  <div>
    <el-container>
      <el-header>LINE VOOM素材录入</el-header>
      <el-main>
        <div>
          <el-form :inline="true" :model="searchData" ref="searchData">
            <el-form-item prop="tags">
              <el-input v-model="searchData.tags" placeholder="标签"></el-input>
            </el-form-item>
            <el-form-item prop="account">
              <el-select v-model="searchData.status" placeholder="状态">
                <el-option v-for="item in line_voom_status" :key="item.value" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="mobile_id">
              <el-select v-model="searchData.mobile_id" placeholder="手机设备id">
                <el-option v-for="item in mobiles" :key="item.value" :label="item.value" :value="item.key"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="" prop="utimeStart">
                <el-date-picker type="datetime" placeholder="创建开始时间" v-model="searchData.ctimeStart"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
              </el-form-item>
              <el-form-item prop="utimeEnd">
                <el-date-picker type="datetime" placeholder="创建结束时间" v-model="searchData.ctimeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              </el-form-item>
              <el-form-item label="" prop="utimeStart">
                <el-date-picker type="datetime" placeholder="最后更新开始时间" v-model="searchData.utimeStart"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
              </el-form-item>
              <el-form-item prop="utimeEnd">
                <el-date-picker type="datetime" placeholder="最后更新结束时间" v-model="searchData.utimeEnd"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
              </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" icon="el-icon-search">查询</el-button>
              <el-button @click="handleReset('searchData')"  icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <!-- buttons -->
        <div class="mb-10">
          <el-button type="primary"  @click="handleCreate"  icon="el-icon-circle-plus">创建</el-button>
          <el-button icon="el-icon-delete"  @click="handleBatchDelete">批量删除</el-button>
          <!-- <el-button type="primary"  @click="batchImport"  icon="el-icon-circle-plus">批量导入</el-button> -->
        </div>   
        <!-- 表格 -->
        <div>
          <el-table
            :data="lineList"
            class="table-info"
            highlight-current-row
            @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
             <el-table-column
              label="id"
              width="100"
              prop="id">
            </el-table-column>
            <el-table-column
            width="140"
              label="账号id"
              prop="account_id">
            </el-table-column>
            <el-table-column
              prop="content"
              label="内容">
            </el-table-column>
             <el-table-column
              prop="images"
              label="图片">
              <template slot-scope="scope">
                <div v-if="scope.row.images"> 
                  <span v-for="(item, index) in scope.row.images.split(',')" :key="index" v-show="index<2">
                    <img :src="item" style="max-width:120px;" />
                  </span>
                  <div v-if="scope.row.images.split(',').length>2">
                    <el-popover trigger="hover" placement="top" v-if='scope.row.images && scope.row.images.length'>
                    <span v-for="(item, index) in scope.row.images.split(',')" :key="index">
                      <img :src="item" style="max-width:120px;" />
                    </span>
                    <div slot="reference" class="name-wrapper inline-block">
                      <a class="a-link" size="medium">更多</a>
                    </div>
                  </el-popover>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="类型"
              prop="type">
            </el-table-column>
            <el-table-column
              prop="tags"
              width="100px"
              label="标签">
            </el-table-column>
            <el-table-column
              prop="status"
              width="100px"
              label="状态">
            </el-table-column>
            <el-table-column
              prop="mobile"
              label="手机设备id">
              <template slot-scope="scope">
                  <span v-for="(item, index) in scope.row.mobile" :key="index">{{item}},</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="update_time"
              width="150px"
              sortable
              label="创建时间">
            </el-table-column>
            <el-table-column
              prop="update_time"
              sortable
              width="150px"
              label="更新时间">
            </el-table-column>
            <el-table-column
              label="操作"
              width="180">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="handleEdit(scope.row)">编辑</el-button>
                <el-button size="mini" type="text" :disabled="scope.row.status.indexOf('删除')>0?true:false" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="text-center m-20">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="10"
              layout="total, prev, pager, next,sizes,jumper"
              :total="totalDataNum">
            </el-pagination>
          </div>
        </div>
        </el-main>
    </el-container>
    <!-- 创建/修改 动作 -->
    <update-action ref="updateAction" :mobiles = mobiles></update-action>
  </div>
</template>
<script>
 import * as API from '@/api/common'
 import UpdateAction from '@/components/line-voom/updateAction.vue'
  export default {
    name: 'line-voom',
    data() {
      return {
        currentPage:1,
        perPageCount:10,
        totalDataNum:0,
        line_voom_status:[], 
        mobiles:[], 
        device_status:null, 
        lineList: [
          // {
          //   id:"",
          //   account_id:"",
          //   content:"",
          //   images:"",
          //   type:"",
          //   tags:"",
          //   status:"",
          //   mobile_ids:"",
          //   create_time:"",
          //   update_time:"",
          //   images_arr:[],
          //   mobile_ids_arr:[]
          // }
        ],
        multipleSelection: [],
        searchData: this.emptyData()
      };
    },
    components:{
        UpdateAction
    },
    mounted() {
      this.getTableData();
      this.getConfigData();
    },
    methods: {
      handleCreate(){
        this.$refs.updateAction.createActionDrawer();
      },
      handleEdit(row){
        this.$refs.updateAction.updateActionDrawer(row);
      },
      getTableData(){
        var param = Object.assign(this.searchData, {page:this.currentPage, size: this.perPageCount});
         API.handleGet(API.line.create, {'params':param}).then(res =>{
            if(res.code != 0) return this.$message(res.message);
            //res.data.images = res.data.images.length.indexOf(",")>0?res.data.images.split(","):[res.data.images];
            this.lineList = res.data;
            this.totalDataNum  = res.page.total;
        })
        
      },
       handleDelete(row){
        this.deleteOpe([row.id]);
      },
      handleBatchDelete(){
        var ids=[];
        if(this.multipleSelection && this.multipleSelection.length){
          this.multipleSelection.forEach(item =>{
            ids.push(item.id);
          })
        }
        console.log(ids);
        if(ids.length>0){
          this.deleteOpe(ids);
        }
      },
      deleteOpe(idArr){
        console.log(idArr);
        this.$confirm('确认删除？')
          .then(async ()=>{
            //var res = await this.$http.delete(`gka/api/task/${row.id}`);
            API.handleDelete(`${API.line.create}?ids=[${idArr}]`).then(res =>{
              if(res.code != 0) return this.$message.error(res.message);
              this.$message.success("删除成功");
              this.getTableData();
            })
          })
          .catch(()=>{
            this.$message.info("已取消删除"); 
          })
      },
      //查询操作
      async handleSearch(){
        this.currentPage=1;
        var param = Object.assign(this.searchData, {page:this.currentPage, size: this.perPageCount});
        API.handleGet(API.line.create, {'params':param}).then(res =>{
          if(res.code != 0) return this.$message(res.message);
          this.lineList = res.data;
          this.totalDataNum  = res.page.total;
        })
        
      },

      //查询重置
      handleReset(searchData){
        this.searchData = this.emptyData();
      },
      //获取配置内容
      async getConfigData(){
        API.handleGet(API.line.config).then(res =>{
          if(res.code != 0) return this.$message("请求数据失败");
          var datas = JSON.parse(JSON.stringify(res.data));

          let {keys, values, entries} = Object;
          for(let [key, value] of entries(datas.line_voom_status)){
            this.line_voom_status.push({'key':key, 'value': value});
          }
          for(let [key, value] of entries(datas.mobiles)){
            this.mobiles.push({'key':key, 'value': value});
          }
        })
          
      },

      //表格复选框
       handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      //排序
      formatterDate(row, column){
        return row.create_time
      },
      //批量导入
      // batchImport(){
      //   this.focusImport = true;
      // },
      // //分页
      handleSizeChange(val) {
        this.perPageCount = val;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.getTableData();
        //console.log(`当前页: ${val}`);
      },
      emptyData(){
        return {
            tags:"",
            status:"",
            mobile_id:"",
            ctimeStart:"",
            ctimeEnd:"",
            utimeStart:"",
            utimeEnd:"",
        }
      },
    }
  };
</script>
<style>
    .el-table__header{
     background: #f1f2f3;
    }
</style>
<style lang="scss" scoped>
  .el-header{
    background-color: rgb(249 249 249);
    line-height: 60px;
    text-align: left;
    border: 1px solid #eee;
  }
  .table-info{
    border:1px solid #eee;
    p{
      margin: 0;
    }
  }

.a-link{
  color: #409EFF;
  text-decoration: none;
  cursor: pointer;
}
</style>