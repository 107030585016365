<template>
    <div>
      <div class="action-con ">
        <el-drawer
            :title="drawerTitle"
            size="580px"
            :visible.sync="openDrawer"
            :wrapperClosable="false"
             @close="handleClose('actionDrawerData')"
            direction="rtl">
            <section class="px-20">
              <div class="d-flex justify-content-between px-20 font-15">
                <label class="font-weight-bold">任务配置</label>
                <span class="gray-color-9 font-14"><em class="red-color">*</em>必填项</span>
              </div>
               <el-form label-position="top" :model="actionDrawerData" :rules="rules" ref="actionDrawerData" class="p-20">
                 <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="任务配置名称"
                    prop="name"> 
                      <el-input v-model="actionDrawerData.name" placeholder="请填写任务配置名称"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item 
                      label=" 任务配置类型"
                      prop="type">
                      <el-select v-model="actionDrawerData.type" placeholder="请选择任务配置类型"  style="width: 100%;"  @change="configTypeChange">
                        <el-option v-for="item in taskTypes" :key="item.key" :label="item.value" :value="item.key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20" v-show="ifPCLike == 1">
                  <el-col :span="12">
                    <el-form-item
                    label="是否手动录入"
                    prop="remark"> 
                      <el-select v-model="actionDrawerData.remark" placeholder="请选择是否手动录入"  style="width: 100%;" @change="handInputChange">
                        <el-option v-for="item in handInput" :key="item.key" :label="item.value" :value="item.key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item 
                  label=" 任务配置周期"
                  prop="cron"
                  >
                  <el-input v-model="actionDrawerData.cron" placeholder="请填写任务配置周期表达式，如：0 0 12 * * ?"></el-input>
                </el-form-item>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="任务状态"
                    prop="status"> 
                      <el-select v-model="actionDrawerData.status" placeholder="请选择任务状态"  style="width: 100%;">
                        <el-option v-for="item in newStatus" :key="item.key" :label="item.value" :value="item.key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                    label="任务类型"
                    prop="is_check"> 
                      <el-select v-model="actionDrawerData.is_check" placeholder="请选择任务类型"  style="width: 100%;">
                        <el-option v-for="item in checkTaskType" :key="item.key" :label="item.value" :value="item.key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="优化师"
                    prop="optimizer_name"> 
                      <el-input v-model="actionDrawerData.optimizer_name" placeholder="请填写优化师"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                    label="家族"
                    prop="org_name"> 
                      <el-input v-model="actionDrawerData.org_name" placeholder="请填写家族"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="商品ID"
                    prop="sale_id"> 
                      <el-input v-model="actionDrawerData.sale_id" placeholder="请填写商品ID"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                    label="历史销量"
                    prop="history_sales"> 
                      <el-input v-model="actionDrawerData.history_sales" placeholder="请填写历史销量"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 目标账号 -->
                <div class="d-flex justify-content-between my-20 font-15">
                  <label class="font-weight-bold">目标账号</label>
                  <span class="gray-color-9 font-14"><em class="red-color">*</em>必填项</span>
                </div> 
                <div class="mb-20">
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <el-form-item
                      label="地区"
                      prop="account_county"
                      >
                        <el-select v-model="actionDrawerData.account_county" placeholder="请选择地区"  style="width: 100%;">
                          <el-option v-for="item in countries" :key="item.value" :label="item.value" :value="item.key"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item
                      label="语言"
                      prop="account_language"
                      >
                        <el-select v-model="actionDrawerData.account_language" placeholder="请选择语言"  style="width: 100%;">
                          <el-option v-for="item in languages" :key="item.value" :label="item.value" :value="item.key"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item prop="account_num" label="数量">
                    <el-input v-model="actionDrawerData.account_num" placeholder="请填写数量" style="width:49%"></el-input>
                  </el-form-item>
                </div>
               
                <!-- 任务参数 -->
                <div class="d-flex justify-content-between my-20 font-15">
                  <label class="font-weight-bold">任务参数<span class="gray-color-9 font-14 font-weight-normal">（必填）</span></label>
                   <span class="blue-color pointer" @click="addKeysClk"><i class="el-icon-edit-outline"></i> 添加</span>
                </div>
                <el-form-item>
                  <template>
                     <el-form-item
                          v-for="(task) in task_data"
                            :key="task.code"
                          >
                          <el-row :gutter="20">
                            <el-col :span="10" class="mb-10">
                              <el-select v-model="task.code" placeholder="请选择参数名">
                              <el-option v-for="item in task_data_options" :key="item.value" :label="item.value" :value="item.key"></el-option>
                            </el-select>
                            </el-col>
                            <el-col :span="12" class="mb-10">
                              <el-input v-model="task.val" placeholder="请填写参数值"></el-input>
                            </el-col>
                            <el-col :span="2">
                            <i class="el-icon-delete font-16 blue-color" @click="removeKeysClk(task)"></i>
                            </el-col>
                          </el-row>
                            
                          </el-form-item>
                  </template>
                </el-form-item>

                <div class="demo-drawer__footer">
                  <el-button @click="cancelForm('actionDrawerData')">取 消</el-button>
                  <el-button type="primary" @click="submitButton('actionDrawerData')" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
                </div>
              </el-form>
            </section>
        </el-drawer>
      </div>
    </div>
</template>
<script>
import { filterParams } from '@/assets/js/common.js'
import * as API from '@/api/common'
  export default {
    name: 'create-action',
    props:{
      taskTypes:{type:Array},
      languages:{type:Array},
      countries:{type: Array},
      task_data_options:{type: Array},
      task_config_status:{type: Array}
    },
      
    data() {
        return {
          drawerTitle:"创建",
          actionDrawer: false, 
          openRelativeDrawer:false, //关联个号弹层
          openDrawer: false,
          loading: false,
          isCreate:false, //判断打开弹出是否是创建操作
          updateId:"",
          rules:{
            name:[{required: true, message: '请填写任务配置名称', trigger: 'blur'}],
            type:[{required: true, message: '请选择任务配置类型', trigger: 'blur'}],
            account_language:[{required: true, message: '请选择语言', trigger: 'blur'}],
            account_county:[{required: true, message: '请选择地区', trigger: 'blur'}],
            // account_num:[{required: true, message: '请填写数量', trigger: 'blur'}],
          },
          ifPCLike:0, //是否是pc点赞评论
          //ifHandInput: 0, //是否手动录入
          actionDrawerData: {
           name:"",
           type:"",
           cron:"",
           account_county:"",
           account_language:"",
           account_num:"",
           status:"",
           is_check:"",
           optimizer_name:"",
           org_name:"",
           history_sales:"",
           sale_id:"",
           remark:0,
          },
          task_data:[{'code':"", "val":""}],
          newStatus:[{key:0, value:'停止'},{key:1, value:'启动'}, {key:3, value:'链接/主页已失效'}],
          checkTaskType:[{key:1, value:'测试任务'},{key:0, value:'正式任务'}],
          handInput:[{key:0, value:'否'},{key:1, value:'是'}],
        }
      },
      components:{
      },
      
      mounted(){
        // this.actionDrawer = this.openDrawer;
      },
      watch:{
      
      },
      methods: {
        createActionDrawer(){
          this.drawerTitle="创建";
          this.openDrawer= true;
          this.isCreate = true;
          this.task_data=[];
        },
        updateActionDrawer(data){
          this.drawerTitle="编辑";
          this.openDrawer= true;
          this.isCreate = false;
          this.updateId = data.id;
          this.$nextTick(function(){
             this.task_data=[];
            this.updateId = data.id;
            this.actionDrawerData = {
              name:data.name,
              type: data.type+"",
              cron: data.cron,
              account_county: data.account_county,
              account_language: data.account_language,
              account_num: data.account_num,
              account_id: data.account_id,
              status: data.status,
              is_check: data.is_check,
              optimizer_name: data.optimizer_name,
              org_name:data.org_name,
              history_sales:data.history_sales,
              sale_id:data.sale_id,
              remark: data.remark,
              };
            this.task_data =JSON.parse(data.task_data);
            this.task_data.push({'code':"page_id", "val": data.page_id});
          })
        },
        openRelateClk(){
          this.$refs.relateAccount.openActionDrawer();
        },

      //提交信息
      submitButton(actionDrawerData){
        var that  = this;
        //创建操作
        if(this.isCreate){
          that.$refs[actionDrawerData].validate((valid) => {
            if (valid) {
              var param = {};
              // var a = filterParams(this.actionDrawerData);
              Object.assign(param,this.actionDrawerData);
              //判断任务参数、
              if(this.task_data.length){
                if(this.task_data[0].code !='' || this.task_data[0].val != ''){
                  Object.assign(param,{"task_data":this.task_data});
                }
              }
              //that.$http.post(`gka/api/task`, param).then(res=>{
              API.handlePost(API.configration.delete, param).then(res =>{
              if(res.code != 0) return that.$message.error(res.message);
              that.$message({message:"创建成功", type:'success'});
              this.openDrawer= false;
              //更新表格
              that.$parent.getTableData();
              this.$refs[actionDrawerData].resetFields();
              });
            }
          })
        }else{    
          var param = {};
          var a = filterParams(this.actionDrawerData);
          Object.assign(param, a );

          //判断任务参数、
          if(this.task_data.length){
            if(this.task_data[0].code !='' || this.task_data[0].val != ''){
              Object.assign(param,{"task_data":this.task_data});
            }
          }
          
          //that.$http.put(`gka/api/task/${that.updateId}`,param).then(res =>{
          API.handlePut(`${API.configration.delete}/${that.updateId}`,param).then(res =>{
            if(res.code != 0) return that.$message({message:res.message, type:'error'});
            that.$message({message:"更新成功", type:'success'});
             that.openDrawer= false;
            //更新表格
            that.$parent.getTableData();
            this.$refs[actionDrawerData].resetFields();
          })        
        }
      },

      configTypeChange(item){
        this.ifPCLike = item =="11"?1:0;
      },

      handInputChange(item){
          if(item=="1"){
            this.actionDrawerData.status = 1;
            this.actionDrawerData.is_check = 0;
            this.actionDrawerData.account_num = 10;
            this.task_data.push({
              code: 'posts_id',
              val: ''
            });
          }else{
            this.actionDrawerData.status = "";
            this.actionDrawerData.is_check = "";
            this.actionDrawerData.account_num = "";
            this.task_data=[];
          }
      },
      cancelForm(actionDrawerData) {
        this.loading = false;
        this.$refs[actionDrawerData].resetFields();
        this.openDrawer= false;
      },

        //添加任务参数
        addKeysClk(){
          //console.log("test", this.task_data)
          this.task_data.push({
            code: '',
            val: ''
          });
        },
        // 删除任务参数
        removeKeysClk(item){
          var index = this.task_data.indexOf(item)
          if (index !== -1) {
            this.task_data.splice(index, 1)
          }
        },

        handleClose(actionDrawerData) {
          this.$refs[actionDrawerData].resetFields();
        },
      }
  }
</script>
<style lang="scss">
  .el-form--label-top .el-form-item__label{
    padding: 0 !important;
  }
  .el-form-item{
    text-align: left;
  }
  .relateArea{
    min-height: 60px;
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        padding: 9px;
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relatelist{
    >div{
      b{
        display: inline-block;
        margin-left: 10px;
        width: 20px;
        height: 16px;
      }
      &:hover{
        border: 1px solid rgb(186, 210, 237);
        background-color: rgb(243, 249, 255);
        i{
          display: block;
          cursor: pointer;
        }
      }
    }
  }
  .relative-drawer{
    .el-form-item{
      margin-bottom: 10px; 
    }
  }
  .divide{
    border-right: 1px solid #eee;
    
  }
</style>
