<template>
    <div>
      <div class="action-con ">
        <el-drawer
            :title="drawerTitle"
            size="580px"
            :visible.sync="openDrawer"
            :wrapperClosable="false"
             @close="handleClose('actionDrawerData')"
            direction="rtl">
            <section class="px-20">
               <el-form label-position="left" :model="actionDrawerData" :rules="rules" ref="actionDrawerData" class="px-20 form-area">
                <div class="font-15 mb-10 font-weight-bold">
                  <label class="">关联账号信息</label>
                </div>
                <!-- 创建时显示 -->
                <el-row :gutter="24" v-show="isCreate">
                  <el-col :span="24">
                    <el-form-item
                    label="账号id"
                    prop="account_id"> 
                      <el-input v-model="actionDrawerData.account_id" placeholder="请填写账号id"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <!-- 编辑时显示 -->
                <div class="edit-div mb-10 font-14" v-show="!isCreate && Object.keys(actionDrawerData.account).length>0" >
                  <el-row :gutter="20">
                    <el-col :span="20">
                      <span>摘要：</span>{{actionDrawerData.account.summary}}   (账号id-姓名-账号-国家)
                    </el-col>
                  </el-row>
                  <transition name="el-zoom-in-top">
                    <div class="more-area mt-10" v-show="moreArea"  ref="moreArea">
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <span>id：</span> {{actionDrawerData.account.id}}
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">   
                    <el-col :span="12">
                      <span>名：</span> {{actionDrawerData.account.first_name}}
                    </el-col> 
                    <el-col :span="12">
                      <span>中间名：</span> {{actionDrawerData.account.middle_name}}
                    </el-col> 
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <span>姓:</span> {{actionDrawerData.account.last_name}}
                      </el-col> 
                      <el-col :span="12">
                        <span>性别：</span> {{actionDrawerData.account.gender_str}}
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <span>账号：</span> {{actionDrawerData.account.account}}
                      </el-col>
                      <el-col :span="12">
                        <span>手机号：</span> {{actionDrawerData.account.phone}}
                      </el-col>
                    </el-row>
                    <el-row :gutter="20">
                      <el-col :span="12">
                        <span>区号：</span> {{actionDrawerData.account.phone_country_code}}
                      </el-col>
                      <el-col :span="12">
                        <span>国家：</span> {{actionDrawerData.account.country_str}}
                      </el-col> 
                    </el-row>
                    </div>
                   </transition>
                  <el-button class="py-10" type="text"  @click="moreInfo()">{{moreTitle}}</el-button>
                </div>

                <div class="font-15 mb-10 font-weight-bold">
                  <label class="">顾客信息</label>
                </div>
                <el-row :gutter="20" v-show="isCreate">
                  <el-col :span="12">
                    <el-form-item 
                      label="姓"
                      prop="last_name">
                      <el-input v-model="actionDrawerData.last_name" placeholder="请填写姓"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item 
                      label="名"
                      prop="first_name">
                      <el-input v-model="actionDrawerData.first_name" placeholder="请填写名"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="平台账号"
                    prop="pf_account"> 
                      <el-input v-model="actionDrawerData.pf_account" placeholder="请填写平台账号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item 
                      label="平台昵称"
                      prop="pf_nickname">
                      <el-input v-model="actionDrawerData.pf_nickname" placeholder="请填写平台昵称"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item 
                      label="备注名"
                      prop="remark_name">
                      <el-input v-model="actionDrawerData.remark_name" placeholder="请填写备注名"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                    label="下次回访时间"
                    prop="next_visit_time"> 
                      <el-date-picker type="datetime" placeholder="下次回访时间" v-model="actionDrawerData.next_visit_time"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="添加好友时间"
                    prop="add_time"> 
                      <el-date-picker type="datetime" placeholder="添加好友时间" v-model="actionDrawerData.add_time"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item 
                    label="目前标签"
                    prop="current_label_code">
                    <el-select v-model="actionDrawerData.current_label_code" placeholder="请选择目前标签">
                      <el-option v-for="item in labelsList" :key="item.key" :label="item.value" :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="最初标签"
                    prop="init_label_code"> 
                      <el-select v-model="actionDrawerData.init_label_code" :disabled="!isCreate" placeholder="请选择最初标签">
                        <el-option v-for="item in labelsList" :key="item.key" :label="item.value" :value="item.value"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                    label="区号"
                    prop="phone_country_code"> 
                      <el-input v-model="actionDrawerData.phone_country_code" placeholder="请填写区号"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="手机号"
                    prop="phone"> 
                      <el-input v-model="actionDrawerData.phone" placeholder="请填写手机号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                    label="邮箱"
                    prop="email"> 
                      <el-input v-model="actionDrawerData.email" placeholder="请填写邮箱"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="性别"
                    prop="gender"> 
                      <el-select v-model="actionDrawerData.gender" placeholder="请选择性别">
                        <el-option v-for="item in gendersList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                    label="年龄"
                    prop="age"> 
                      <el-input v-model="actionDrawerData.age" placeholder="请填写年龄"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                    label="国家"
                    prop="country"> 
                      <el-select v-model="actionDrawerData.country" placeholder="请选择国家">
                        <el-option v-for="item in countriesList" :key="item.key" :label="item.value" :value="item.key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item
                    label="职业"
                    prop="profession"> 
                      <el-input v-model="actionDrawerData.profession" placeholder="请填写职业"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <div class="d-flex align-items-center font-15 mb-20 mt-10" v-show="!isCreate">
                  <label class="">联系</label>
                  <div class="inline-block mx-10" v-for="(item,index) in actionDrawerData.official_accounts" :key="index">
                    <el-link type="primary" :href="item.link" title="点击聊天" >{{item.account_name}}</el-link>
                  </div>
                </div>
                <div class="d-flex justify-content-between font-15 mb-10">
                  <label class="">订单数据</label>
                  <span class="blue-color pointer" @click="addOrderInfo"><i class="el-icon-edit-outline"></i> 添加</span>
                </div>
                <!-- 添加订单信息 -->
                <div v-show="orders_data.length">
                  <div v-for="(order, index) in orders_data" :key="index" class="child-area mb-20">
                    <el-row :gutter="20">
                      <el-col :span="10">
                        <el-input v-model="order.order_number" placeholder="订单编号" class="input1"></el-input>
                      </el-col>
                      <el-col :span="10" >
                        <el-date-picker type="datetime" placeholder="下单时间" v-model="order.order_time" class="input1"  value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
                      </el-col>
                      <el-col :span="3" class="text-center">
                        <i class="el-icon-delete font-16 blue-color" @click="removeOrderClk(index)"></i>
                      </el-col>
                    </el-row>  
                      <el-row :gutter="20">
                      <el-col :span="21">
                        <el-input v-model="order.order_product" placeholder="订单产品" class="input1"></el-input>
                      </el-col>
                    </el-row>  
                  </div>
                </div>
                <div>
                  <div v-for="(item, index) in actionDrawerData.orders" :key="index" class="list-con mb-10">
                    <p>订单编号：{{item.order_number}}</p>
                    <p>下单时间：{{item.order_time}}</p>
                    <p>订单产品：{{item.order_product}}</p>
                  </div>
                  <span v-show="!isCreate && this.actionDrawerData.orders.length" class="blue-color pointer font-14" @click="getOrderMore">查看更多...</span>
                </div> 
                <div class="d-flex justify-content-between font-15 mt-20 mb-10">
                  <label class="">回访记录</label>
                  <span class="blue-color pointer" @click="addLogsInfo"><i class="el-icon-edit-outline"></i> 添加</span>
                </div>
                <!-- 添加回访记录 -->
                <div v-show="visit_logs_data.length">
                  <div v-for="(logs, index) in visit_logs_data" :key="index" class="child-area mb-20">
                    <el-row :gutter="20">
                      <el-col :span="20">
                        <el-select v-model="logs.label_code" placeholder="请选择标签" class="input1" style="width:100%">
                          <el-option v-for="item in labelsList" :key="item.key" :label="item.value" :value="item.value"></el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="3" class="text-center">
                        <i class="el-icon-delete font-16 blue-color" @click="removeLogsClk(index)"></i>
                      </el-col>
                    </el-row>  
                      <el-row :gutter="20">
                      <el-col :span="20">
                        <el-input v-model="logs.visit_content" placeholder="回访内容" class="input1"></el-input>
                      </el-col>
                    </el-row>  
                  </div>
                </div>
                <div class="mb-20">
                  <div v-for="(item, index) in actionDrawerData.visit_logs" :key="index" class="list-con mb-10" v-show="index<1">
                    <p>标&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;签：{{item.label_code}}</p>
                    <p>回访内容：{{item.visit_content}}</p>
                    <!-- <p>{{item.create_time}}</p>
                    <p>{{item.update_time}}</p> -->
                  </div>
                  <span v-show="!isCreate && this.actionDrawerData.visit_logs.length" class="blue-color pointer font-14" @click="getLogsMore">查看更多...</span>
                </div>  
                <div class="demo-drawer__footer">
                  <el-button @click="cancelForm('actionDrawerData')">取 消</el-button>
                  <el-button type="primary" @click="submitButton('actionDrawerData')" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
                </div>
              </el-form>
            </section>
        </el-drawer>
      </div>
       <!-- 订单数据查看更多 -->
      <el-dialog title="订单数据" :visible.sync="dialogOrdersVisible" class="visitLogs">
        <el-form :inline="true" :model="searchOrdersData" ref="searchLogsData">
          <el-form-item prop="order_number">
            <el-input  size="small" v-model="searchOrdersData.order_number" placeholder="订单编号" ></el-input>
          </el-form-item>
          <el-form-item prop="order_product">
            <el-input  size="small" v-model="searchOrdersData.order_product" placeholder="订购产品" ></el-input>
          </el-form-item>
          <el-form-item label="" prop="create_time">
            <el-date-picker  size="small" type="datetime" placeholder="创建时间" v-model="searchOrdersData.create_time" style="width:180px" value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
          </el-form-item>
          <el-form-item prop="update_time">
            <el-date-picker  size="small" type="datetime" placeholder="更新时间" v-model="searchOrdersData.update_time"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary"  @click="handleOrderSearch()">搜索</el-button>
            <el-button size="small" type="button" @click="handleLOrderReset()">重置</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="orderMoreData">
          <el-table-column property="order_number" label="订单编号"></el-table-column>
          <el-table-column property="order_product" label="订购产品"></el-table-column>
          <el-table-column property="create_time" label="创建时间" width="180"></el-table-column>
          <el-table-column property="update_time" label="更新时间" width="180"></el-table-column>
        </el-table>
        <div class="text-center m-20">
          <el-pagination background :total="ordersTotalNum" @size-change="handleOrderSizeChange" :page-sizes="[10, 20, 30, 40]"
                @current-change="handleOrderCurrentChange" :current-page="currentOrderPage" layout="total, prev, pager, next,sizes,jumper" ></el-pagination>
        </div>
      </el-dialog>

      
    <!-- 访问记录查看更多 -->
    <el-dialog title="访问记录" :visible.sync="dialogVisitLogVisible" class="visitLogs">
      <el-form :inline="true" :model="searchLogsData" ref="searchLogsData">
        <el-form-item prop="label_code">
          <el-select  size="small" v-model="searchLogsData.label_code" placeholder="请选择标签" class="selectInput">
            <el-option v-for="item in labelsList" :key="item.key" :label="item.value" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="visit_content">
          <el-input  size="small" v-model="searchLogsData.visit_content" placeholder="回访内容" ></el-input>
        </el-form-item>
        <el-form-item label="" prop="create_time">
          <el-date-picker  size="small" type="datetime" placeholder="创建时间" v-model="searchLogsData.create_time" style="width:180px" value-format="yyyy-MM-dd HH:mm:ss" ></el-date-picker>
        </el-form-item>
        <el-form-item prop="update_time">
          <el-date-picker  size="small" type="datetime" placeholder="更新时间" v-model="searchLogsData.update_time"  value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
         <el-form-item>
          <el-button size="small" type="primary"  @click="handleLogsSearch()">搜索</el-button>
          <el-button size="small" type="button" @click="handleLogsReset()">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="logsData">
        <el-table-column property="label_code" label="标签"></el-table-column>
        <el-table-column property="visit_content" label="回访内容"></el-table-column>
        <el-table-column property="create_time" label="创建时间" width="180"></el-table-column>
        <el-table-column property="update_time" label="更新时间" width="180"></el-table-column>
      </el-table>
      <div class="text-center m-20">
        <el-pagination background :total="logsTotalNum" @size-change="handleLogsSizeChange" :page-sizes="[10, 20, 30, 40]"
              @current-change="handleLogsCurrentChange" :current-page="currentLogsPage" layout="total, prev, pager, next,sizes,jumper" ></el-pagination>
      </div>
    </el-dialog>
    </div>
</template>
<script>
import { filterParams } from '@/assets/js/common.js'
import * as API from '@/api/common'
  export default {
    name: 'item-action',
    props:{
    },
      
    data() {
        return {
          drawerTitle:"创建",
          actionDrawer: false, 
          openRelativeDrawer:false, //关联个号弹层
          openDrawer: false,
          loading: false,
          isCreate:false, //判断打开弹出是否是创建操作
          detailsInfo:null,
          labelsList:[], //标签列表
          countriesList:[], //国家列表O
          orders_data:[],
          orderMoreData:[], //订单数据查看更多
          ordersTotalNum:0,
          perOrdersPageCount:10,
          dialogOrdersVisible:false,
          currentOrderPage:1,
          searchOrdersData:this.emptyOrderData(),
          visit_logs_data:[],
          logsData:[], //获取更多日志信息
          searchLogsData:this.emptyLogsData(),
          logsTotalNum:0,
          perlogsPageCount:10,
          currentLogsPage:1,
          dialogVisitLogVisible:false, 
          gendersList:[{'key':1, 'value':'男'},{'key':0, 'value':'女'}],
          updateId:"",
          moreTitle:"更多",
          moreArea:false,
          rules:{
            account_id:[{required: true, message: '请填写账号id', trigger: 'blur'}],
            pf_account:[{required: true, message: '请填写平台账号', trigger: 'blur'}],
            pf_nickname:[{required: true, message: '请填写平台昵称', trigger: 'blur'}],
            remark_name:[{required: true, message: '请填写备注名', trigger: 'blur'}],
            current_label_code:[{required: true, message: '请选择目前标签', trigger: 'blur'}],
            init_label_code:[{required: true, message: '请选择最初标签', trigger: 'blur'}],
            next_visit_time:[{required: true, message: '下次回访时间', trigger: 'blur'}],
            add_time:[{required: true, message: '添加好友时间', trigger: 'blur'}],
          },
          actionDrawerData: {
            visit_logs:[],
            orders:[],
            account:{},
            official_accounts:[],
          //  pf_account:"",
          //  pf_nickname:"",
          //  next_visit_time:"",
          //  add_time:"",
          //  current_label_code:"",
          //  init_label_code:"",
          //  phone_country_code:"",
          //  phone:"",
          //  gender:"",
          //  age:"",
          //  country:"",
          //  profession:"",
          },
        }
      },
      components:{
      },
      
      mounted(){
        // this.actionDrawer = this.openDrawer;
        this.getConfigData();
      },
      watch:{
      
      },
      methods: {
        createActionDrawer(){
          this.drawerTitle="创建";
          this.openDrawer= true;
          this.isCreate = true;
          this.actionDrawerData = {
            account:{},
            orders:[],
            visit_logs:[],
            official_accounts:[],
          }
          this.orders_data = [];
          this.visit_logs_data = [];
        },
        updateActionDrawer(id){
          this.drawerTitle="编辑";
          this.openDrawer= true;
          this.isCreate = false;
          this.getDetailData(id);
          this.visit_logs_data = [];
          this.orders_data = [];
          
          this.$nextTick(function(){
            this.actionDrawerData = {
              account:{},
              orders:[],
              visit_logs:[],
              official_accounts:[],
            }
            // this.updateId = data.id;
            // this.actionDrawerData = {
            //   name:data.name,
            //   type: data.type+"",
            //   cron: data.cron,
            //   account_county: data.account_county,
            //   account_language: data.account_language,
            //   account_num: data.account_num,
            //   account_id: data.account_id,
            //   status: data.status,
            //   is_check: data.is_check,
            //   optimizer_name: data.optimizer_name,
            //   org_name:data.org_name,
            //   history_sales:data.history_sales,
            //   sale_id:data.sale_id,
            //   remark: data.remark,
            //   };
          })
        },
        getDetailData(id){
          API.handleGet(`${API.lineCustomers.edit}/${id}`).then(res =>{
            if(res.code != 0) return this.$message(res.message);
            //console.log("res", res.data);
            this.detailsInfo = res.data;
            this.actionDrawerData = {...res.data};
          }) 
        },
      getLogsMore(){
        this.searchLogsData = this.emptyLogsData();
        this.getLogsMoreData();
      },
      getLogsMoreData(){
        let param = Object.assign(this.searchLogsData,{page:this.currentLogsPage, size: this.perlogsPageCount});
        API.handleGet(`${API.lineCustomers.visitlogs}/${this.detailsInfo.id}`, {'params':param}).then(res =>{
            if(res.code != 0) return this.$message(res.message);
            this.dialogVisitLogVisible = true;
            this.logsData = res.data;
            this.logsTotalNum = res.page.total;
        }) 
      },
      getOrderMore(){
        this.searchOrdersData = this.emptyOrderData();
        this.getOrderMoreData();
      },
      getOrderMoreData(){
        let param = Object.assign(this.searchOrdersData,{page:this.currentOrderPage, size: this.perOrdersPageCount});
        API.handleGet(`${API.lineCustomers.orderdata}/${this.detailsInfo.id}`, {'params':param}).then(res =>{
            if(res.code != 0) return this.$message(res.message);
            this.dialogOrdersVisible = true;
            this.orderMoreData = res.data;
            this.ordersTotalNum = res.page.total;
        }) 
      },

        openRelateClk(){
          this.$refs.relateAccount.openActionDrawer();
        },
        //添加新订单信息
        addOrderInfo(){
          this.orders_data.push({'order_number':"", "order_time":"", "order_product":""});
        },
        removeOrderClk(index){
          this.orders_data.splice(index,1);
        },
        //添加回访记录
        addLogsInfo(){
          this.visit_logs_data.push({'label_code':"", "visit_content":""});
        },
        removeLogsClk(index){
          this.visit_logs_data.splice(index,1);
        },
      moreInfo(){
        this.moreArea? this.moreArea=false: this.moreArea= true;
        this.moreArea? this.moreTitle ="合起": this.moreTitle ="更多";
        // if(this.$refs.moreArea.style.display == "none"){
        //   this.$refs.moreArea.style.display= "block";
        //   this.moreTitle ="合起"
        // }else{
        //   this.$refs.moreArea.style.display= "none";
        //   this.moreTitle ="更多";
        // }
      },
      //提交信息
      submitButton(actionDrawerData){
        var that  = this;
        //创建操作
        if(this.isCreate){
          that.$refs[actionDrawerData].validate((valid) => {
            if (valid) {
              var param = {};
              //delete this.actionDrawerData.next_visit_time;
              this.actionDrawerData.platform = "LINE";
              this.actionDrawerData.orders = this.orders_data;
              this.actionDrawerData.visit_logs = this.visit_logs_data;
              Object.assign(param,this.actionDrawerData);
              delete param.account;
              API.handlePost(API.lineCustomers.edit, param).then(res =>{
              if(res.code != 0) return that.$message.error(res.message);
              that.$message({message:"创建成功l me", type:'success'});
              this.openDrawer= false;
              //更新表格
              that.$parent.getTableData();
              //this.$refs[actionDrawerData].resetFields();
              });
            }
          })
        }else{
           that.$refs[actionDrawerData].validate((valid) => {
            if (valid) {    
              var param = {};
              delete this.actionDrawerData.id;
              delete this.actionDrawerData.first_name;
              delete this.actionDrawerData.middle_name;
              delete this.actionDrawerData.last_name;
              delete this.actionDrawerData.account_id;
              delete this.actionDrawerData.create_time;
              delete this.actionDrawerData.update_time;
              delete this.actionDrawerData.status;
              delete this.actionDrawerData.user_id;
              delete this.actionDrawerData.official_accounts;
              this.actionDrawerData.orders = this.orders_data;
              this.actionDrawerData.visit_logs = this.visit_logs_data;
              //var a = filterParams(this.actionDrawerData);
              Object.assign(param, this.actionDrawerData );
              delete param.account;
              API.handlePut(`${API.lineCustomers.edit}/${that.detailsInfo.id}`,param).then(res =>{
                if(res.code != 0) return that.$message({message:res.message, type:'error'});
                that.$message({message:"更新成功", type:'success'});
                that.openDrawer= false;
                //更新表格
                that.$parent.getTableData();
                this.$refs[actionDrawerData].resetFields();
              })   
            }
          })     
        }
      },

      //获取配置内容
      async getConfigData(){
        API.handleGet(API.line.config).then(res =>{
          if(res.code != 0) return this.$message("请求数据失败");
          var datas = JSON.parse(JSON.stringify(res.data));

          let {keys, values, entries} = Object;
          for(let [key, value] of entries(datas.labels)){
            this.labelsList.push({'key':key, 'value': value});
          }
          for(let [key, value] of entries(datas.countries)){
            this.countriesList.push({'key':key, 'value': value});
          }
        })
      },

      cancelForm(actionDrawerData) {
        this.loading = false;
        this.$refs[actionDrawerData].resetFields();
        this.openDrawer= false;
      },

      handleClose(actionDrawerData) {
        this.$refs[actionDrawerData].resetFields();
      },

      async handleOrderSearch(){
        this.currentOrderPage=1;
        this.getOrderMoreData();
      },
      //查询重置
      handleLOrderReset(){
        this.searchOrdersData = this.emptyOrderData();
      }, 

      //分页
      handleOrderSizeChange(val) {
        this.perOrdersPageCount = val;
        this.getOrderMoreData();
      },
      handleOrderCurrentChange(val) {
        this.currentOrderPage = val;
        this.getOrderMoreData();
      },
      emptyOrderData(){
        return {
          order_number:"",
          order_product:"",
          create_time:"",
          update_time:"",
        }
      },

      //visit logs operation
      async handleLogsSearch(){
        this.currentLogsPage=1;
        this.getLogsMoreData();
      },
      //查询重置
      handleLogsReset(){
        this.searchLogsData = this.emptyLogsData();
      },
      //分页
      handleLogsSizeChange(val) {
        this.perlogsPageCount = val;
        this.getLogsMoreData();
      },
      handleLogsCurrentChange(val) {
        this.currentLogsPage = val;
        this.getLogsMoreData();
      },
      emptyLogsData(){
        return {
          label_code:"",
          visit_content:"",
          create_time:"",
          update_time:"",
        }
      },

      }
  }
</script>
<style>
.form-area .el-form-item{
  margin-bottom: 12px;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner{
  width: auto;
}
 .visitLogs .el-form .el-input__inner{
     width: 186px;
   }
   .selectInput .el-input .el-input__inner{
     width: 150px;
   }
</style>
<style lang="scss">
  .el-form--label-top .el-form-item__label{
    padding: 0 !important;
  }
  .el-form-item{
    text-align: left;
  }

  .child-area .input1 input{
    border-width: 0 0 1px 0;
  }
  .child-area .el-icon-delete{
    margin-top: 15px;
  }

  .list-con{
    padding: 5px 10px;
    background-color: #eee;
    border-radius: 8px;
  }
  .list-con p{
    margin: 5px 10px;
    line-height: 1.2;
  }
  .more-area span{
    display: inline-block;
    margin-bottom: 10px;
    width: 70px;
    color: #606266;
  }
</style>
