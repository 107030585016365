<template>
    <div class="action-con">
        <el-drawer
            :title="drawerTitle"
            size="580px"
            :wrapperClosable="false"
            :visible.sync="openDrawer"
            @close="handleClose('actionDrawerData')"
            direction="rtl">
            <section>
              <div class="d-flex justify-content-between px-20  font-15">
                <label class="font-weight-bold">注册信息</label>
                <span class="gray-color-9 font-14"><em class="red-color">*</em>必填项</span>
              </div>
              <el-form label-position="top" :model="actionDrawerData" :rules="rules" ref="actionDrawerData" class="p-20">
                <el-row :gutter="40">
                  <el-col :span="12">
                    <label class="block my-10 font-14">头像</label>
                    <el-upload
                      class="avatar-uploader"
                      action="https://jsonplaceholder.typicode.com/posts/"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :before-upload="beforeAvatarUpload">
                      <img v-if="actionDrawerData.avatar" :src="actionDrawerData.avatar" class="avatar">
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                  </el-col>
                  <el-col :span="12">
                    <div class="d-flex align-items-end">
                      <el-form-item 
                        label=" 姓名"
                        prop="first_name"
                        class="row-item mr-10">
                        <el-input v-model="actionDrawerData.first_name" placeholder="姓" maxlength="8"></el-input>
                      </el-form-item>
                      <el-form-item 
                        label=""
                        prop="last_name"
                        class="row-item"
                        >
                        <el-input v-model="actionDrawerData.last_name" placeholder="名" maxlength="8"></el-input>
                      </el-form-item>
                    </div>
                    <el-form-item 
                      label="性别"
                      prop="gender"
                      >
                      <el-radio-group v-model="actionDrawerData.gender" label="性别">
                        <el-radio :label="1">男</el-radio>
                        <el-radio :label="0">女</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item 
                      prop="birthday"
                      label="生日">
                      <el-date-picker
                        v-model="actionDrawerData.birthday"
                        type="date"
                        placeholder="请选择生日" style="width: 100%;">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item 
                      prop="account"
                      label="账号"
                      >
                      <el-input v-model="actionDrawerData.account" placeholder="请填写账号"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col  v-show="false">
                    <el-form-item 
                      prop="password"
                      label="密码"
                      >
                      <el-input v-model="actionDrawerData.password" placeholder="请填写密码"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item 
                      prop="phone"
                      label=" 手机号"
                      >
                      <el-input v-model="actionDrawerData.phone" placeholder="请填写手机号"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                 <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item 
                      prop="email"
                      label=" 邮箱">
                      <el-input v-model="actionDrawerData.email" placeholder="请填写邮箱">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                      <el-form-item label="状态" prop="status">
                        <el-select v-model="actionDrawerData.status" placeholder="请选择状态" style="width: 100%;">
                          <el-option v-for="item in account_status" :key="item.value" :label="item.value" :value="item.key"></el-option>
                        </el-select>
                      </el-form-item>
                  </el-col>
                 </el-row>
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item label="所属地区" prop="country">
                      <el-select v-model="actionDrawerData.country" placeholder="请选择地区" style="width: 100%;">
                        <el-option v-for="item in countries" :key="item.value" :label="item.value" :value="item.key"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                  <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item label="备注" prop="remark">
                        <el-input v-model="actionDrawerData.remark" placeholder="请填写备注信息"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                

                <div class="d-flex justify-content-between my-20 font-15">
                  <label class="font-weight-bold">验证信息</label>
                  <span class="gray-color-9 font-14"><em class="red-color">*</em>必填项</span>
                </div>

                <!-- <el-form-item label="注册方式">
                  <el-radio-group v-model="actionDrawerData.registe">
                  <el-radio :label="1">按手机号注册</el-radio>
                  <el-radio :label="2">按邮箱注册</el-radio>
                </el-radio-group>
                </el-form-item> -->
                
                <!-- <el-form-item label="密码" prop="password">
                  <el-input v-model="actionDrawerData.password" placeholder="请填写密码"></el-input>
                </el-form-item> -->
                <el-form-item label="二次验证密钥" prop="tfa_secret">
                  <el-input v-model="actionDrawerData.tfa_secret" placeholder="请填写二次验证密钥"></el-input>
                </el-form-item>
                <el-form-item label="二次验证取码链接" prop="tfa_code_url">
                  <el-input v-model="actionDrawerData.tfa_code_url" placeholder="二次验证取码链接"></el-input>
                </el-form-item>
                <el-form-item label="备份名" prop="backup_record">
                  <el-input v-model="actionDrawerData.backup_record" placeholder="备份名"></el-input>
                </el-form-item>
                <!--<label class="block my-10 text-left font-weight-bold  font-15">关键词<span class="font-14 gray-color-9">（建议使用英文命名）</span></label>
                <el-form-item label="关联手机终端" prop="region">
                  <el-select v-model="actionDrawerData.region" placeholder="请选择地区" style="width: 100%;">
                    <el-option v-for="item in mobilesData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="关联PC终端" prop="region">
                  <el-select v-model="actionDrawerData.region" placeholder="可通过终端编码，终端名称，内网IP进行搜索选择~" style="width: 100%;">
                    <el-option v-for="item in pcsData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                  </el-select>
                </el-form-item>1-->
              <div class="demo-drawer__footer">
                <el-button @click="cancelForm('actionDrawerData')">取 消</el-button>
                <el-button type="primary" @click="submitButton('actionDrawerData')" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>
              </div>
              </el-form>
            </section>
        </el-drawer>
    </div>
</template>
<script>
import * as API from '@/api/common'
  export default {
    name: 'item-action',
    props:{
      mobilesData:{type: Array},
      pcsData:{type: Array}
    },
    data() {
        return {
          drawerTitle:"创建",
          actionDrawer: false,
          openDrawer: false,
          platforms:[], //  所属平台
          countries:[], // 所属区域
          account_status:[], //账号状态
          loading: false,
          isCreate:false, //判断打开弹出是否是创建操作
          actionDrawerData: {
            avatar:'',
            first_name:"",
            last_name:"",
            gender:"",
            birthday:"",
            phone:"",
            email:"",
            platform:"",
            country:"",
            password:"",
            tfa_secret:"",
            tfa_code_url:"",
            status:"",
            backup_record:"",
            remark:"",
            account:"",
          },
          rules: {
            first_name:[{required: true, message: '请输入您的姓', trigger: 'blur'}],
            last_name:[{required: true, message: '请输入您的名', trigger: 'blur'}],
            gender:[{required: true, message: '请选择您的性别', trigger: 'blur'}]
          }

        }
      },
      mounted(){
        // this.actionDrawer = this.openDrawer;
      },
      created(){
        this.getConfigData();
      },
      watch:{
      
      },
      methods: {
        createActionDrawer(){
          this.drawerTitle="创建";
          this.openDrawer= true;
          this.isCreate = true;
        },
        updateActionDrawer(data){
          this.drawerTitle="编辑";
          this.openDrawer= true;
          this.isCreate = false;

          this.$nextTick(function(){
            this.actionDrawerData = JSON.parse(JSON.stringify(data));
          })
        },
        //获取配置内容
      async getConfigData(){
         //var res = await this.$http.get(`gka/api/configs`);
        API.handleGet(API.configs.getConfig).then(res =>{
          if(res.code != 0) return this.$message("请求数据失败");
          var datas = JSON.parse(JSON.stringify(res.data))

          let {keys, values, entries} = Object;
          for(let [key, value] of entries(datas.countries)){
            this.countries.push({'key':key, 'value': value});
          }
          for(let [key, value] of entries(datas.platforms)){
            this.platforms.push({'key':key, 'value': value});
          } 
         for(let [key, value] of entries(datas.account_status)){
            this.account_status.push({'key':key, 'value': value});
          }
        })
          
      },
      //提交信息
      submitButton(actionDrawerData){
        var that  = this;
        //创建操作
        if(this.isCreate){
          that.$refs[actionDrawerData].validate((valid) => {
            if (valid) {
              //that.$http.post(`gka/api/account`, that.actionDrawerData).then(res=>{
              API.handlePost(API.accouts.delete, that.actionDrawerData).then(res =>{
              if(res.code != 0) return that.$message({message:res.message, type:'error'});
              that.$message({message:"创建成功", type:'success'});
              this.openDrawer= false;
              //更新表格
              that.$parent.getTableData();
              that.$refs[actionDrawerData].resetFields();
              });
            }
          })
        }else{    
          var id = this.actionDrawerData.id;
          delete this.actionDrawerData.id;
          delete this.actionDrawerData.status_str;
          delete this.actionDrawerData.user_name;
          delete this.actionDrawerData.bind_devices;
          //that.$http.put(`gka/api/account/${id}`,that.actionDrawerData).then(res =>{
          API.handlePut(`${API.accouts.delete}/${id}`, that.actionDrawerData).then(res =>{
            //console.log(res);
            if(res.code != 0) return that.$message({message:res.message, type:'error'});
            that.$message({message:"更新成功", type:'success'});
            this.openDrawer= false;
            //更新表格
            that.$parent.getTableData();
            that.$refs[actionDrawerData].resetFields(); 
          })
        }
      },
      cancelForm(actionDrawerData) {
        this.loading = false;
        this.openDrawer= false;
        this.$refs[actionDrawerData].resetFields();
      },
      
      handleClose(actionDrawerData){
        this.$refs[actionDrawerData].resetFields();
      },
      
      handleKeysClick(tab, event) {
        console.log(tab, event);
      },
      addKeysClk(){
        console.log("test")
        this.actionDrawerData.imgKeys.push({
          value: 'second',
          key: Date.now()
        });
      },
      removeKeysClk(item){
        var index = this.actionDrawerData.imgKeys.indexOf(item);
        if (index !== -1) {
          this.actionDrawerData.imgKeys.splice(index, 1)
        }
      },
      //上传
        handleAvatarSuccess(res, file) {
        this.actionDrawerData.avatar = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
      }
    }
  }
</script>
<style lang="scss">
  .el-form--label-top .el-form-item__label{
    padding: 0 !important;
  }
  .el-form-item{
    text-align: left;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 220px;
    height: 220px;
    line-height: 220px;
    background-color: #f0f0f0;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 100%;
    height: 100%;
    display: block;
  }
</style>
