import Vue from 'vue'
import { Loading } from 'element-ui'

let loadingCount = 0
let loading

const startLoading = (e) => {
  loading = Loading.service({
    lock: true,
    text: e && e.title ? e.title : '拼命加载中',
    // spinner: 'el-icon-loading',
    background: e && e.background ? e.background : 'rgba(0, 0, 0, 0.6)',
  })
}

const endLoading = () => {
  loading.close()
}

const showLoading = (e) => {
  if (loadingCount === 0) {
    startLoading(e)
  }
  loadingCount += 1
}

const hideLoading = () => {
  if (loadingCount <= 0) {
    return
  }
  loadingCount -= 1
  if (loadingCount === 0) {
    endLoading()
  }
}

Vue.prototype.$showLoading = showLoading
Vue.prototype.$hideLoading = hideLoading